import Underline from '@tiptap/extension-underline';
import StarterKit from '@tiptap/starter-kit';
import { useEditor } from '@tiptap/react';
import TextAlignExtension from '@tiptap/extension-text-align';
import LinkExtension from '@tiptap/extension-link';
import Highlight from '@tiptap/extension-highlight';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Placeholder from '@tiptap/extension-placeholder';
import { useState } from 'react';

interface UseRichTextEditorProps {
  key: string;
  value: string;
  onUpdate?: (value: string) => void;
  onUpdateText?: (value: string) => void;
  onBlur?: (value: string) => void;
  onSelectionChange?: (selection: {
    from: number;
    to: number;
    coordinates: { left: number; top: number };
  }) => void;
  autoFocus?: boolean;
  placeholder?: string;
}

export const useRichTextEditor = ({
  key,
  value,
  onUpdate,
  onUpdateText,
  onBlur,
  onSelectionChange,
  autoFocus,
  placeholder,
}: UseRichTextEditorProps) => {
  const [isTextSelected, setIsTextSelected] = useState(false);

  const editor = useEditor(
    {
      editable: true,
      injectCSS: false,
      content: value,
      autofocus: autoFocus || false,
      extensions: [
        StarterKit,
        Underline,
        Highlight,
        TextAlignExtension,
        LinkExtension.configure({
          autolink: true,
        }),
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Placeholder.configure({
          placeholder: placeholder || 'Write your notes here',
        }),
      ],
      onUpdate: ({ editor }) => {
        onUpdate && onUpdate(editor.getHTML());
        onUpdateText && onUpdateText(editor.getText());
      },
      onBlur: ({ editor }) => {
        onBlur && onBlur(editor.getHTML());
      },
      onSelectionUpdate: ({ editor }) => {
        const { selection } = editor.state;
        const { from, to } = selection;
        onSelectionChange &&
          onSelectionChange({
            from,
            to,
            coordinates: editor.view.coordsAtPos(from),
          });

        if (from - to === 0) {
          setIsTextSelected(false);
          return;
        }

        setIsTextSelected(true);
      },
    },
    [key],
  );

  return { editor, isTextSelected };
};
