import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Project } from '../../app/entities/project';
import { ProjectSettingsForm } from './components/project-settings-form';
import { DangerZone } from '../../features/projects/components/danger-zone';
import { Header } from './project.page';
import { uiStore } from '../../core/stores/ui-store';
import { ScreenContainer } from 'features/projects/components/styled-project-components';

interface SettingsTabProps {
  project?: Project;
}

export const ProjectSettingsRoute: React.FC<SettingsTabProps> = observer(({ project }) => {
  useEffect(() => {
    uiStore.currentLocation = 'project.settings';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <>
      <Header>Settings</Header>

      <ScreenContainer>
        <Container>
          <ProjectSettingsForm project={project} />

          <DangerZone project={project} />
        </Container>
      </ScreenContainer>
    </>
  );
});

const Container = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
`;
