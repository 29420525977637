import React, { DragEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { formatPicture } from '../../../core/services/image.service';
import { Upload } from '../../../components/icons/upload';
import { PhotoUploader } from '../../../components/photo-uploader/photo-uploader';
import { Storyboard } from '../../../app/entities/storyboard';
import { Shot } from '../../../app/entities/shot';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import type { Frame } from '../../../lib/utils/transform-image';

type CreateShotCardProps = {
  storyboard: Storyboard;
  zoom: number;
};

export const CreateShotCard: React.FC<CreateShotCardProps> = ({ storyboard, zoom }) => {
  const { t } = useTranslation('project');
  const [isShowingLimitsModal, setIsShowingLimitsModal] = useState(false);

  const handleCreate = async (file?: File) => {
    if (!storyboard.project?.canAddShot()) {
      setIsShowingLimitsModal(true);
      return;
    }

    let frame;

    if (file) {
      if (file.type === 'image/gif') {
        frame = {
          data: file,
          size: file.size,
          type: file.type,
        };
      } else {
        const options = { width: 640, height: 380 };
        frame = await formatPicture(file, options);
      }
    }

    const shot = await Shot.createShot({ projectId: storyboard.projectId, file: frame });
    await storyboard.addShot(shot._id);
  };

  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = async (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.persist();

    const files = Array.from(e.dataTransfer.files);

    for (let file of files) {
      await handleCreate(file);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!e.currentTarget.files) {
      return;
    }

    const files = Array.from(e.currentTarget.files);

    for (let file of files) {
      await handleCreate(file);
    }
  };

  return (
    <Container
      data-zoom={zoom}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {zoom <= 2 && (
        <PhotoUploader
          onMultipleChange={handleFileChange}
          buttonName="New shot"
          placeholderTitle={`${t('drag')} ${t('or')} ${t('browse_files')}`}
          placeholderDescription="JPG, PNG"
          multiple
        />
      )}

      {zoom > 2 && (
        <React.Fragment key="create-shot-card">
          <Title>New shot</Title>

          <FolderContainerAcceptedFilesContainer>
            <FolderContainerAcceptedFiles>{t('accepted')}: .jpg, .png</FolderContainerAcceptedFiles>
          </FolderContainerAcceptedFilesContainer>

          <FolderContainerUpload>
            <Upload />

            <FolderContainerUploadDragAndDrop>{t('drag')}</FolderContainerUploadDragAndDrop>

            <FolderContainerUploadDragAndDrop>{t('or')}</FolderContainerUploadDragAndDrop>

            <FolderContainerUploadBrowse>{t('browse_files')}</FolderContainerUploadBrowse>
          </FolderContainerUpload>

          <InputFile
            multiple
            id="file"
            type="file"
            accept={'image/*'}
            onChange={handleFileChange}
          />
        </React.Fragment>
      )}

      {isShowingLimitsModal && (
        <LimitReachedModal
          type="shots"
          eventName="Shots exceed 10"
          onCancel={() => setIsShowingLimitsModal(false)}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  scroll-snap-align: center;
  width: 100%;
  min-width: 100%;

  &[data-zoom='3'],
  &[data-zoom='4'],
  &[data-zoom='5'],
  &[data-zoom='6'] {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    border-radius: var(--border-radius-medium);
    border: 2px dashed var(--color-grey-3);
    padding: 2.4rem;
    position: relative;
  }

  &:hover {
    cursor: pointer;
  }
`;

const InputFile = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
`;

const Title = styled.div`
  font-family: Inter, sans-serif;
  font-weight: 300;
  color: var(--white-default);
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 18px;
  text-align: center;

  b {
    font-weight: 300;
    color: var(--color-primary);
  }
`;

const FolderContainerAcceptedFilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 0.4rem;
`;

const FolderContainerAcceptedFiles = styled.div`
  height: 1.4rem;
  margin-left: 0.6rem;
  color: var(--color-text-grey-light);
  font-family: Inter, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.4rem;
  text-align: center;
`;

const FolderContainerUpload = styled.div`
  display: flex;
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  align-content: center;

  svg {
    margin: auto;
  }
`;

const FolderContainerUploadDragAndDrop = styled.p`
  height: 1.4rem;
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: -0.25px;
  line-height: 2rem;
  text-align: center;
  margin-top: 1.2rem;
`;

const FolderContainerUploadBrowse = styled.div`
  height: 1.4rem;
  color: var(--color-primary);
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  letter-spacing: -0.25px;
  line-height: 2rem;
  text-align: center;
  margin-top: 1.2rem;
`;
