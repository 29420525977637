import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CommentContext } from '@producer-io/ui-kit';

import { Comment } from '../../app/entities/comment';
import { CommentItem } from './CommentItem';
import { CommentIcon } from '../icons/CommentIcon';
import { breakPoint } from '../../app/theme';
import { EmptyDrawerPlaceholder } from '../empty-drawer.placeholder';

type CommentsListProps = {
  showCompleted?: boolean;
  onSelectComment?: (comment: Pick<Comment, 'timestamp' | 'page'>) => void;
};

export const CommentsList: React.FC<CommentsListProps> = observer(
  ({ showCompleted, onSelectComment }) => {
    const { comments, currentComment, commentsListRef, selectComment } = useContext(CommentContext);

    const filteredComments = comments.filter((el) =>
      showCompleted ? true : !el.completed,
    ) as Comment[];

    const hasComment = !!comments.length;

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      const currentCommentId = searchParams.get('commentId');
      if (currentCommentId) {
        const comment = Comment.getOne(currentCommentId);

        if (comment) {
          const selectedComment = comment?.parent || comment;
          selectComment(selectedComment);

          const element = document.getElementById(selectedComment._id);

          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });

            if (onSelectComment) {
              onSelectComment(selectedComment);
            }

            setSearchParams((searchParams) => {
              searchParams.delete('commentId');
              return searchParams;
            });
          }
        }
      }
    }, [
      filteredComments,
      currentComment,
      onSelectComment,
      searchParams,
      selectComment,
      setSearchParams,
    ]);

    return (
      <CommentsContainer ref={commentsListRef}>
        {hasComment ? (
          filteredComments?.map((comment) => (
            <CommentContainer key={comment._id} data-selected={comment._id === currentComment?._id}>
              <CommentItem
                comment={comment as Comment}
                onClick={(comment) =>
                  setSearchParams((searchParams) => {
                    searchParams.set('commentId', comment!._id);
                    return searchParams;
                  })
                }
              />

              {comment.replies?.map((reply: Comment) => (
                <CommentItem
                  key={reply._id}
                  comment={reply}
                  onClick={(reply) =>
                    setSearchParams((searchParams) => {
                      searchParams.set('commentId', reply.parent!._id);
                      return searchParams;
                    })
                  }
                  isReply
                />
              ))}
            </CommentContainer>
          ))
        ) : (
          <EmptyDrawerPlaceholder
            icon={<CommentIcon />}
            text={'It is quiet in here. Start the conversation by leaving the first comment'}
          />
        )}
      </CommentsContainer>
    );
  },
);

CommentsList.displayName = 'CommentsList';

const CommentsContainer = styled.div`
  border-radius: 0 0 1.6rem 0;
  flex: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  position: relative;

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    padding-bottom: 12rem;
  }
`;

const CommentContainer = styled.div`
  border-left: 0.2rem solid;
  border-left-color: transparent;
  padding-inline-end: calc(0.8rem - 100vw + 100%);

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding-inline-end: min(1.2rem - (40rem - 100%), 1.2rem);
  }

  & > :not(:first-child) {
    margin-inline-start: 1.6rem;
  }

  &:hover {
    cursor: pointer;

    &:not([data-selected='true']) {
      background-color: var(--color-grayscale-charleston);
    }
  }

  &[data-selected='true'] {
    border-left-color: var(--color-shades-hibiscus);
  }
`;
