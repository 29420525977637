import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Contact } from '../../../app/entities/contact';
import { FormBuilderModal } from '../../../components/form-builder-modal/form-builder-modal';
import { Frame } from '../../../lib/utils/transform-image';
import { createContact } from '../stores/contact.slice';
import { formatPicture } from '../../../core/services/image.service';
import { buildContactForm } from '../../../routes/contacts/contact-form.definition';

type CreateContactProps = {
  spaceId: string;
  onCancel: () => void;
  onSubmit: (contact: Contact) => void;
};

export const CreateContactDrawer: React.FC<CreateContactProps> = ({
  spaceId,
  onCancel,
  onSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState<Frame>();
  const { t } = useTranslation('contact');

  const handleSubmitContact = async (editedData: Partial<Contact>) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      const contact = await createContact({
        contactInfo: editedData,
        accountId: spaceId!,
        file,
      });

      onSubmit(contact);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  const handleSetImage = async (file?: File) => {
    if (!file) {
      return null;
    }

    const options = { width: 480, height: 480 };
    const frame = await formatPicture(file, options);
    setFile(frame);
  };

  const createContactFormDefinition = buildContactForm(true, t, handleSetImage);

  return (
    <FormBuilderModal
      model="contact"
      definition={createContactFormDefinition}
      onSubmit={handleSubmitContact}
      onCancel={onCancel}
    />
  );
};
