import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LockClosedIcon } from '@radix-ui/react-icons';

import { api } from '../../api';
import { SettingsSection } from '../../features/settings/components/settings-section';
import { TextInput } from '../../components/text-input/TextInput';
import { TextAreaInput } from '../../components/textarea-input/TextAreaInput';
import { ElevatedButton } from '../../components/buttons';
import { SSOPlaceholder } from '../../features/settings/components/sso-placeholder';
import { Switch } from '../../components/toggle/Switch';
import { SettingsAction } from '../../features/user/components/settings-action';
import { useCurrentSpace } from '../../hooks/use-current-space';
import { uiStore } from '../../core/stores/ui-store';

const formSchema = yup.object().shape({
  signOnURL: yup.string().url().required(),
  issuer: yup.string().url().required(),
  certificate: yup.string(),
});

export const SecuritySettingsRoute: React.FC = () => {
  const [isSSOEnabled, setIsSSOEnabled] = useState(false);
  const space = useCurrentSpace();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    api.get(`/spaces/${space._id}/sso`).then(({ data }) => {
      setIsSSOEnabled(data.isEnabled);
    });
  }, [space._id]);

  useEffect(() => {
    uiStore.showBack = true;

    uiStore.currentLocation = 'space.settings';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  const onSubmit = () => {
    const values = getValues();

    api.patch(`/spaces/${space._id}/sso`, { isEnabled: isSSOEnabled, ...values });
  };

  const isSecurityScreenEnabled = !!space?.flags?.isSSOEnabled;

  return (
    <Page>
      {isSecurityScreenEnabled ? (
        <>
          <SettingsSection
            title="SAML single sign-on"
            description="Manage the authentication for your organization through an identity provider like Azure, Okta, OneLogin, Ping Identity or your custom SAML 2.0 provider."
          />

          <SettingsAction
            title={'Enable SAML single sign-on'}
            hint={
              space.alias && isSSOEnabled
                ? `Your organisation single sign-on URL is <a href="https://app.the-producer.io/orgs/${space.alias}/sso." target="_blank">https://app.the-producer.io/orgs/${space.alias}/sso.</a>`
                : undefined
            }
            icon={<LockClosedIcon />}
            action={
              <Switch isToggled={isSSOEnabled} onToggle={() => setIsSSOEnabled(!isSSOEnabled)} />
            }
          />

          {isSSOEnabled && (
            <Form>
              <TextInput
                {...register('signOnURL')}
                label="Sign on URL"
                placeholder="https://example.com"
                hint="Members will be forwarded here when signing in to your organization."
                errorMessage={errors.signOnURL?.message as string}
                hasError={!!errors.signOnURL?.message}
              />

              <TextInput
                {...register('issuer')}
                label="Issuer"
                placeholder="https://example.com"
                hint="Typically a unique URL generated by your SAML identity provider."
                errorMessage={errors.issuer?.message as string}
                hasError={!!errors.issuer?.message}
              />

              <TextAreaInput
                {...register('certificate')}
                label="Public certificate"
                errorMessage={errors.certificate?.message as string}
                hasError={!!errors.certificate?.message}
              />
            </Form>
          )}

          <Footer>
            <ElevatedButton text="Save" onClick={onSubmit} />
          </Footer>
        </>
      ) : (
        <SSOPlaceholder />
      )}
    </Page>
  );
};

const Page = styled.div`
  width: 100%;
  max-width: 92.8rem;
  margin: 0 auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-top: 1.6rem;
`;

const Footer = styled.div`
  margin-top: 1.6rem;
`;
