import { Location } from '../../app/entities/location';
import { api } from '../../api';
import { LocationCreateDto } from '../../dto/location/location-create.dto';
import { upload } from '../../core/services/file.service';
import { Frame } from 'lib/utils/transform-image';
import { entityPool } from '../../core/engine/engine';
import { Asset } from 'app/entities/asset';

type FetchProjectLocationsInput = {
  projectId: string;
};

/**
 * Update location Cover
 */

type UpdateLocationCoverInput = {
  locationId: string;
  file: Frame;
};

export const updateLocationCover = async ({ locationId, file }: UpdateLocationCoverInput) => {
  const { data } = await api.put(`/locations/${locationId}/cover`, {
    fileSize: file.size,
    fileType: file.type,
  });

  // @ts-ignore
  await upload(data.links.upload, file.data ? file.data : file);

  const location = Location.getOne(locationId)!;
  location.cover = data.cover;
};

export const fetchProjectLocations = async ({ projectId }: FetchProjectLocationsInput) => {
  const { data } = await api.get(`/projects/${projectId}/locations`);

  data.map((el: any) => {
    const location = Object.assign(new Location(), el);
    entityPool.insert(location);
  });
};

type CreateProjectLocationInput = FetchProjectLocationsInput & LocationCreateDto;

export const createProjectLocation = async ({
  projectId,
  ...input
}: CreateProjectLocationInput) => {
  const { data } = await api.post(`/projects/${projectId}/locations`, input);

  const location = Object.assign(new Location(), data);
  entityPool.insert(location);

  /**
   * Get folder
   */
  const { data: folderData } = await api.get(`/assets/${location.assetsFolderId}`);
  const folder = Object.assign(new Asset(), folderData);
  entityPool.insert(folder);

  return location;
};
