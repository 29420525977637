import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';
import styled from 'styled-components';

import { Project } from '../../app/entities/project';
import { GanttChart } from '../../components/gantt-chart/gantt-chart';
import { StepGanttHeader } from './components/step-gantt-header';
import { uiStore } from '../../core/stores/ui-store';
import { Header } from './project.page';
import { useCurrentMember } from '../../hooks/use-current-member';

interface CalendarProps {
  project: Project;
}

export const ProjectCalendarRoute: React.FC<CalendarProps> = observer(({ project }) => {
  useEffect(() => {
    uiStore.currentLocation = 'project.calendar';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  const currentMember = useCurrentMember();

  const isViewOnly = currentMember?.role === 'guest';

  const ganttItems: React.ComponentProps<typeof GanttChart>['items'] = project?.stages.map(
    (stage, index) => ({
      id: stage._id,
      name: stage.name,
      nameSuffix: <StageNumber>{(index + 1).toString().padStart(2, '0')}</StageNumber>,
      ...(stage.startDate && { startDate: new Date(stage.startDate) }),
      ...(stage.dueDate && { dueDate: new Date(stage.dueDate) }),
      ...(stage.completedSteps && { completed: stage.completedSteps }),
      ...(stage.totalSteps && { total: stage.totalSteps }),
      subItems: stage.steps?.map((step) => ({
        id: step._id,
        name: step.displayName,
        onItemUpdate: (values: { dueDate?: Date; startDate?: Date }) => {
          const startDate =
            (values.startDate && DateTime.fromJSDate(values.startDate).toISO()) || undefined;
          const dueDate =
            (values.dueDate && DateTime.fromJSDate(values.dueDate).toISO()) || undefined;

          if (startDate || dueDate) {
            step.update({
              ...(startDate && { startDate }),
              ...(dueDate && { dueDate }),
            });
          }
        },
        isDone: step.done,
        sideHeader: <StepGanttHeader step={step} isViewOnly={isViewOnly} />,
        ...(step.startDate && { startDate: new Date(step.startDate) }),
        ...(step.dueDate && { dueDate: new Date(step.dueDate) }),
      })),
    }),
  );

  return (
    <Container>
      <Header>
        <div>Calendar</div>
      </Header>

      <ToolBar />

      <ChartWrapper>
        <GanttChart items={ganttItems} />
      </ChartWrapper>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
`;

const ChartWrapper = styled.div`
  flex: 1;
  overflow: auto;
  padding-bottom: 1.6rem;
`;

const StageNumber = styled.div`
  font-size: 1.4rem;
  color: var(--color-surfaces-app-background-0);
  border-radius: 0.4rem;
  background-color: var(--color-texts-high-contrast);
  padding: 0.1rem 0.4rem;
  font-weight: 600;
`;

const ToolBar = styled.div`
  // height: 4rem;
  // min-height: 4rem;
  height: 0;
`;
