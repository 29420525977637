import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import * as Sentry from '@sentry/react';

import { HorizontalMenu } from '../../components/horizontal-menu/HorizontalMenu';
import { DirectorPage, DirectorPageProps } from './pages/director.page';
import { LineProducerPage, LineProducerPageProps } from './pages/line-producer.page';
import { CommonFilePage, CommonFilePageProps } from './pages/common-file.page';
import { StoryboardPage, StoryboardPageProps } from './pages/storyboard.page';
import { LocationPage, LocationPageProps } from './pages/location.page';
import { CastingPage, CastSelectionPageProps } from './pages/casting.page';
import { ShotListPage } from './pages/shot-list.page';
import { CrewPageProps, TeamPage } from './pages/team.page';
import { LoaderContainer } from './components/step-container';
import { PageLoader } from '../../app/components/page-loader';
import { CrashErrorScreen } from './components/crash-error-screen';
import { useCurrentProject } from 'hooks/use-current-project';
import { useCurrentStep } from 'hooks/use-current-step';
import { breakPoint } from 'app/theme';
import { ShootingDayScreen } from 'features/shootingdays/screens/shooting-day.screen';
import { ScheduleScreen } from 'features/schedule/screens/schedule.screen';
import { ProducerScreen } from 'features/collaborators/screens/producer.screen';

type ScreenType = Record<
  string,
  | React.FC<CommonFilePageProps>
  | React.FC<StoryboardPageProps>
  | React.FC<DirectorPageProps>
  | React.FC<LineProducerPageProps>
  | React.FC<LocationPageProps>
  | React.FC<CastSelectionPageProps>
  | React.FC<CrewPageProps>
  | React.FC<any>
>;

const screenType: ScreenType = {
  default: CommonFilePage,
  schedule: ScheduleScreen,
  storyboard: StoryboardPage,
  producer: ProducerScreen,
  director: DirectorPage,
  lineProducer: LineProducerPage,
  video: CommonFilePage,
  review: CommonFilePage,
  wip: CommonFilePage,
  location: LocationPage,
  casting: CastingPage,
  shootingDay: ShootingDayScreen,
  shotList: ShotListPage,
  crew: TeamPage,
};

export const ProjectStepRoute = observer(() => {
  const project = useCurrentProject()!;
  const step = useCurrentStep()!;

  // @ts-ignore
  let Screen = step && screenType[step.type];

  if (!Screen) {
    Screen = screenType['default'];
  }

  if (!step) {
    return (
      <LoaderContainer>
        <PageLoader />
      </LoaderContainer>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={<CrashErrorScreen />}>
      <HorizontalMenu />

      <Content>
        <Screen project={project} step={step} />
      </Content>
    </Sentry.ErrorBoundary>
  );
});

const Content = styled.div`
  position: relative;
  height: 100%;
  max-height: 100%;

  @media screen and (min-width: ${breakPoint.medium}px) {
    max-height: calc(100% - 4rem);
  }
`;
