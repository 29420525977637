import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useSortable } from '@dnd-kit/sortable';

import { Step } from '../../../app/entities/step';
import { StageHeader } from './stage-header';
import { StepList } from '../../../components/tasks-list/StepList';
import { CreateStepIndicator } from './create-step-indicator';
import { Stage as StageModel } from '../../../app/entities/stage';

interface PhaseProps {
  stage: StageModel;
}

export const Stage: React.FC<PhaseProps> = observer(({ stage }) => {
  const { setNodeRef } = useSortable({
    id: stage._id,
    data: {
      type: 'stage',
    },
  });

  const handleCreateShootingDay = () => {
    Step.createShootingDay({
      projectId: stage.projectId,
    });
  };

  useEffect(() => {
    // TODO Remove after bootstrap
    if (stage.name === 'Production') {
      stage.steps = stage.steps
        .slice()
        .sort(
          (a, b) =>
            (a.dueDate ? new Date(a.dueDate).getTime() : 0) -
            (b.dueDate ? new Date(b.dueDate).getTime() : 0),
        );
    }
  }, [stage]);

  return (
    <Container id={`${stage.name.toLowerCase().replace(' ', '-')}stage`}>
      <Header ref={stage.name !== 'Production' ? setNodeRef : undefined}>
        <StageHeader
          name={stage.name}
          index={stage.order}
          completed={stage.completedSteps}
          total={stage.totalSteps}
          isActive
        />
      </Header>

      <StepList tasks={stage.steps} />

      {stage.name.toLowerCase() !== 'production' && <CreateStepIndicator stageId={stage._id} />}

      {stage.name.toLowerCase() === 'production' && (
        <AddStep id="add-shooting-day" onClick={handleCreateShootingDay}>
          Add shooting day
        </AddStep>
      )}
    </Container>
  );
});

const Container = styled.div`
  flex: 1;
  width: 90%;
  max-width: 90%;
  min-width: 90%;
  scroll-snap-align: start;

  @media (min-width: 768px) {
    min-width: 24rem;
  }
`;

const Header = styled.div`
  margin-bottom: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: default;
`;

const AddStep = styled.div`
  height: 7.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-radius-medium);
  border: #8a8f98 2px dashed;
  color: #8a8f98;
  opacity: 0.75;
  font-size: 1.6rem;
  margin-top: 0.8rem;

  &:hover {
    cursor: pointer;
    color: #cacdd5;
    border: 1.5px solid #cacdd5;
  }
`;
