import React, { useState } from 'react';
import styled from 'styled-components';

import { ElevatedButton } from '../buttons';
import { BaseDialog, DialogFooter } from '../dialog';
import { OutlinedButton } from '../buttons';
import { CrossCircledFilledIcon } from '../icons/cross-circled-filled.icon';
import { authStore } from '../../core/stores/auth-store';
import { Tooltip } from '../tooltip/Tooltip';
import { toastPool } from '../../features/toasts/models/toast-pool';
import { Toast } from '../../features/toasts/models/toast';
import { useCurrentMember } from '../../hooks/use-current-member';

export const UnauthorizedDialog: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const currentMember = useCurrentMember();

  const handleCancel = () => {
    authStore.setAuthorized(true);
  };

  const handleConfirm = async () => {
    if (!currentMember || isLoading) return;

    setIsLoading(true);
    try {
      await currentMember.requestMembership();
      toastPool.insert(new Toast('Membership successfully requested', 'success'));
      authStore.setAuthorized(true);
    } finally {
      setIsLoading(false);
    }
  };

  const dialogActions = [
    <OutlinedButton key="close" onClick={handleCancel} text="Close" disabled={isLoading} />,
  ];

  if (currentMember && currentMember.role === 'guest') {
    dialogActions.push(
      <Tooltip
        key="requestMembership"
        text="Membership already requested"
        position="top"
        disabled={!currentMember.membershipRequested}
      >
        <ElevatedButton
          onClick={handleConfirm}
          text="Request creator role"
          disabled={currentMember.membershipRequested}
          isLoading={isLoading}
        />
      </Tooltip>,
    );
  }

  return (
    <BaseDialog onClose={handleCancel}>
      <Container>
        <Icon>
          <CrossCircledFilledIcon />
        </Icon>

        {currentMember?.role === 'guest' ? (
          <>
            <h1>Request Access</h1>
            <p>
              You do not have permission to perform this action. To gain access, please consider
              requesting the creator role from the owner or an admin.
            </p>
          </>
        ) : (
          <>
            <h1>Action denied</h1>
            <p>Sorry, but you do not have the appropriate permissions to perform this action.</p>
          </>
        )}
      </Container>

      <DialogFooter actions={dialogActions} />
    </BaseDialog>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.8rem 3.2rem 2.4rem;
  max-width: 47.6rem;
  text-align: center;
  color: white;

  h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    margin-bottom: 0.8rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
  }
`;

const Icon = styled.div`
  margin-bottom: 3.2rem;
  svg {
    color: var(--color-error);
    height: 3.6rem;
    width: 3.6rem;
  }
`;
