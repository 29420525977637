import React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import { ContactDetailScreen } from './contact-detail.screen';
import { PagePlaceholder } from '../../components/page-placeholder/page-placeholder';
import { breakPoint } from '../../app/theme';

export const ContactDetailsPage: React.FC = () => (
  <Container>
    <Routes>
      <Route path="/:contactId/*" element={<ContactDetailScreen />} />

      <Route
        path="/"
        element={
          <EmptyPlaceholderWrapper>
            <PagePlaceholder
              backgroundImgUrl={'/images/default-background.svg'}
              description="Select contact to view"
            />
          </EmptyPlaceholderWrapper>
        }
      />
    </Routes>
  </Container>
);

const Container = styled.div`
  flex: 2 1 75%;
  min-height: 0;
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius-medium);
  overflow-y: auto;
  background-color: var(--color-grayscale-eerie-black);
  gap: 2.4rem;

  @media screen and (min-width: ${breakPoint.small}px) {
    flex-direction: row;
    height: unset;
    padding: 1.6rem;
  }

  @media screen and (max-width: ${breakPoint.medium}px) {
    min-height: 100%;
  }
`;

const EmptyPlaceholderWrapper = styled.div`
  width: 100%;

  div {
    user-select: none;
    background-color: var(--color-grayscale-eerie-black);
    background-size: 36rem;
    max-width: unset;

    p {
      display: inline-block;
      font-weight: 500;
      font-size: 3.2rem;
      color: var(--color-grayscale-white);
    }
  }
`;
