import { api } from '../../api';
import { Step } from '../../app/entities/step';
import { entityPool } from '../../core/engine/engine';
import { Stage } from '../../app/entities/stage';
import { Project } from '../../app/entities/project';
import { Asset } from '../../app/entities/asset';

/**
 * Create a new step
 */
type CreateStepInput = {
  stageId: string;
  name: string;
  startDate?: string;
  dueDate?: string;
  type: string;
};

export const createStep = async ({ stageId, ...input }: CreateStepInput) => {
  const { data } = await api.post(`/stages/${stageId}/steps`, input);
  entityPool.insert(Object.assign(new Step(), { ...data, stageId }));

  const stage = Stage.getOne(stageId);

  if (!stage) {
    return;
  }

  const project = Project.getOne(stage.projectId);
  if (project) {
    project.progress = data.progress;
  }

  /**
   * Get folder
   */
  const { data: folderData } = await api.get(`/assets/${data.folderAssetId}`);
  const folder = Object.assign(new Asset(), folderData);
  entityPool.insert(folder);

  return data;
};
