import React from 'react';
import styled from 'styled-components';
import { useDroppable } from '@dnd-kit/core';
import { observer } from 'mobx-react-lite';
import { Cross1Icon } from '@radix-ui/react-icons';

import { Shot } from '../../../app/entities/shot';
import { SelectionProvider } from '../../../core/contexts/selection.context';
import { UnusedShotCard } from './unused-shot-card';
import { EmptyDrawerPlaceholder } from '../../../components/empty-drawer.placeholder';
import { ShotsIcon } from '../../../components/icons/shots.icon';
import { ToolBar, Tools } from 'components/toolbar/toolbar';
import { IconButton } from 'components/buttons';

interface ShotArchiveDrawerProps {
  onClose: () => void;
  shots: Shot[];
}

export const ShotArchiveDrawer: React.FC<ShotArchiveDrawerProps> = observer(
  ({ shots, onClose }) => {
    const unusedShotsIds = shots.map((shot) => shot._id);

    const { setNodeRef, isOver } = useDroppable({
      id: 'boneyard',
      data: { type: 'boneyard' },
    });

    return (
      <SelectionProvider items={unusedShotsIds}>
        <Drawer>
          <ToolBar>
            <Title>Shot archive</Title>

            <Tools>
              <IconButton icon={<Cross1Icon />} size={'small'} onClick={onClose} />
            </Tools>
          </ToolBar>

          <Content ref={setNodeRef}>
            {shots.length ? (
              <>
                {shots.map((shot) => (
                  <UnusedShotCard key={shot._id} shot={shot} />
                ))}

                {isOver && (
                  <Placeholder data-is-over={isOver} data-is-overlay>
                    <EmptyDrawerPlaceholder
                      icon={<ShotsIcon />}
                      text={'Drop a shot here to remove it from the storyboard.'}
                    />
                  </Placeholder>
                )}
              </>
            ) : (
              <Placeholder data-is-over={isOver}>
                <EmptyDrawerPlaceholder
                  icon={<ShotsIcon />}
                  text={'Drop a shot here to remove it from the storyboard.'}
                />
              </Placeholder>
            )}
          </Content>
        </Drawer>
      </SelectionProvider>
    );
  },
);

const Drawer = styled.div`
  border-left: 1px var(--content-border-color) solid;
  width: 36rem;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 1.4rem;
  color: var(--text-color-white);
`;

const Content = styled.div`
  padding: 1.6rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  flex: 1;
  overflow-y: auto;
`;

const Placeholder = styled.div`
  height: 100%;
  border-radius: var(--border-radius-medium);
  transition: all 100ms linear;

  &[data-is-over='true'] {
    background-color: var(--bg-elevation-1);
  }

  &[data-is-overlay='true'] {
    position: absolute;
    top: 1.6rem;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
  }
`;
