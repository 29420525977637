import React, { useMemo, useState } from 'react';
import { LineHeightIcon, DownloadIcon, Pencil1Icon, PlusIcon } from '@radix-ui/react-icons';
import { observer } from 'mobx-react-lite';

import { EyeClosedIcon, EyeOpenIcon } from '../../../components/icons';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { ScreenHeader } from '../../projects/components/screen-header';
import { downloadSchedule } from '../services/schedule.service';
import { ElevatedButton } from '../../../components/buttons';
import { AddLine, bannerItems } from './add-line';
import { RenumberShotsDialog } from '../../shots/components/renumber-shots.dialog';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import { useResponsive } from 'hooks/useResponsive';

import type { Project } from '../../../app/entities/project';
import type { Step } from '../../../app/entities/step';
import { useCurrentMember } from '../../../hooks/use-current-member';

type ScheduleHeaderProps = {
  step: Step;
  project: Project;
};

export const ScheduleHeader: React.FC<ScheduleHeaderProps> = observer(({ step, project }) => {
  const { isDesktop } = useResponsive();

  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isRenumberModalOpen, setIsRenumberModalOpen] = useState(false);
  const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);

  const currentMember = useCurrentMember();
  const userStepPreferences = currentMember?.preferences.stepsUI[step._id];

  const stripboard = project.stripboard;

  const handleSelectBannerItem = ({ title, estimatedTime }: any) => {
    stripboard.addBannerStrip({
      position: 1,
      text: title,
      estimatedTime,
    });
  };

  const handleDownload = async () => {
    if (!project?.space?.canExportPdf()) {
      return setShowLimitReachedModal(true);
    }

    if (generatingPDF) {
      return;
    }

    setGeneratingPDF(true);

    await downloadSchedule(project._id, project.name);

    setGeneratingPDF(false);
  };

  const shotsCount = useMemo(() => {
    if (!stripboard?.strips) {
      return 0;
    }

    return stripboard.strips.filter((strip) => strip.type === 'shot').length;
  }, [stripboard?.strips]);

  const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      title:
        userStepPreferences?.showHiddenShots === false ? 'Show Hidden Shots' : 'Hide Hidden Shots',
      onSelect: () => currentMember?.toggleShowHiddenShots(step._id),
      icon: userStepPreferences?.showHiddenShots === false ? <EyeClosedIcon /> : <EyeOpenIcon />,
    },
    {
      title: 'Renumber Shots',
      onSelect: () => setIsRenumberModalOpen(true),
      icon: <LineHeightIcon />,
    },
    {
      title: 'Add Line',
      icon: <PlusIcon />,
      subItems: bannerItems.map((item) => ({
        ...item,
        onSelect: () => handleSelectBannerItem(item),
      })),
    },
    ...(!isDesktop
      ? [
          {
            title: 'Generate PDF',
            icon: <DownloadIcon />,
            onSelect: () => stripboard.strips[0] && handleDownload(),
            disabled: generatingPDF || !shotsCount,
          },
        ]
      : []),
    {
      title: 'Rename step',
      icon: <Pencil1Icon />,
      onSelect: () => setIsRenameModalOpen(true),
    },
  ];

  return (
    <>
      <ScreenHeader
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        menu={<DropdownMenu items={items} size={'large'} />}
        actions={
          isDesktop && stripboard
            ? [
                !!stripboard && <AddLine key="add-line" stripboard={stripboard} />,
                !!stripboard && (
                  <ElevatedButton
                    key="generate-pdf"
                    onClick={() => stripboard.strips[0] && handleDownload()}
                    isLoading={generatingPDF}
                    text="Generate PDF"
                    disabled={!shotsCount}
                  />
                ),
              ]
            : []
        }
      />

      {isRenumberModalOpen && (
        <RenumberShotsDialog
          stripboard={stripboard}
          onCancel={() => setIsRenumberModalOpen(false)}
        />
      )}

      {isRenameModalOpen && (
        <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
      )}

      {showLimitReachedModal && (
        <LimitReachedModal
          title="Upgrade your space to export your schedule as a PDF"
          eventName="Export schedule attempt"
          onCancel={() => setShowLimitReachedModal(false)}
        />
      )}
    </>
  );
});
