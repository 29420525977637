import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MeasuringConfiguration,
  MeasuringStrategy,
  MouseSensor,
  pointerWithin,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { createPortal } from 'react-dom';

import { SortableShotGrid } from './SortableShotGrid';
import { Storyboard } from '../../../app/entities/storyboard';
import { StoryboardToolbar } from './storyboard.toolbar';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { CountDragPreview } from '../../schedule/components/count-drag-preview';
import { SelectionProvider } from '../../../core/contexts/selection.context';
import { Shot } from '../../../app/entities/shot';
import { ShotArchiveDrawer } from './shot-archive.drawer';
import { useCurrentMember } from '../../../hooks/use-current-member';

const measuring: MeasuringConfiguration = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const DraggableStoryboard: React.FC<{ storyboard: Storyboard; shots: Shot[] }> = observer(
  ({ storyboard, shots }) => {
    const currentMember = useCurrentMember();

    const { setNodeRef, isOver } = useDroppable({
      id: storyboard._id,
      data: { type: 'storyboard' },
    });

    return (
      <Container ref={setNodeRef} data-over={isOver}>
        <SortableShotGrid
          storyboard={storyboard}
          shots={shots}
          zoomLevel={currentMember?.preferences.storyBoardPreferences.zoom || 1}
          options={storyboard?.settings || { properties: [] }}
        />
      </Container>
    );
  },
);

interface StoryboardContainerProps {
  storyboard: Storyboard;
}

export const StoryboardView = observer(({ storyboard }: StoryboardContainerProps) => {
  const [activeDraggedShot, setActiveDraggedShot] = useState<string | null>(null);
  const [draggingCount, setDraggingCount] = useState<number>(0);
  const [isBoneyardOpen, setIsBoneyardOpen] = useState(false);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleDragStart = ({ active }: DragStartEvent) => {
    if (!active) return;
    setActiveDraggedShot(active.id.toString());
    setDraggingCount(active.data.current?.elements?.length);
  };

  const items = storyboard?.shots?.slice()?.sort((a, b) => a.position - b.position) as Shot[];

  const handleDragEnd = async ({ active, over }: DragEndEvent) => {
    if (!over || !active) return;

    const originType = active.data.current?.type || active.data.current?.sortable?.containerId;
    const destinationType = over.data.current?.type || over.data.current?.sortable?.containerId;

    if (!originType || !destinationType || !active.data.current?.elements) return;

    let position = over.data.current?.position;
    if (position !== undefined && position !== null && position > 0) {
      position = position - 1;
    }

    if (originType === 'boneyard-shot' && destinationType === 'storyboard') {
      storyboard.assignShots(active.data.current.elements, position);
    } else if (originType === 'boneyard-shot' && destinationType === 'storyboard-grid') {
      storyboard.assignShots(active.data.current.elements, position);
    } else if (originType === 'storyboard-grid' && destinationType === 'boneyard') {
      storyboard.removeShots(active.data.current.elements);
    } else if (originType === 'storyboard-grid' && destinationType === 'storyboard-grid') {
      if (over.id === active.id) {
        return;
      }

      const destination = storyboard!.shots.find((el) => el._id === over.id);

      if (!destination) {
        return;
      }

      storyboard!.edit(active.data.current?.elements, destination.position);
    }

    setActiveDraggedShot(null);
  };

  const handleDragCancel = () => {
    setActiveDraggedShot(null);
  };

  const unusedShots = storyboard.project!.shots.filter(
    (shot) => !storyboard.shots.some((s) => s._id === shot._id),
  );

  const shotsIds = storyboard.shots.map((shot) => shot._id);

  const toggleBoneyard = () => setIsBoneyardOpen(!isBoneyardOpen);

  return (
    <>
      <StoryboardToolbar storyboard={storyboard} toggleBoneyard={toggleBoneyard} />

      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        collisionDetection={pointerWithin}
        measuring={measuring}
        sensors={sensors}
      >
        <Content>
          <SelectionProvider items={shotsIds}>
            <DraggableStoryboard shots={items} storyboard={storyboard} />
          </SelectionProvider>

          {isBoneyardOpen && (
            <ShotArchiveDrawer shots={unusedShots} onClose={() => setIsBoneyardOpen(false)} />
          )}
        </Content>

        {createPortal(
          <DragOverlay modifiers={[snapCenterToCursor]}>
            {activeDraggedShot && (
              <OverlayWrapper>
                <CountDragPreview
                  count={draggingCount}
                  text={`${draggingCount} Shot${draggingCount > 1 ? 's' : ''}`}
                />
              </OverlayWrapper>
            )}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>
    </>
  );
});

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-height: calc(100% - 8rem);
  flex: 1;
  max-width: 100vw;
`;

const OverlayWrapper = styled.div`
  position: absolute;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow-y: scroll;
`;
