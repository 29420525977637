import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import {
  Cell,
  Table,
  TableHeader,
  THead,
  Row,
} from '../../../components/table/styled-shared-table';
import { IconButton } from '../../../components/buttons';
import { IconButtonWrapper } from '../../../components/buttons/icon-button';
import { MapIcon } from '../../../components/icons/map-icon';
import { formatAddress } from '../../../core/services/address.service';
import { InlineTextArea } from '../../../components/editable-text/inline-text-area';
import { EmptyTablePlaceholder } from '../../../components/table/empty-table-placeholder';

import type { Location } from '../../../app/entities/location';

type LocationsTableProps = {
  locations: (Omit<Location, 'update' | 'toPOJO' | 'save' | 'delete' | 'remove'> & {
    parking?: string;
  })[];
  searchLocationOnMaps: (address: string) => void;
  handleMetaUpdate: (locationId: string, parking: string) => void;
};

export const LocationsTable: React.FC<LocationsTableProps> = observer(
  ({ locations, searchLocationOnMaps, handleMetaUpdate }) => {
    if (!locations?.length) {
      return (
        <EmptyTablePlaceholder description="No locations set in the shots of this shooting day." />
      );
    }

    return (
      <Table>
        <col
          style={{
            width: '10%',
          }}
        />

        <col
          style={{
            width: '18%',
          }}
        />

        <col
          style={{
            width: '16%',
          }}
        />

        <col
          style={{
            width: '53%',
          }}
        />

        <col
          style={{
            width: '3%',
          }}
        />

        <TableHeader>
          <TableHeadCell>Name</TableHeadCell>

          <TableHeadCell>Address</TableHeadCell>

          <TableHeadCell>Contact Person</TableHeadCell>

          <TableHeadCell>Parking Instructions</TableHeadCell>

          <TableHeadCell />
        </TableHeader>

        <tbody>
          {locations?.map((location) => {
            const address = location.address && formatAddress(location.address);
            const isSearchable = !!(
              (location.address?.line1 || location.address?.line2) &&
              location.address?.city
            );

            return (
              <Row key={location._id}>
                <Cell>{location.name}</Cell>

                <Cell
                  onClick={() => {
                    if (!isSearchable || !address) {
                      return;
                    }
                    searchLocationOnMaps(address);
                  }}
                  style={{
                    cursor: isSearchable ? 'pointer' : 'default',
                  }}
                >
                  {address || ''}
                </Cell>

                <Cell>{location.contact}</Cell>

                <Cell>
                  <ParkingInstructions
                    defaultValue={location.parking}
                    onSubmit={(e) => {
                      handleMetaUpdate(location._id, e.target.value);
                    }}
                    rows={2}
                  />
                  {location.nearestParking}
                </Cell>

                <Cell>
                  {isSearchable && address && (
                    <IconButtonWrapper>
                      <IconButton
                        icon={<MapIcon />}
                        disabled={!isSearchable}
                        onClick={() => {
                          searchLocationOnMaps(address);
                        }}
                      />
                    </IconButtonWrapper>
                  )}
                </Cell>
              </Row>
            );
          })}
        </tbody>
      </Table>
    );
  },
);

const TableHeadCell = styled(THead)`
  border-bottom: 0.1rem solid var(--color-grayscale-tuna);

  &:first-child,
  &:last-child {
    padding-inline-start: 1.6rem;
  }
`;

const ParkingInstructions = styled(InlineTextArea)`
  border-radius: 0.4rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-grayscale-white);
  margin-inline-start: -0.8rem;

  &::placeholder {
    color: var(--color-grayscale-light-slate);
  }

  &:hover {
    background-color: var(--color-grayscale-arsenic);
  }

  &:focus {
    background-color: transparent !important;
    border-color: var(--color-grayscale-trout);
  }
`;
