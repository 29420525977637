import React, { PropsWithChildren } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import styled, { keyframes } from 'styled-components';

import { breakPoint } from '../../app/theme';
import { IconButton } from '../buttons';

type CollapsibleItemProps = {
  title?: React.ReactNode | string;
  actions?: React.ReactNode;
  defaultOpen?: boolean;
  isTransparent?: boolean;
  triggerType?: 'default-small' | 'default-medium' | 'transparent-small';
  titleAlignment?: 'start' | 'center';
  padding?: 'default' | 'none';
  onCollapseChange?: (isOpen?: boolean) => void;
};

export const CollapsibleItem: React.FC<PropsWithChildren<CollapsibleItemProps>> = ({
  title,
  actions,
  defaultOpen,
  isTransparent,
  triggerType = 'default-medium',
  titleAlignment = 'start',
  padding = 'default',
  onCollapseChange,
  children,
}) => {
  const [open, setOpen] = React.useState(defaultOpen);
  const [triggerVersion, triggerSize] = triggerType.split('-');

  const handleOpenChange = (isOpen?: boolean) => {
    setOpen(isOpen);
    onCollapseChange?.(isOpen);
  };

  return (
    <Collapsible.Root open={open} onOpenChange={handleOpenChange}>
      <Trigger id="collapsible-trigger" asChild>
        <Header data-transparent={isTransparent} data-align={titleAlignment} data-padding={padding}>
          {title && <Title>{title}</Title>}

          <RightContainer>
            {actions}

            {triggerVersion === 'default' ? (
              <IconButton
                icon={
                  <ChevronDownIcon
                    className="trigger-chevron"
                    color="var(--color-grayscale-manatee)"
                  />
                }
                size={triggerSize as React.ComponentProps<typeof IconButton>['size']}
              />
            ) : (
              <ChevronDownIcon className="trigger-chevron" color="var(--color-grayscale-manatee)" />
            )}
          </RightContainer>
        </Header>
      </Trigger>

      <Content id="collapsible-content">{children}</Content>
    </Collapsible.Root>
  );
};

const slideDown = keyframes`
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
`;

const slideUp = keyframes`
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
`;

const RightContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  align-items: center;
  min-width: max-content;
`;

const Title = styled.div`
  color: var(--color-grayscale-white);
  font-size: 1.3rem;
  font-weight: 400;
  overflow: hidden;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.4rem;
  background: var(--color-grayscale-tuna);
  cursor: pointer;
  gap: 1.6rem;
  color: #e3e4e7;

  &[data-transparent='true'] {
    background: transparent;
  }

  &[data-align='center'] {
    justify-content: center;

    ${RightContainer} {
      position: absolute;
      top: 0.8rem;
      right: 1.6rem;

      @media screen and (min-width: ${breakPoint.medium}px) {
        top: 1.2rem;
        right: 2.4rem;
      }
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    height: 4rem;
    padding: 0 1.6rem;
  }

  &[data-padding='none'] {
    padding: unset;

    & ${Title} {
      line-height: unset;
    }
  }
`;

const Trigger = styled(Collapsible.Trigger)`
  .trigger-chevron {
    transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='open'] .trigger-chevron {
    transform: rotate(180deg);
  }
`;

const Content = styled(Collapsible.Content)`
  overflow: hidden;

  &[data-state='open'] {
    animation: ${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }

  &[data-state='closed'] {
    animation: ${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1);
  }
`;
