import React, { MouseEvent, useEffect } from 'react';
import styled from 'styled-components';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { DragHandleDots2Icon } from '@radix-ui/react-icons';

import { Shot } from '../../../app/entities/shot';
import { useSelection } from '../../../core/contexts/selection.context';
import { getModifier } from '../../../core/services/event.service';

interface UnusedShotCardProps {
  shot: Shot;
}

export const UnusedShotCard = ({ shot }: UnusedShotCardProps) => {
  const { select, elements } = useSelection();

  const { transform, isDragging, listeners, attributes, setNodeRef } = useDraggable({
    id: shot._id,
    data: {
      type: 'boneyard-shot',
      elements: elements.length ? elements : [shot._id],
    },
  });

  const style = {
    transform: CSS.Transform.toString(transform),
  };

  const isSelected = elements.includes(shot._id);

  useEffect(() => {
    if (!isSelected && isDragging) {
      select(shot._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, isDragging]);

  const handleSingleClick = (e: MouseEvent) => {
    const modifier = getModifier(e.nativeEvent);
    select(shot._id, modifier);
  };

  return (
    <ShotCard
      ref={setNodeRef}
      style={style}
      data-dragging={isDragging}
      data-selected={isSelected}
      onClick={handleSingleClick}
      {...attributes}
      {...listeners}
    >
      <DragHandleDots2Icon />
      <Cover data-no-cover={!shot.cover?.src}>
        {shot.cover?.src && <img src={shot.cover?.src} alt={shot.title || 'shot image'} />}
      </Cover>
      <ShotTitle data-no-title={!shot.title}>{shot.title || 'No title'}</ShotTitle>
    </ShotCard>
  );
};

const ShotCard = styled.div`
  padding: 0.8rem;
  background: var(--bg-elevation-1);
  border-radius: 0.4rem;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.4rem;

  &[data-selected='true'] {
    box-shadow: inset 0 0 0 1.5px var(--color-secondary);
  }

  &[data-dragging='true'] {
    opacity: 0;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    height: 3.2rem;
    width: 3.2rem;
    color: var(--color-texts-low-contrast);
  }
`;

const Cover = styled.div`
  height: 3.2rem;
  aspect-ratio: 16 / 9;
  border-radius: var(--border-radius-small);
  overflow: hidden;
  box-sizing: border-box;

  img {
    height: 100%;
    aspect-ratio: 16 / 9;
  }

  &[data-no-cover='true'] {
    border: 1px solid var(--color-grayscale-ghost);
  }
`;

const ShotTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  flex: 1;

  &[data-no-title='true'] {
    color: var(--color-texts-low-contrast);
  }
`;
