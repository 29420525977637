import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { formatDate } from '../../lib/utils/DateHelper';
import { dateShort } from '../../assets/contants/dates-fns';
import { getStageProgress } from '../../features/process/lib/helpers';
import { Plus } from '../icons';
import { StageHeader } from './stage-header';

import type { Stage } from '../../app/entities/stage';
import { Step } from '../../app/entities/step';
import { Dot } from '../../features/process/components/step-status-selector';

interface PhaseProps {
  stage: Stage;
  projectId: string;
  currentStepId: string;
}

export const Phase: React.FC<PhaseProps> = observer(({ stage, projectId, currentStepId }) => {
  const navigate = useNavigate();
  const { sum, done } = getStageProgress(stage.steps);

  const handleAction = () => {
    Step.createShootingDay({
      projectId,
    });
  };

  const goTo = (stepId: string) => navigate(`../steps/${stepId}`);

  const isActiveStage = useMemo(() => {
    return !!stage.steps.find((step) => step._id === currentStepId);
  }, [stage, currentStepId]);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <div tabIndex={0}>
          <StageHeader
            name={stage.name}
            index={stage.order}
            total={sum}
            completed={done}
            isActive={isActiveStage}
          />
        </div>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownContent sideOffset={8}>
          {stage.steps.map((step) => (
            <DropdownElement
              key={step._id}
              onSelect={() => goTo(step._id)}
              data-active={step._id === currentStepId}
            >
              <DropdownElementLeft>
                <StepStatusWrapper>
                  <Dot data-status={step.status!} />
                </StepStatusWrapper>
                <Name>{step.displayName}</Name>
              </DropdownElementLeft>
              &nbsp;
              {step.startDate && <DateLabel>{formatDate(step.startDate, dateShort)}</DateLabel>}
            </DropdownElement>
          ))}

          {stage.name.toLowerCase() === 'production' && (
            <DropdownElement onSelect={handleAction}>
              <DropdownElementLeft>
                <CheckItem>
                  <Plus />
                </CheckItem>

                <Name>Add shooting day</Name>
              </DropdownElementLeft>
            </DropdownElement>
          )}
        </DropdownContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
});

const DropdownContent = styled(DropdownMenu.Content)`
  background-color: #2d2f33;
  border-radius: var(--border-radius-large);
  width: 100%;
  max-width: 32rem;
  min-width: 24rem;
  padding: 0.4rem 0.4rem 0.4rem;
  z-index: 1000;
  min-height: 4.4rem;
  max-height: 50rem;
  overflow-y: auto;

  &[data-production='true'] {
    padding-bottom: 4.8rem;
  }

  @media screen and (min-width: 1784px) {
    min-width: 32rem;
  }
`;

const DropdownElement = styled(DropdownMenu.Item)`
  padding: 0.6rem;
  font-size: 1.4rem;
  color: var(--text-color-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius-medium);
  background-color: transparent;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }

  &[data-active='true'] {
    background-color: #28292d;
  }

  &[data-active='true'] {
    background-color: #28292d;
  }

  &[data-highlighted] {
    cursor: pointer;
    background-color: #28292d;
  }
`;

const DropdownElementLeft = styled.div`
  display: flex;
  align-items: center;
`;

const CheckItem = styled.div`
  width: 2.4rem;
  height: 1.8rem;
  margin-right: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: var(--step-title-color);
  }
`;

const Name = styled.div`
  font-size: 1.4rem;
  font-weight: 500;
  color: #ffffff99;
`;

const DateLabel = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: #8a8f98;
  white-space: nowrap;
`;

const StepStatusWrapper = styled.div`
  width: 2.4rem;
  height: 1.8rem;
  margin-right: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
