import React from 'react';
import styled from 'styled-components';

import { NotificationCount } from '../notification-count/NotificationCount';

interface TitleWithNotificationsProps {
  title: string | undefined;
  defaultValue?: boolean;
  onClick?: () => void;
  notificationCount?: number;
  className?: string;
}

export const TitleWithNotifications = React.forwardRef<HTMLDivElement, TitleWithNotificationsProps>(
  ({ title, notificationCount = 0, onClick, defaultValue = true, className }, ref) => (
    <FileItemTitleContainer className={className}>
      <FileItemTitle ref={ref} data-is-default={!defaultValue}>
        {title ? title : '-'}
      </FileItemTitle>

      <FileItemTitleNotification>
        {notificationCount > 0 && <NotificationCount value={notificationCount} onClick={onClick} />}
      </FileItemTitleNotification>
    </FileItemTitleContainer>
  ),
);

TitleWithNotifications.displayName = 'TitleWithNotifications';

export const FileItemTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FileItemTitle = styled.div`
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0;
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
  color: var(--text-color-white);

  &[data-is-default='true'] {
    color: var(--card-subtitle-color);
  }
`;

export const FileItemTitleNotification = styled.div`
  margin-left: 0.4rem;
`;
