import { api } from '../../api';
import { Storyboard } from '../../app/entities/storyboard';
import { entityPool } from '../../core/engine/engine';
import { Project } from '../../app/entities/project';
import { Shot } from '../../app/entities/shot';
import { Step } from '../../app/entities/step';

export const fetchStoryboards = async (projectId: string) => {
  const { data } = await api.get(`/projects/${projectId}/storyboards`);
  data.forEach((storyboardItem: Storyboard) => {
    const storyboard = Object.assign(new Storyboard(), storyboardItem);
    entityPool.insert(storyboard);
  });
  return data;
};

export const createStoryboard = async (project: Project) => {
  const { data } = await api.post(`/projects/${project._id}/storyboards`);
  const storyboard = Object.assign(new Storyboard(), data.storyboard);
  const step = Object.assign(new Step(), data.step);

  entityPool.insert(storyboard);
  entityPool.insert(step);

  Shot.createShot({
    projectId: project._id,
    position: 1,
  });

  return data;
};
