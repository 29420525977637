import React from 'react';
import { observer } from 'mobx-react-lite';

import { AssetFilesHeader } from '../components/asset-files-screen.header';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { StepDrawer } from '../../projects/components/step.drawer';

import type { Step } from '../../../app/entities/step';
import { ActionBar } from '../components/action-bar';
import { FolderView } from '../components/folder-view';

type AssetFilesScreenProps = {
  step: Step;
  spaceId: string;
};

export const AssetFilesScreen: React.FC<AssetFilesScreenProps> = observer(({ step, spaceId }) => {
  const fileIds = step.assets?.map((file: any) => file._id) || [];

  return (
    <>
      <AssetFilesHeader step={step} fileIds={fileIds} />

      <StepDrawer step={step} />

      <ScreenContainer>
        <FolderView stepId={step._id} folderId={step.folderAssetId} isRoot />
      </ScreenContainer>

      <ActionBar spaceId={spaceId} projectId={step.projectId} />
    </>
  );
});
