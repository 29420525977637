import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '../circular-progress/circular-progress';
import { calculatePercentage } from '../../lib/utils/math';

type StageHeaderProps = {
  index: number;
  name: string;
  completed: number;
  total: number;
  isActive?: boolean;
};

export const StageHeader: React.FC<StageHeaderProps> = ({
  index,
  name,
  completed,
  total,
  isActive,
}) => {
  const progress = calculatePercentage(completed, total);

  return (
    <Container data-active={isActive}>
      <Header>
        <Title isActive={isActive}>
          <StageNumber isActive={isActive}>{index.toString().padStart(2, '0')}</StageNumber>
          <h3>{name}</h3>
        </Title>

        <HeaderProgress>
          <CircularProgress progress={progress} />
          <ProgressIndicator>
            <span>{completed}</span>/{total}
          </ProgressIndicator>
        </HeaderProgress>
      </Header>
    </Container>
  );
};

type IsActiveProps = {
  isActive?: boolean;
};

const Container = styled.div`
  user-select: none;
  position: relative;
  display: flex;
  max-width: 32rem;
  min-width: 25.6rem;
  width: 100%;
  cursor: pointer;
  height: 3.2rem;
  align-items: center;
  z-index: 1;
  padding: 0.7rem 0.8rem;
  border-radius: var(--border-radius-medium);
  background: var(--bg-elevation-1);

  &[data-active='true'] {
    border: 1px #367bff solid;
  }
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StageNumber = styled.div<IsActiveProps>`
  font-size: 1.4rem;
  color: #1f2023;
  border-radius: 0.4rem;
  background-color: var(--white-default);
  margin-right: 0.8rem;
  padding: 0.1rem 0.4rem;
  font-weight: 600;
`;

const Title = styled.div<IsActiveProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  h3 {
    color: var(--text-color-white);
    font-weight: 400;
    font-size: 1.4rem;
  }
`;

const HeaderProgress = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
`;

const ProgressIndicator = styled.div`
  font-size: 1.4rem;
  color: #8a8f98;

  span {
    color: var(--text-color-white);
  }
`;
