import React from 'react';

import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { Location } from '../../../app/entities/location';
import { Asset } from '../../../app/entities/asset';
import { useCurrentStep } from 'hooks/use-current-step';

type LocationBreadcrumbType = {
  locationId: string;
  assetId?: string;
};

export const LocationBreadcrumb: React.FC<LocationBreadcrumbType> = ({ locationId, assetId }) => {
  const currentLocation = Location.getOne(locationId)!;
  const currentAsset = Asset.getOne(assetId!);
  const currentStep = useCurrentStep()!;

  const locationBreadcrumb = [
    {
      label: currentStep.name,
      href: '../..',
    },
    {
      label: currentLocation.name,
      href: `../`,
    },
    ...(currentLocation.assetsFolderId
      ? currentAsset?.getBreadCrumbs(currentLocation.assetsFolderId!) || []
      : []),
  ];

  return <Breadcrumb breadcrumbs={locationBreadcrumb} />;
};
