import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Share2Icon } from '@radix-ui/react-icons';

import { ScreenHeader } from '../../projects/components/screen-header';
import { CommonFileBreadcrumb } from '../components/common-file-breadcrumb';
import { AssetViewerScreen } from './asset-viewer.screen';
import { ElevatedButton } from '../../../components/buttons';
import { ShareModal } from '../../share/components/share-modal';
import { useResponsive } from 'hooks/useResponsive';
import { Asset } from '../../../app/entities/asset';

import type { Step } from '../../../app/entities/step';
import { FolderView } from '../components/folder-view';
import styled from 'styled-components';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { breakPoint } from 'app/theme';

type CommonFileScreenProps = {
  step: Step;
  spaceId: string;
};

export const CommonFileScreen: React.FC<CommonFileScreenProps> = observer(({ step, spaceId }) => {
  const { isDesktop } = useResponsive();

  const { assetId } = useParams();
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const asset = Asset.getOne(assetId!);

  if (!asset) {
    return <div />; // TODO: handle display file not found placeholder
  }

  const handleOpenShareModal = () => {
    setIsShareModalOpen(true);
  };

  return (
    <React.Fragment>
      <ScreenHeader
        breadcrumb={<CommonFileBreadcrumb step={step} assetId={assetId!} />}
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        actions={[
          <ElevatedButton
            key="share-button"
            onClick={handleOpenShareModal}
            text="Share"
            icon={<Share2Icon />}
          />,
        ]}
        canValidateStep={false}
      />

      <Content>
        {asset.type === 'folder' ? (
          <FolderView stepId={step._id} folderId={asset._id} />
        ) : (
          <AssetViewerScreen asset={asset} />
        )}
      </Content>

      {isShareModalOpen && (
        <ShareModal
          title={`Share ${asset.name}`}
          elementIds={[asset._id]}
          spaceId={spaceId}
          projectId={step.projectId}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
});

const Content = styled(ScreenContainer)`
  max-height: calc(100% - 4rem);
`;
