import { api } from '../../../api';
import { entityPool } from '../../../core/engine/engine';
import { Member } from '../../../app/entities/member';
import { Invitation } from '../../../app/entities/invitation';

/**
 * Create member thunk
 */

type CreateMemberInput = {
  spaceId: string;
  members: Array<{
    email: string;
    role: 'admin' | 'creator';
  }>;
};

export const createMember = async ({ spaceId, ...input }: CreateMemberInput) => {
  const { data }: { data: Member[] } = await api.post(`/spaces/${spaceId}/members`, input);
  return data.map((el) => {
    const member = Object.assign(new Invitation(), el);
    entityPool.insert(member);
    return member;
  });
};
