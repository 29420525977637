import React from 'react';

export const ShotsIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M54 12H18C16.8954 12 16 12.8954 16 14V42C16 43.1046 16.8954 44 18 44H54C55.1046 44 56 43.1046 56 42V14C56 12.8954 55.1046 12 54 12Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43 24C44.6569 24 46 22.6569 46 21C46 19.3431 44.6569 18 43 18C41.3431 18 40 19.3431 40 21C40 22.6569 41.3431 24 43 24Z"
      fill="currentColor"
    />
    <path
      d="M16 32.1726L25.585 22.5851C25.7707 22.3992 25.9913 22.2516 26.2341 22.151C26.4769 22.0503 26.7372 21.9985 27 21.9985C27.2628 21.9985 27.5231 22.0503 27.7659 22.151C28.0087 22.2516 28.2293 22.3992 28.415 22.5851L40.8275 35.0001L47.25 28.5851C47.625 28.2103 48.1335 27.9998 48.6637 27.9998C49.194 27.9998 49.7025 28.2103 50.0775 28.5851L56 34.5151"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48 44V50C48 50.5304 47.7893 51.0391 47.4142 51.4142C47.0391 51.7893 46.5304 52 46 52H10C9.46957 52 8.96086 51.7893 8.58579 51.4142C8.21071 51.0391 8 50.5304 8 50V22C8 21.4696 8.21071 20.9609 8.58579 20.5858C8.96086 20.2107 9.46957 20 10 20H16"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
