import React from 'react';

export const FolderPlus = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.6333 4.13589V3.16666H5.86466L6.83389 4.13589H2.6333ZM1.6333 4.63589V3.15896C1.6333 2.89579 1.73785 2.64339 1.92394 2.4573C2.11003 2.2712 2.36243 2.16666 2.62561 2.16666H5.86807H5.8683C6.13113 2.16678 6.38317 2.27116 6.56912 2.45691L6.56932 2.4571L8.2481 4.13589H13.4564C13.7196 4.13589 13.972 4.24043 14.158 4.42653C14.3441 4.61262 14.4487 4.86502 14.4487 5.12819V12.0753C14.4487 12.3239 14.3499 12.5624 14.1741 12.7382C13.9983 12.914 13.7598 13.0128 13.5111 13.0128H2.58745L2.58611 13.0128C2.33361 13.0121 2.09166 12.9115 1.91312 12.733C1.73459 12.5544 1.63398 12.3125 1.6333 12.06L1.6333 12.0587V4.63589ZM8.04099 5.13589H2.6333V12.0128H13.4487V5.13589H8.04099ZM6.06407 8.57435C6.06407 8.29821 6.28793 8.07435 6.56407 8.07435H7.54099V7.09743C7.54099 6.82128 7.76485 6.59743 8.04099 6.59743C8.31714 6.59743 8.54099 6.82128 8.54099 7.09743V8.07435H9.51792C9.79406 8.07435 10.0179 8.29821 10.0179 8.57435C10.0179 8.85049 9.79406 9.07435 9.51792 9.07435H8.54099V10.0513C8.54099 10.3274 8.31714 10.5513 8.04099 10.5513C7.76485 10.5513 7.54099 10.3274 7.54099 10.0513V9.07435H6.56407C6.28793 9.07435 6.06407 8.85049 6.06407 8.57435Z"
      fill="#FAFAFA"
    />
  </svg>
);
