import React from 'react';

import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { Asset } from '../../../app/entities/asset';

import type { Step } from '../../../app/entities/step';

type CommonFileBreadcrumbType = {
  step: Step;
  assetId: string;
};

export const CommonFileBreadcrumb: React.FC<CommonFileBreadcrumbType> = ({ step, assetId }) => {
  const currentAsset = Asset.getOne(assetId);
  const breadCrumbs = [
    { label: step.name, href: '../' },
    ...(currentAsset?.getBreadCrumbs(step.folderAssetId) || []),
  ];

  return <Breadcrumb breadcrumbs={breadCrumbs} />;
};
