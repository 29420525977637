import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Stage as StageItem } from '../../../features/process/components/stage';
import { Stage } from '../../../app/entities/stage';
import { Project } from '../../../app/entities/project';
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { createPortal } from 'react-dom';
import { StepItem } from '../../../components/task/StepItem';
import { Step } from '../../../app/entities/step';

interface OverViewTabProps {
  project: Project;
}

export const OverviewTab: React.FC<OverViewTabProps> = observer(({ project }) => {
  const [activeStep, setActiveStep] = useState<string | null>(null);
  const [startingStage, setStartingStage] = useState<string | null>(null);
  const [startingPosition, setStartingPosition] = useState<number | null>(null);

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const stagesIds = useMemo(() => project.stages.map((stage) => stage._id), [project.stages]);

  const onDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === 'step') {
      setActiveStep(event.active.id.toString());
      const activeStep = Step.getOne(event.active.id.toString());
      if (!activeStep) return;
      setStartingStage(activeStep.stageId);
      setStartingPosition(activeStep.order);
    }
  };

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id.toString();
    const overId = over.id.toString();

    setActiveStep(null);

    const isActiveStep = active.data.current?.type === 'step';
    const isOverStep = over.data.current?.type === 'step';

    if (!isActiveStep) {
      setStartingStage(null);
      setStartingPosition(null);
      return;
    }

    const activeStep = Step.getOne(activeId);
    if (!activeStep) {
      setStartingStage(null);
      setStartingPosition(null);
      return;
    }

    const overStep = isOverStep ? Step.getOne(overId) : null;
    const overStage = isOverStep
      ? overStep && Stage.getOne(overStep.stageId)
      : Stage.getOne(overId);

    let order = overStep ? overStep.order : (overStage?.steps.length || 0) + 1;

    if (startingStage === activeStep.stageId && startingPosition === order) {
      setStartingStage(null);
      setStartingPosition(null);
      return;
    }

    // clear them after we've used them
    setStartingStage(null);
    setStartingPosition(null);

    if (!overStage || !activeStep) return;

    const activeStepIndex = overStage.steps.findIndex((step) => step._id === activeStep._id);

    let newSteps = overStage.steps.toSpliced(activeStepIndex, 1);
    const overStepIndex = overStage.steps.findIndex((step) => step._id === overStep?._id);
    newSteps.splice(overStepIndex, 0, activeStep);
    newSteps = newSteps.map((step, index) => {
      step.order = index + 1;
      return step;
    });
    overStage.steps = newSteps;

    activeStep.update({
      stageId: activeStep.stageId,
      order: order,
    });
  };

  const onDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id.toString();
    const overId = over.id.toString();

    if (activeId === overId) return;

    const isActiveStep = active.data.current?.type === 'step';

    const isOverStep = over.data.current?.type === 'step';
    const isOverStage = over.data.current?.type === 'stage';

    if (!isActiveStep) return;

    const activeStep = Step.getOne(activeId);
    if (!activeStep) return;

    const activeStage = Stage.getOne(activeStep.stageId);
    if (!activeStage) return;

    if (isOverStep) {
      const overStep = Step.getOne(overId);
      if (!overStep) return;

      if (overStep.stageId !== activeStep.stageId) {
        const overStage = Stage.getOne(overStep.stageId);
        if (!overStage) return;

        activeStep.stageId = overStep.stageId;

        activeStage.steps = activeStage?.steps.filter((step) => step._id !== activeStep._id);

        overStage.steps = [...overStage.steps, activeStep];
      }
    } else if (isOverStage) {
      const overStage = Stage.getOne(overId);
      if (!overStage) return;

      activeStage.steps = activeStage?.steps.filter((step) => step._id !== activeStep._id);
      overStage.steps = [...overStage.steps, activeStep];
    }
  };

  return (
    <Container>
      <DndContext
        sensors={sensors}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        collisionDetection={pointerWithin}
      >
        <SortableContext items={stagesIds}>
          {project?.stages?.map((stage: Stage) => (
            <StageItem key={stage._id} stage={stage} />
          ))}
        </SortableContext>

        {createPortal(
          <DragOverlay>{activeStep && <StepItem step={Step.getOne(activeStep)!} />}</DragOverlay>,
          document.body,
        )}
      </DndContext>
    </Container>
  );
});

const Container = styled.div`
  margin-top: 1.6rem;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  height: 100%;
  padding-bottom: 2.4rem;
  gap: 1.6rem;
`;
