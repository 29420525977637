import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Menu from '@radix-ui/react-dropdown-menu';
import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Plus } from '../../components/icons';
import { Space } from '../entities/space';
import { api } from '../../api';
import { getNameInitials } from '../../lib/utils/get-name-initials';
import { breakPoint } from '../theme';

type SpaceSelectorProps = {
  /**
   * The current space
   */
  space: Space;
  /**
   * To sync Radix dropdown with original drawer
   */
  isOpen: boolean;
  /**
   * Close the drawer
   */
  onClose: () => void;
};

export const SpaceSelector: React.FC<SpaceSelectorProps> = observer(
  ({ space, isOpen, onClose }) => {
    const navigate = useNavigate();
    const spaces = Space.getAll();

    const onCreateSpace = () => {
      onClose();
      const href = '/onboarding/create-workspace';
      navigate(href, { state: { spaceId: space._id } });
    };

    const onSelect = (id: string) => {
      onClose();
      api.updateSpaceInHeaders(id);
      const href = `/space/${id}`;
      navigate(href);
    };

    const [open, setIsOpen] = useState(false);

    useEffect(() => {
      if (!isOpen) {
        setIsOpen(isOpen);
      }
    }, [isOpen]);

    return (
      <Container>
        <Menu.Root onOpenChange={(open) => setIsOpen(open)} open={open}>
          <Trigger asChild>
            <SelectedSpaceWrapper>
              <SelectedSpace>
                <Avatar
                  size="medium"
                  src={space.avatar}
                  initials={getNameInitials(space.name)}
                  theme="dark"
                />
                <SpaceName>{space.name}</SpaceName>
              </SelectedSpace>

              <ChevronDownIcon color="var(--color-grayscale-shuttle, #626878)" />
            </SelectedSpaceWrapper>
          </Trigger>

          <MenuDialog align={'start'} sideOffset={8}>
            <MenuItem onSelect={onCreateSpace}>
              <IconContainer>
                <Icon>
                  <Plus />
                </Icon>
              </IconContainer>

              <Name>Create new workspace</Name>
            </MenuItem>

            <Separator />

            {spaces.map((el) => {
              const isSelected = el._id === space._id;

              return (
                <MenuItem key={el._id} data-active={isSelected} onClick={() => onSelect(el._id)}>
                  <IconContainer data-active={isSelected}>
                    <Avatar
                      size="small"
                      src={el.avatar}
                      theme="dark"
                      initials={getNameInitials(el.name)}
                    />
                  </IconContainer>

                  <Name data-active={isSelected}>{el.name}</Name>
                </MenuItem>
              );
            })}
          </MenuDialog>
        </Menu.Root>
      </Container>
    );
  },
);

const Container = styled.div`
  order: 0;
  margin: 0 1.6rem 1.6rem;

  @media (min-width: ${breakPoint.medium}px) {
    order: 3;
  }
`;

const Trigger = styled(Menu.Trigger)`
  cursor: pointer;
`;

const MenuDialog = styled(Menu.Content)`
  background-color: #181a1e;
  z-index: 9999;
  width: 23.2rem;
  border-radius: var(--border-radius-medium);
  padding: 0.8rem 0;
  max-height: 56rem;
  overflow: auto;
`;

const Separator = styled(Menu.Separator)`
  background-color: #494e5a;
  height: 1px;
  width: 100%;
  margin-bottom: 0.8rem;
`;

const IconContainer = styled.div`
  color: var(--text-color-white);
  font-weight: 500;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  border-radius: 0.8rem;
  height: 3.2rem;
  width: 3.2rem;
  transition: all 150ms linear;
  border: 0.2rem transparent solid;

  &[data-active='true'] {
    border: 0.2rem #494e5a solid;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: var(--text-color-white);
  }

  &:hover {
    cursor: pointer;
    border: 0.2rem #929292 solid;

    &[data-active='true'] {
      border: 0.2rem white solid;
    }
  }
`;

const Name = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  margin-left: 1.2rem;
  color: #7a8296;
  word-break: break-all;

  &[data-active='true'] {
    color: white;
  }
`;

const MenuItem = styled(Menu.Item)`
  width: 100%;
  padding: 0.6rem 0.8rem;
  display: flex;
  align-items: center;
  transition: all 100ms ease-in;
  overflow: hidden;
  cursor: pointer;

  &[data-active='true'] {
    background-color: #25272d;
  }

  &:not(:last-child) {
    margin-bottom: 0.4rem;
  }

  &[data-highlighted] {
    background-color: #25272d;

    & > ${IconContainer} {
      cursor: pointer;
      border: 0.2rem #494e5a solid;

      &[data-active='true'] {
        border: 0.2rem #494e5a solid;
      }
    }

    & > ${Name} {
      color: white;
    }
  }
`;

const Icon = styled.div`
  font-size: 1.6rem;
  color: #7a8296;
  cursor: pointer;
  display: flex;
  padding: 0.8rem;
  height: 2.4rem;
  width: 2.4rem;
  align-items: center;
  justify-content: center;

  svg {
    fill: #7a8296;
  }
`;

const SelectedSpaceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;

  & > svg {
    min-width: 1.6rem;
  }
`;

const SelectedSpace = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;

const SpaceName = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-grayscale-white, #fafafa);
  text-transform: capitalize;
  word-break: break-all;
`;
