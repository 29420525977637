import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { AssetFilesScreen } from '../../../features/assets/screens/asset-files.screen';
import { CommonFileScreen } from '../../../features/assets/screens/common-file.screen';

import type { Project } from '../../../app/entities/project';
import type { Step } from '../../../app/entities/step';

export type CommonFilePageProps = {
  project: Project;
  step: Step;
};

export const CommonFilePage: React.FC<CommonFilePageProps> = ({ step, project }) => {
  return (
    <Routes>
      <Route
        path={'/:assetId'}
        element={<CommonFileScreen step={step} spaceId={project.spaceId} />}
      />

      <Route path={'/'} element={<AssetFilesScreen step={step} spaceId={project.spaceId} />} />
    </Routes>
  );
};
