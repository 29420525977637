import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { AssetGrid } from '../../../components/grid';
import { LocationCard } from './location-card';

import type { Location } from '../../../app/entities/location';
import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';

type LocationListProps = {
  locations: Location[];
  onOpenModal: () => void;
};

export const LocationList: React.FC<LocationListProps> = observer(({ locations, onOpenModal }) => {
  if (locations.length === 0) {
    return (
      <PagePlaceholder
        description='Locations will be displayed here, you can add them by clicking on the "Create
          Location" button'
        actionButton={{ text: 'Create Location', onClick: onOpenModal }}
      />
    );
  }

  return (
    <Container>
      {locations.map((location) => (
        <LocationCard key={location._id} location={location} />
      ))}
    </Container>
  );
});

const Container = styled(AssetGrid)`
  padding: 1.6rem;
`;
