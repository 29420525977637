import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { StorageProgress } from '../../features/settings/components/storage-progress';
import { DescriptionHeader } from '../../features/user/components/description-header';
import { uiStore } from '../../core/stores/ui-store';
import { useCurrentSpace } from '../../hooks/use-current-space';

export const StorageSettingsRoute: React.FC = observer(() => {
  const space = useCurrentSpace();

  useEffect(() => {
    uiStore.showBack = true;

    uiStore.currentLocation = 'space.settings';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <Container>
      <DescriptionHeader title="Storage" />

      <StorageProgress space={space} />

      {!space.isSelfServing && (
        <DescriptionContainer>
          <Description>
            To request additional storage, please contact{' '}
            <a href="mailto:sales@the-producer.io">sales@the-producer.io</a>
          </Description>
        </DescriptionContainer>
      )}
    </Container>
  );
});

const Container = styled.div`
  width: 100%;
  max-width: 92.8rem;
  margin: 0 auto;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #e6edf3;
  max-width: 64rem;
  overflow-wrap: break-word;

  a {
    color: white;
    text-decoration: underline;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;
  margin-top: 2rem;
`;
