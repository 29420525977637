import { globalConfig } from './configuration/config';
import { keycloak } from './keycloak';
import { io, Socket } from 'socket.io-client';
import { entityPool } from './core/engine/engine';
import { Project } from './app/entities/project';

export class AppSocket {
  private socket: Socket;

  init() {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.socket = io(globalConfig.config.webSocketHost, {
      auth: {
        token: keycloak.token,
      },
    });

    this.setupListeners();

    this.socket.on('connect_error', (error) => {
      console.log('connect_error', error);
    });
  }

  private setupListeners() {
    this.socket.on('project-created', (project: Project) => {
      const newProject = Object.assign(new Project(), project);
      entityPool.insert(newProject);
    });

    this.socket.on(
      'project-updated',
      ({ avatar, ...update }: Partial<Project> & { _id: string }) => {
        if (avatar) {
          // updated the avatar, so that, after receiving the socket response, it gets rerendered in the CoverImage component
          (update as any).avatar = `${avatar}&key=${Date.now()}`;
        }

        entityPool.updateEntity(update._id, update);
      },
    );

    this.socket.on('project-deleted', ({ projectId }: { projectId: string }) => {
      const project = entityPool.getEntity<Project>(projectId);
      if (!project) return;
      entityPool.delete(project);
    });
  }

  public projectAvatarUpdated(project: Project) {
    this.socket.emit('project-avatar-uploaded', project);
  }

  public addListener(event: string, listener: (...args: any[]) => void) {
    this.socket.on(event, listener);
  }
}

const appSocket = new AppSocket();

export { appSocket };
