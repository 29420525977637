import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

export const ListView: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <Table>{children}</Table>;
};

export const ListViewHeader: React.FC<React.PropsWithChildren> = ({ children }) => (
  <TableHead>
    <tr>{children}</tr>
  </TableHead>
);

export const ListViewItem = React.forwardRef<
  HTMLTableRowElement,
  PropsWithChildren<React.HTMLAttributes<HTMLTableRowElement>>
>(({ children, ...props }, ref) => {
  return (
    <Row ref={ref} {...props}>
      {children}
    </Row>
  );
});

ListViewItem.displayName = 'ListViewItem';

const Table = styled.table`
  width: 100%;
  height: max-content;
  border-spacing: 0;

  tbody {
    width: 100%;
  }
`;

const TableHead = styled.thead`
  height: 4rem;
  min-height: 4rem;

  th {
    border-bottom: 1px solid var(--color-grayscale-tuna);
    color: var(--color-grayscale-shuttle);
    font-size: 1.4rem;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;

    &:first-child {
      padding-left: 1.6rem;
    }

    &:last-child {
      padding-right: 1.6rem;
    }

    &:not(:last-child) {
      padding-right: 3.2rem;
    }
  }
`;

const Row = styled.tr`
  border: 1px solid transparent;
  cursor: pointer;

  &:not([data-selected='true']) {
    &:hover {
      background-color: var(--color-grayscale-tuna);
      transition: background-color 0.2s ease-in-out;
    }
  }

  & td {
    height: 4.8rem;
    color: var(--white-default);
    font-size: 1.4rem;
    text-align: left;
    white-space: nowrap;
    border-top: 1px solid transparent;
    border-bottom: 1px solid var(--color-grayscale-tuna);
    padding: 0.8rem 0.8rem 0.8rem 0.1rem;

    &:first-child {
      border-left: 1px solid transparent;
      padding-left: 1.6rem;
    }

    &:last-child {
      border-right: 1px solid transparent;
      padding-right: 1.6rem;
    }

    &:not(:last-child) {
      padding-right: 3.2rem;
    }
  }
`;
