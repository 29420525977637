import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import styled from 'styled-components';

import { SortableStrip } from './sortable-strip';
import { useSelection } from '../../../core/contexts/selection.context';
import { CountDragPreview } from './count-drag-preview';
import { Stripboard } from '../../../app/entities/stripboard';
import { Cell } from './styled-strips';
import { Strip } from '../models/types';
import { Shot } from '../../../app/entities/shot';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { useCurrentMember } from '../../../hooks/use-current-member';

type ScheduleProps = {
  strips: Strip[];
  stripboard: Stripboard;
  placeholder?: React.ReactNode;
};

export const SortableStripList: React.FC<ScheduleProps> = observer(
  ({ stripboard, strips, placeholder }) => {
    const { t } = useTranslation();
    const { elements, select, clear } = useSelection();
    const currentMember = useCurrentMember();

    const [copiedElements, setCopiedElements] = useState<string[]>([]);
    const [showDeleteShotsDialog, setShowDeleteShotsDialog] = useState(false);

    const [activeId, setActiveId] = useState<string | null>(null);
    const stripIds = strips?.map((strip: any) => strip._id);

    const stripboardStripsIds: string[] = stripboard.strips.map((strip: any) => strip._id);
    const activeIndex = activeId ? stripboardStripsIds?.indexOf(activeId) : -1;

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 6 } }));

    const selectedShots = useMemo(
      () =>
        stripboard.strips.filter((strip) => strip.type === 'shot' && elements.includes(strip._id)),
      [stripboard.strips, elements],
    );

    const handleDragStart = ({ active }: DragStartEvent) => {
      select(active.id.toString());
      setActiveId(active.id.toString());
    };

    const handleDragCancel = () => {
      setActiveId(null);
    };

    const handleDragEnd = (event: DragEndEvent) => {
      const { over } = event;

      if (over) {
        const overIndex = stripboardStripsIds.indexOf(`${over.id}`);

        if (activeIndex !== overIndex) {
          const destination = stripboard.strips.find((strip) => strip._id === over.id);

          if (!destination) {
            return;
          }

          stripboard.edit(elements, destination.position);
        }
      }

      clear();
      setActiveId(null);
    };

    useEffect(() => {
      const handleKeyDown = async (e: KeyboardEvent) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
          if (elements.length) {
            e.preventDefault();
            const strips = stripboard.strips.filter(
              (strip) => strip.type === 'shot' && elements.includes(strip._id),
            );
            const shotIds = strips.map((strip) => strip.data.shotId);
            setCopiedElements(shotIds);
          }
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
          if (copiedElements.length) {
            e.preventDefault();
            await Shot.duplicateShots(copiedElements);
            setCopiedElements([]);
          }
        }

        if (e.key === 'Delete') {
          if (selectedShots.length) {
            e.preventDefault();
            setShowDeleteShotsDialog(true);
          }
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elements, copiedElements]);

    const handleDeleteShots = async () => {
      const shotIds = selectedShots.map((strip) => strip.data.shotId);
      await Shot.deleteShots(shotIds);
      setShowDeleteShotsDialog(false);
    };

    return (
      <>
        {showDeleteShotsDialog && (
          <DeleteDialog
            onCancel={() => {
              setShowDeleteShotsDialog(false);
            }}
            onSubmit={() => {
              handleDeleteShots();
            }}
            text={`Are you sure you want to delete ${selectedShots.length} shots`}
            title={'Delete multiple shots'}
          />
        )}

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext items={stripIds} strategy={verticalListSortingStrategy}>
            {placeholder && <Cell colSpan={100}>{placeholder}</Cell>}

            {strips?.map((strip) => (
              <SortableStrip
                key={strip._id}
                stripboard={stripboard}
                strip={strip}
                isSelected={elements.includes(strip._id)}
                isActive={!!activeId}
                readOnly={!currentMember || currentMember.role === 'guest'}
              />
            ))}
          </SortableContext>

          <DragOverlay
            dropAnimation={null}
            style={{ cursor: 'grabbing' }}
            modifiers={[snapCenterToCursor]}
          >
            <OverlayWrapper>
              <CountDragPreview
                count={elements?.length}
                text={t('common:strip', { count: elements?.length })}
              />
            </OverlayWrapper>
          </DragOverlay>
        </DndContext>
      </>
    );
  },
);

const OverlayWrapper = styled.div`
  position: absolute;
`;
