import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Member } from '../app/entities/member';
import { authStore } from '../core/stores/auth-store';

export const useCurrentMember = () => {
  const { spaceId } = useParams() as { spaceId: string };
  const currentUser = authStore.currentUser;

  const [currentMember, setCurrentMember] = useState<Member | undefined>();

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    setCurrentMember(currentUser.members.find((member) => member.spaceId === spaceId));
  }, [currentUser, spaceId, currentUser?.members]);

  return currentMember;
};
