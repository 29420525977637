import React from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { CrossCircledFilledIcon } from '../../../components/icons/cross-circled-filled.icon';

import { ElevatedButton } from '../../../components/buttons';
import { useCurrentMember } from '../../../hooks/use-current-member';

export const SpaceLockedPlaceholder = () => {
  const { spaceId } = useParams() as { spaceId: string };
  const navigate = useNavigate();

  const member = useCurrentMember();
  const isAdmin = member?.role === 'admin' || member?.role === 'owner';

  const handleClick = () => {
    navigate(`/space/${spaceId}/settings/billings`);
  };

  return (
    <Container>
      <Icon>
        <CrossCircledFilledIcon />
      </Icon>

      <h1>Your creative space is locked, but your data is safe</h1>

      {isAdmin ? (
        <>
          <p>
            Your creative space has been temporarily locked because your subscription is still
            overdue. Don’t worry—none of your data has been deleted. You can unlock your creative
            space and regain full access by renewing your subscription at any time.
          </p>

          <p>If you need assistance, we’re here to help!</p>
        </>
      ) : (
        <p>
          Your creative space has been temporarily locked because your subscription is overdue.
          Don’t worry—none of your data has been deleted. To regain access, please contact your
          creative space admin to update the subscription and unlock your account.
        </p>
      )}

      {isAdmin && (
        <Actions>
          <ElevatedButton text="Unlock now" onClick={handleClick} />
          <Link href={'mailto:support@the-producer.io'}>Contact support</Link>
        </Actions>
      )}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4.8rem 3.2rem 2.4rem;
  max-width: 47.6rem;
  text-align: center;
  color: white;

  h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    margin-bottom: 1.2rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 300;
    margin-bottom: 1.6rem;
  }
`;

const Icon = styled.div`
  position: relative;
  height: 9.6rem;
  width: 9.6rem;
  border: 0.2rem solid var(--color-error);
  background: var(--color-error-20);
  border-radius: 50%;
  margin-bottom: 3.2rem;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-error);
    height: 3.6rem;
    width: 3.6rem;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.6rem;
  gap: 1.6rem;
`;

const Link = styled.a`
  font-size: 1.4rem;
  color: var(--color-grayscale-light-slate);
`;
