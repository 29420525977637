import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { breakPoint } from '../theme';

type HeaderProps = React.PropsWithChildren<{
  title?: string;
}>;

export const Header: React.FC<PropsWithChildren<HeaderProps>> = ({ title, children }) => {
  return (
    <HeaderItem>
      <Title>{title}</Title>

      <HeaderItems>{children}</HeaderItems>
    </HeaderItem>
  );
};

const HeaderItem = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 0 1.6rem;
  border-bottom: 1px var(--content-border-color) solid;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
  }
`;

const Title = styled.h1`
  font-family: Inter, sans-serif;
  color: #e3e4e7;
  font-size: 1.3rem;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 4.2rem;
`;

const HeaderItems = styled.div`
  display: flex;
  gap: 1.6rem;
`;
