import { computed, makeObservable, observable } from 'mobx';
import merge from 'lodash.merge';
import axios from 'axios';

import { Model } from '../../core/engine/model';
import { Space } from './space';
import { ManyToOne, OneToMany } from '../../core/engine/decorators';
import { Shot } from './shot';
import { Collaborator } from './collaborator';
import { Address } from '../../dto/common/address.dto';
import { Character } from './character';
import { Location } from './location';
import { Stage } from './stage';
import { Asset } from './asset';
import { Storyboard } from './storyboard';
import { TeamContact } from '../../features/teams/models/team';
import { Team } from './team';
import { Stripboard } from './stripboard';
import { api } from '../../api';
import { Contact } from './contact';
import { upload } from '../../core/services/file.service';
import { Frame } from '../../lib/utils/transform-image';
import { ShareLink } from './share-link';
import { Step } from './step';

export enum ProjectPriority {
  NO_PRIORITY = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  URGENT = 4,
}

export class Project extends Model {
  /** The project's name **/
  name: string = '';

  /** The project' cover image **/
  avatar: string;

  spaceId: string = '';

  contacts: TeamContact[] = [];

  dueDate: Date;

  startDate: Date;

  /** The project's collaborator **/
  collaborators: Collaborator[] = [];

  /** The project's collaborator **/
  @OneToMany('project')
  characters: Character[] = [];

  /** The project's shots **/
  @OneToMany('project')
  shots: Shot[] = [];

  /** The project's locations **/
  @OneToMany('project')
  locations: Location[] = [];

  /** The project's stages **/
  @OneToMany('project')
  stages: Stage[] = [];

  /** The project's steps **/
  @OneToMany('project')
  steps: Step[] = [];

  /** The project's assets **/
  @OneToMany('project')
  assets: Asset[] = [];

  /** The project's teams **/
  @OneToMany('project')
  teams: Team[] = [];

  /** The project's share links **/
  @OneToMany('project')
  shareLinks: ShareLink[] = [];

  stripboard: Stripboard;

  /** The project's space **/
  @ManyToOne('projects')
  space: Space | null = null;

  client?: {
    name?: string;

    logo?: {
      src?: string;
    };

    address?: Address;
  };

  agency?: {
    name?: string;

    logo?: {
      src?: string;
    };

    address?: Address;
  };

  production?: {
    name?: string;

    phone?: string;

    logo?: {
      src?: string;
    };

    address?: Address;
  };

  aspectRatio?: string;
  removeTimeStamp?: string;
  currentStepId: string;
  src: string;
  directors: string[];
  producers: string[];
  lineProducers: string[];
  currentTask: string;
  lastShotIndex: number;
  status?: string;
  lastUpdatedAt?: string;
  usedStorage?: number;

  priority?: ProjectPriority;

  rootAssetId: string;
  documentsFolderId: string;

  private _fps?: number;

  set fps(fps: number) {
    this._fps = fps;
  }

  get fps(): number {
    return this._fps ? this._fps : this.space!.fps;
  }

  private _progress: number = 0;

  set progress(progress: number) {
    this._progress = Math.round(progress);
  }

  get progress(): number {
    return Math.round(this._progress);
  }

  @OneToMany('project')
  storyboards: Storyboard[] = [];

  /*******************
   * Computed values *
   *******************/
  get shootingDays(): Step[] {
    return this.steps.filter((step) => step.type === 'shootingDay');
  }

  set shootingDays(_: string) {}

  get storyboard(): Storyboard | null {
    const storyboard = this.storyboards[0];

    if (!storyboard) {
      return null;
    }

    return storyboard;
  }

  constructor() {
    super('projects');
    this.shots = [];
    this.storyboards = [];
    this.contacts = [];
    this.collaborators = [];
    this.characters = [];
    this.shots = [];
    this.locations = [];
    this.stages = [];
    this.steps = [];
    this.assets = [];
    this.teams = [];
    this.shareLinks = [];
    this.usedStorage = 0;
    this.priority = ProjectPriority.NO_PRIORITY;

    makeObservable(this, {
      collaborators: observable,
      characters: observable,
      locations: observable,
      shots: observable,
      stages: observable,
      steps: observable,
      contacts: observable,
      name: observable,
      avatar: observable,
      spaceId: observable,
      dueDate: observable,
      startDate: observable,
      assets: observable,
      teams: observable,
      shareLinks: observable,
      space: observable,
      client: observable,
      agency: observable,
      production: observable,
      aspectRatio: observable,
      removeTimeStamp: observable,
      currentStepId: observable,
      src: observable,
      directors: observable,
      producers: observable,
      lineProducers: observable,
      currentTask: observable,
      lastShotIndex: observable,
      status: observable,
      lastUpdatedAt: observable,
      usedStorage: observable,
      priority: observable,

      storyboards: observable,
      storyboard: computed,
      shootingDays: computed,
      fps: computed,
      progress: computed,
    });
  }

  async addKeyContact(contact: Contact, role: string) {
    await api.post(`/projects/${this._id}/contacts`, {
      contactId: contact._id,
      role,
    });

    this.contacts.push({
      ...contact,
      phone: contact.phone || '',
      _id: contact._id,
      role,
    });
  }

  async removeKeyContact(contactId: string) {
    await api.delete(`/projects/${this._id}/contacts/${contactId}`);
    this.contacts = this.contacts.filter((item) => item._id !== contactId);
  }

  async update(values: object): Promise<void> {
    const files: any = {};

    const filterFiles = (values: any) => {
      const filters = ['production', 'client', 'agency'];

      filters.forEach((filter) => {
        if (values[filter] && values[filter].logo && values[filter].logo.file) {
          files[filter] = values[filter].logo.file;
          delete values[filter].logo.file;
        }
      });

      return values;
    };

    const { data } = await api.patch(`/projects/${this._id}`, filterFiles(values));

    if (data.links.uploads) {
      await Promise.all(
        Object.keys(data.links.uploads).map((key) => {
          // @ts-ignore
          const company = values[key];
          const url = data.links.uploads[key];

          if (!url || !company || !company.logo) {
            return;
          }

          const file = files[key];
          data[key].logo.src = `${data[key].logo.src}?dt=${Date.now()}`;

          return axios.put(url, file);
        }),
      );
    }

    merge(this, data);
  }

  async updateCover(file: Frame) {
    const { data } = await api.put(`/projects/${this._id}/cover`, {
      fileSize: file.size,
      fileType: file.type,
    });

    // @ts-ignore
    await upload(data.links.upload, file.data ? file.data : file);

    merge(this, data);
  }

  static getOne(id: string): Project | undefined {
    return Model.getOne(id) as Project;
  }

  canAddShot() {
    return this.space?.hasSubscription() || this.shots.length < 10;
  }

  toPOJO(): Record<string, any> {
    const pojo = {
      _id: this._id,
      name: this.name,
      avatar: this.avatar,
      progress: this.progress,
      spaceId: this.spaceId,
      dueDate: this.dueDate,
      startDate: this.startDate,
      contacts: this.contacts,
      collaborators: this.collaborators,
      characters: this.characters,
      locations: this.locations,
      shots: this.shots,
      stages: this.stages,
      steps: this.steps,
      assets: this.assets,
      space: this.space,
      client: this.client,
      agency: this.agency,
      production: this.production,
      aspectRatio: this.aspectRatio,
      removeTimeStamp: this.removeTimeStamp,
      currentStepId: this.currentStepId,
      src: this.src,
      directors: this.directors,
      producers: this.producers,
      lineProducers: this.lineProducers,
      currentTask: this.currentTask,
      lastShotIndex: this.lastShotIndex,
      status: this.status,
      fps: this.fps,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };

    // if a value is undefined, we remove it from the POJO
    // @ts-ignore
    Object.keys(pojo).forEach((key) => pojo[key] === undefined && delete pojo[key]);
    return pojo;
  }

  static PriorityMapper: Record<ProjectPriority, { title: string }> = {
    [ProjectPriority.NO_PRIORITY]: {
      title: 'No Priority',
    },
    [ProjectPriority.LOW]: {
      title: 'Low',
    },
    [ProjectPriority.MEDIUM]: {
      title: 'Medium',
    },
    [ProjectPriority.HIGH]: {
      title: 'High',
    },
    [ProjectPriority.URGENT]: {
      title: 'Urgent',
    },
  };

  static PriorityArray = Object.entries(Project.PriorityMapper)
    .slice()
    .sort((a, b) => Number(b[0]) - Number(a[0]));
}
