import React, { PropsWithChildren, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { IconButton } from '../../../components/buttons';
import { Delete as DeleteIcon } from '../../../components/icons';
import { getNameInitials } from '../../../lib/utils/get-name-initials';
import { getFullName } from '../../../lib/utils/get-full-name';

import type { Project } from '../../../app/entities/project';
import type { TeamContact } from '../../teams/models/team';

type CollaboratorCardProps = {
  project: Project;
  contact: TeamContact;
};

export const CollaboratorCard: React.FC<CollaboratorCardProps> = observer(
  ({ project, contact }) => {
    const handleDelete = () => {
      project.removeKeyContact(contact._id);
    };

    const contactName = getFullName(contact);

    return (
      <Card>
        <Content>
          <Delete>
            <IconButton variant="transparent" icon={<DeleteIcon />} onClick={handleDelete} />
          </Delete>

          <Avatar alt={contactName} src={contact.avatar || ''} />

          <Name>{contactName}</Name>
          <Role>{contact.role}</Role>
        </Content>
      </Card>
    );
  },
);

type AvatarProps = {
  alt?: string;
  src?: string;
};

/**
 * If a src is provided, it will always show the picture
 * if the image loading ends up an error, it will show:
 *  - the children if provided
 *  - the first letter of the alt text if provided
 *  - a default user icon
 * If no src is provided, it will show:
 *  - the children if provided
 *  - a default user icon
 *
 * @constructor
 */
const Avatar: React.FC<PropsWithChildren<AvatarProps>> = ({ alt, src, children }) => {
  const [hasError, setHasError] = useState(false);

  const handleImageError = () => {
    setHasError(true);
  };

  return (
    <AvatarContainer>
      {hasError ? (
        <div>{getNameInitials(alt)}</div>
      ) : src ? (
        <Image src={src} onError={handleImageError} />
      ) : children ? (
        children
      ) : (
        getNameInitials(alt)
      )}
    </AvatarContainer>
  );
};

const AvatarContainer = styled.div`
  height: 12rem;
  width: 12rem;
  border-radius: var(--border-radius-medium);
  background-color: #ffd1de;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3.2rem;
`;

const Image = styled.img`
  height: 12rem;
  width: 12rem;
  border-radius: var(--border-radius-medium);
  object-fit: cover;
`;

const Delete = styled.div`
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  opacity: 0;
  transform: translateX(1rem) scale(0.95);
  transition: all 300ms cubic-bezier(0.25, 0.1, 0.25, 1) 0s;

  svg {
    fill: var(--color-grey-5);
  }
`;

const Card = styled.article`
  border-radius: var(--border-radius-medium);
  background-color: var(--card-bg-color);
  width: 100%;
  padding-top: 100%;
  position: relative;

  &:hover {
    ${Delete} {
      opacity: 1;
      transform: translateX(0) scale(1);
    }
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
`;

const Name = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 1.6rem;
  color: var(--color-grayscale-white);
  text-align: center;
`;

const Role = styled.p`
  font-size: 1.4rem;
  color: #8a8f98;
  margin-top: 0.4rem;
  text-align: center;
`;
