import styled from 'styled-components';

import { StyledLine } from '../dropdowns/StyledDropdown';
import { breakPoint } from '../../app/theme';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.8rem 0 1.6rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 1.2rem 2.4rem;
  }
`;

export const TableContainer = styled.div`
  padding: 1.6rem;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const TableHeader = styled.thead`
  color: var(--color-grayscale-shuttle);
  text-align: left;
  border-bottom: 0.1rem solid var(--color-grayscale-tuna);
`;

export const THead = styled.th`
  height: 3.6rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-grayscale-shuttle);
  cursor: default;
`;

export const Cell = styled.td`
  font-weight: 400;
  font-size: 1.4rem;
  padding: 0.4rem 1.6rem 0.4rem 0;
  color: var(--color-grayscale-white);
  cursor: default;

  &:first-child {
    padding-inline-start: 1.6rem;
  }

  & input {
    width: 100%;
  }

  & ${StyledLine} {
    max-width: unset;
  }

  &:last-child {
    padding-inline-end: 0.8rem;
  }
`;

export const Row = styled.tr`
  position: relative;
  width: 100%;

  border-bottom: 0.1rem solid var(--color-grayscale-tuna);

  &:last-child {
    border-bottom: none;
  }
`;
