import React from 'react';
import { Space } from '../app/entities/space';
import { Navigate, Outlet } from 'react-router-dom';
import { useCurrentMember } from '../hooks/use-current-member';

interface Props {
  space: Space;
}

export const MembersOnlyRoute: React.FC<Props> = ({ space }) => {
  const currentMember = useCurrentMember();
  if (currentMember?.role === 'guest') {
    return <Navigate to={`/space/${space._id}`} replace />;
  }
  return <Outlet />;
};
