import React, { useEffect } from 'react';

import { Header } from './project.page';
import { useCurrentAsset } from '../../hooks/use-current-asset';
import { uiStore } from '../../core/stores/ui-store';
import { observer } from 'mobx-react-lite';
import { AssetViewerScreen } from '../../features/assets/screens/asset-viewer.screen';
import { FolderView } from '../../features/assets/components/folder-view';
import { Breadcrumb } from '../../components/breadcrumb/breadcrumb';
import { useCurrentProject } from '../../hooks/use-current-project';

export const ProjectDocumentRoute = observer(() => {
  const project = useCurrentProject()!;
  const asset = useCurrentAsset();

  useEffect(() => {
    uiStore.currentLocation = 'project.documents';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  const breadCrumbs = [
    { label: 'Documents', href: '../' },
    ...(asset?.getBreadCrumbs(project.documentsFolderId) || []),
  ];

  return (
    <>
      <Header>
        <Breadcrumb breadcrumbs={breadCrumbs} />
      </Header>

      {asset ? (
        asset.type === 'folder' ? (
          <FolderView folderId={asset._id} />
        ) : (
          <AssetViewerScreen asset={asset} />
        )
      ) : null}
    </>
  );
});
