import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { ErrorScreen } from '../../../components/error-screen/ErrorScreen';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { createStoryboard, fetchStoryboards } from '../storyboard.slice';
import { PageLoader } from '../../../components/page-loader/PageLoader';

import { Project } from '../../../app/entities/project';
import { Step } from '../../../app/entities/step';
import { StoryboardHeader } from '../components/storyboard-screen.header';
import { StepDrawer } from '../../projects/components/step.drawer';
import { StoryboardView } from '../components/storyboard-view';

type StoryboardScreenProps = {
  project: Project;
  step: Step;
};

export const StoryboardScreen: React.FC<StoryboardScreenProps> = observer(({ project, step }) => {
  const [isLoadingStoryboard, setIsLoadingStoryboard] = useState(true);

  const isLoading = isLoadingStoryboard;
  const storyboard = step.storyboard || project.storyboard;

  useEffect(() => {
    if (!storyboard) {
      fetchStoryboards(project._id)
        .then(() => setIsLoadingStoryboard(false))
        .catch(() => setIsLoadingStoryboard(false));
    } else {
      setIsLoadingStoryboard(false);
    }
  }, [storyboard, project._id]);

  return (
    <>
      <StoryboardHeader step={step} project={project} />

      <StepDrawer step={step} />

      {isLoading ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : !storyboard ? (
        <ErrorScreen
          title="You do not have a storyboard for this project yet"
          message="Start your next story now"
          actionText="Create storyboard"
          onActionClick={() => createStoryboard(project)}
        />
      ) : (
        <StoryboardView storyboard={storyboard} />
      )}
    </>
  );
});
