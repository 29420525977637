import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { ShootingScheduleTable } from './shooting-schedule-table';
import { useResponsive } from '../../../hooks/useResponsive';
import { MobileShootingScheduleTable } from './shooting-schedule-table.mobile';
import { Strip, ShotStripData } from '../models/types';
import { Step } from '../../../app/entities/step';

import type { Stripboard } from '../../../app/entities/stripboard';
import { useCurrentMember } from '../../../hooks/use-current-member';

type ShootingScheduleProps = {
  stripboard: Stripboard;
  strips: Strip[];
  placeholder?: React.ReactNode;
  spaceId: string;
  step: Step;
};

export const ShootingSchedule: React.FC<ShootingScheduleProps> = observer(
  ({ stripboard, strips, placeholder, spaceId, step }) => {
    const { isDesktop } = useResponsive();

    const currentMember = useCurrentMember();
    const userStepPreferences = currentMember?.preferences.stepsUI[step._id];

    const filteredRows = useMemo(() => {
      if (!strips.length) {
        return [];
      }

      return strips.filter((strip) => {
        if (strip.type !== 'shot') return true;
        if (userStepPreferences?.showHiddenShots) return true;
        return !(strip.data as ShotStripData).isHidden;
      });
    }, [strips, userStepPreferences?.showHiddenShots]);

    return (
      <>
        {isDesktop ? (
          <ShootingScheduleTable
            stripboard={stripboard}
            strips={filteredRows}
            placeholder={placeholder}
          />
        ) : (
          <MobileShootingScheduleTable
            stripboard={stripboard}
            strips={filteredRows}
            placeholder={placeholder}
          />
        )}
      </>
    );
  },
);
