import React from 'react';

import { StoryboardScreen } from '../../../features/storyboards/screens/storyboard.screen';

import type { Step } from '../../../app/entities/step';
import { Project } from '../../../app/entities/project';
import { observer } from 'mobx-react-lite';

export interface StoryboardPageProps {
  project: Project;
  step: Step;
}

export const StoryboardPage: React.FC<StoryboardPageProps> = observer(({ project, step }) => {
  return <StoryboardScreen step={step} project={project} />;
});
