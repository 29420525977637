import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Phase } from './PhaseContent';
import { breakPoint } from '../../app/theme';
import { useCurrentProject } from 'hooks/use-current-project';

export const HorizontalMenu = observer(() => {
  const { stepId } = useParams() as { spaceId: string; stepId: string };
  const project = useCurrentProject()!;

  return (
    <Menu>
      <Phases>
        {project.stages.map((stage: any) => (
          <Phase key={stage._id} stage={stage} projectId={project._id} currentStepId={stepId} />
        ))}
      </Phases>
    </Menu>
  );
});

const Menu = styled.div`
  display: none;
  position: relative;
  z-index: 1000;
  flex-direction: row;
  align-items: center;
  height: var(--header-height);
  min-height: var(--header-height);
  border-bottom: 1px rgba(255, 255, 255, 0.16) solid;
  max-width: 100%;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
  }
`;

const Phases = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0.8rem;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  gap: 0.8rem;

  &::-webkit-scrollbar {
    display: none;
  }
`;
