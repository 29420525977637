import { api } from '../../../api';
import { upload } from '../../../core/services/file.service';
import { Frame } from 'lib/utils/transform-image';
import { Project } from '../../../app/entities/project';
import { entityPool } from '../../../core/engine/engine';
import { appSocket } from '../../../app-socket';
import { Step } from '../../../app/entities/step';
import { Location } from '../../../app/entities/location';
import { Storyboard } from '../../../app/entities/storyboard';
import { Character } from '../../../app/entities/character';
import { Stripboard } from '../../../app/entities/stripboard';
import { Asset } from '../../../app/entities/asset';
import { Stage } from '../../../app/entities/stage';
import { Shot } from '../../../app/entities/shot';
import { ShareLink } from '../../../app/entities/share-link';
import { Candidate } from '../../../app/entities/candidate';

export const fetchSpaceProjects = async (spaceId: string) => {
  const { data } = await api.get(`/spaces/${spaceId}/projects`);

  data.forEach((el: any) => {
    /** Backend is sending a shootingDays value that is not use **/
    const { shootingDays, ...values } = el;

    const project = Object.assign(new Project(), values);
    entityPool.insert(project);
  });
};

export const bootstrapProject = async (projectId: string) => {
  const { data } = await api.get(`/projects/${projectId}/bootstrap`);

  // handle stages
  for (const stage of data.stages) {
    const stageEntity = Object.assign(new Stage(), stage);
    entityPool.insert(stageEntity);
  }

  // handle steps
  if (data.steps) {
    for (const step of data.steps) {
      const stepEntity = Object.assign(new Step(), step, { assets: [] });
      entityPool.insert(stepEntity);
    }

    // at this point the steps are already in the store and attached to the stages and ready for sorting
    const stages = Stage.getAll().filter((s) => s.projectId === projectId);
    for (let i = 0; i < stages.length; i++) {
      stages[i].steps.sort((a, b) => a.order - b.order);
    }
  }

  // handle locations
  for (const location of data.locations) {
    const locationEntity = Object.assign(new Location(), location);
    entityPool.insert(locationEntity);
  }

  // handle storyBoards
  for (const storyBoard of data.storyBoards) {
    const storyboardEntity = Object.assign(new Storyboard(), storyBoard);
    entityPool.insert(storyboardEntity);
  }

  // handle characters
  data.characters.forEach(({ candidates, ...el }: any) => {
    const character = Object.assign(new Character(), el);
    entityPool.insert(character);

    candidates.forEach((cd: any) => {
      const candidate = Object.assign(new Candidate(), cd, { projectId, characterId: el._id });
      entityPool.insert(candidate);
    });
  });

  // handle stripBoard
  const stripBoard = Object.assign(new Stripboard(), data.stripBoard);
  entityPool.insert(stripBoard);

  // handle assets
  for (const asset of data.assets) {
    const assetEntity = Object.assign(new Asset(), asset);
    entityPool.insert(assetEntity);
  }

  // handle shots
  for (const shot of data.shots) {
    const shotEntity = Object.assign(new Shot(), shot);
    entityPool.insert(shotEntity);
  }

  for (const shareLink of data.shareLinks) {
    const shareLinkEntity = Object.assign(new ShareLink(), shareLink);
    entityPool.insert(shareLinkEntity);
  }
};

/**
 * Create Project
 */

type CreateProjectInput = {
  spaceId: string;
  name: string;
  client?: string;
  agency?: string;
  shootingDays?: number;
  fps?: number;
  aspectRatio?: string;
  dueDate?: string;
  file?: Frame;
};

export const createProject = async (input: CreateProjectInput) => {
  const { spaceId, file, ...body } = input;

  if (file) {
    // @ts-ignore
    body.file = {
      type: file.type.split('/')[1],
      size: file.size,
      fileType: file.type,
    };
  }

  const { data } = await api.post(`/spaces/${spaceId}/projects`, body);

  if (data.links && file) {
    const uploadRes = await upload(data.links.upload, file.data);

    if (uploadRes.status === 201) {
      appSocket.projectAvatarUpdated(data);
    }
  }

  const project = Object.assign(new Project(), data);
  entityPool.insert(project);
};

export const duplicateProject = async (projectId: string) => {
  const { data } = await api.post(`/projects/duplicate/${projectId}`);

  const project = Object.assign(new Project(), data);
  entityPool.insert(project);

  return data;
};
