import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Header } from './project.page';
import { uiStore } from '../../core/stores/ui-store';
import { ShareLinkList } from '../../features/share/components/share-link-list';
import { ScreenContainer } from 'features/projects/components/styled-project-components';

export const ProjectShareLinksRoute = observer(() => {
  useEffect(() => {
    uiStore.currentLocation = 'project.share-links';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <React.Suspense>
      <Header>Share links</Header>

      <ScreenContainer>
        <Container>
          <ShareLinkList />
        </Container>
      </ScreenContainer>
    </React.Suspense>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: 100%;
`;
