import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { useCurrentSpace } from '../../../hooks/use-current-space';
import { useOnClickOutside } from '../../../hooks/useOutsideClick';
import { authStore } from '../../../core/stores/auth-store';
import { MainDrawer } from './main-drawer';
import { ProjectDrawer } from './project-drawer';
import { uiStore } from 'core/stores/ui-store';
import { breakPoint } from '../../theme';
import { useCurrentProject } from 'hooks/use-current-project';
import { useResponsive } from '../../../hooks/useResponsive';

import type { User } from 'app/entities/user';
import type { Space } from 'app/entities/space';

export type DrawerComponentProps = {
  isOpen: boolean;
  currentUser: User;
  space: Space;
};

export const Drawer: React.FC = observer(() => {
  const currentUser = authStore.currentUser!;
  const space = useCurrentSpace();
  const project = useCurrentProject();
  const isOpen = uiStore.isDrawerOpen;
  const { isDesktop } = useResponsive();
  const { ref } = useOnClickOutside(() => (uiStore.isDrawerOpen = false));

  return (
    <Container ref={ref} data-is-open={isOpen}>
      {isDesktop || !project ? (
        <MainDrawer isOpen={isOpen} currentUser={currentUser} space={space} />
      ) : (
        <ProjectDrawer isOpen={isOpen} currentUser={currentUser} space={space} />
      )}
    </Container>
  );
});

const Container = styled.div`
  top: var(--tab-bar-height);
  background: var(--color-surfaces-app-background-0);
  position: absolute;
  display: flex;
  flex-direction: column;
  width: var(--drawer-width-closed);
  height: calc(100% - var(--tab-bar-height));
  transition: all 200ms ease-in;
  z-index: 9999;

  transform: translateX(calc(-1 * var(--drawer-width-closed)));

  &[data-is-open='true'] {
    transform: translateX(0);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    top: 0;
    height: 100%;
    transform: translateX(0);
    background: black;
    position: relative;
  }
`;
