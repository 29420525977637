import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Close } from '../../components/icons/Close';
import { Tab } from '../../features/tabs/models/tab';
import { api } from '../../api';

type TabProps = {
  tab: Tab;
  active?: boolean;
  id?: string;
  onClose: (key: string) => void;
};

export const TabItem: React.FC<TabProps> = observer(({ tab, active, id, onClose }) => {
  const navigate = useNavigate();

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    tab.close();
    onClose(tab.key);
  };

  const handleOpenTab = () => {
    api.updateSpaceInHeaders(tab.href.split('/')[2]!);
    navigate(tab.href);
  };

  return (
    <Container id={id} data-is-active={active} onClick={handleOpenTab}>
      {tab.label ? <span>{tab.label}</span> : <LabelPlaceholder />}

      <span onClick={handleClose}>
        <Close />
      </span>
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  margin-top: 0.4rem;
  height: calc(var(--tab-bar-height) - 0.4rem);
  width: 28rem;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.4rem;
  transition: 0.1s;
  color: grey;
  padding: 0 1.4rem 0 2rem;
  font-size: 1.4rem;

  span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1.6rem;
  }

  span:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.8rem;
    height: 1.8rem;
    min-width: 1.8rem;
    transition: 0.1s;

    svg {
      width: 1rem;
      fill: grey;
      transition: 0.1s;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 0.4rem;

      svg {
        fill: white;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    height: 70%;
    width: 0.5px;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    cursor: pointer;
    color: white;
    background-color: var(--header-tab-bg-color-hover);

    &:after {
      display: none;
    }
  }

  &[data-is-active='true'] {
    background-color: var(--header-tab-bg-color-active);
    border: 1px var(--content-border-color) solid;
    color: white;
  }
`;

const LabelPlaceholder = styled.span`
  display: inline-block;
  height: 1.2rem;
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
  max-width: 16rem;
`;
