import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Outlet, Route, Routes, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { initializePaddle } from '@paddle/paddle-js';
import { addMonths, isPast } from 'date-fns';

import { PageLoader } from './components/page-loader';
import { ContactPage } from '../routes/contacts/contact.page';
import { AnalyticsPages } from '../routes/analytics/analytics.pages';
import { ProjectList } from '../features/projects/components/ProjectList';
import { breakPoint } from './theme';
import { Upload } from './entities/upload';
import { Space } from './entities/space';
import { api } from '../api';
import { insufficientStorageStore } from '../core/stores/insufficient-storage';
import { InsufficientStorageDialog } from '../components/dialogs/insufficient-storage-dialog';
import { DesktopWarning } from './components/desktop-warning';
import { authStore } from '../core/stores/auth-store';
import { UnauthorizedDialog } from '../components/dialogs/unauthorized-dialog';
import { AppHeader } from './components/app-header';
import { bulkUpdateStore } from '../core/stores/bulk-update';
import { ShotsBulkUpdateModal } from '../components/dialogs/shots-bulk-update.modal';
import { MembersOnlyRoute } from '../components/members-only-route';
import { globalConfig } from '../configuration/config';
import { PastDueSubscriptionBanner } from './components/past-due-subscription-banner';
import { PastDueWarningModal } from '../features/billing/components/past-due-warning.modal';
import { SpaceLockedPlaceholder } from '../features/billing/components/space-locked.placeholder';
import { Drawer } from './components/Drawer/drawer';
import { useCurrentMember } from '../hooks/use-current-member';

type PageParams = {
  spaceId: string;
};

export const Core: React.FC = observer(() => {
  const { spaceId } = useParams() as PageParams;
  const space = Space.getOne(spaceId)!;
  const [isPastDueModalOpen, setIsPastDueModalOpen] = useState(false);
  const [isSpaceLocked, setIsSpaceLocked] = useState(false);
  const currentmember = useCurrentMember();

  useEffect(() => {
    api.updateSpaceInHeaders(spaceId);
  }, [spaceId]);

  useEffect(() => {
    if (!globalConfig.config.paddleToken) {
      return;
    }

    initializePaddle({
      token: globalConfig.config.paddleToken,
      pwCustomer: {
        id: space.subscription?.customerId || undefined,
      },
    });
  }, [space, spaceId]);

  /**
   * TODO URGENT
   * Will not work when reopening the page as the asset will not be there anymore
   */

  useEffect(() => {
    Upload.loadDB();
  }, []);

  /**
   * Check if Space is overdue and display modal id overdue for more than one month
   */
  useEffect(() => {
    if (
      space.isSubscriptionPastDue &&
      !!space.subscription?.past_due_at &&
      (currentmember?.role || 'guest')
    ) {
      if (!space.subscription || !space.subscription.past_due_at) return;

      if (isPast(addMonths(space.subscription.past_due_at, 2))) {
        setIsSpaceLocked(true);
      } else if (isPast(addMonths(space.subscription.past_due_at, 1))) {
        setIsPastDueModalOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [space, spaceId]);

  if (!spaceId || !space) {
    return <PageLoader />;
  }

  const displayBanner = !!space?.isSubscriptionPastDue;

  return (
    <Container>
      {displayBanner && <PastDueSubscriptionBanner spaceId={spaceId} />}

      <App>
        <Drawer />

        <Main>
          <AppHeader space={space} hasBanner={displayBanner} />

          <Content>
            <ContentContainer>
              <Outlet />

              <Routes>
                <Route element={<MembersOnlyRoute space={space} />}>
                  <Route
                    path="/contacts/*"
                    element={
                      isSpaceLocked ? <SpaceLockedPlaceholder /> : <ContactPage space={space} />
                    }
                  />
                  <Route path="/analytics/*" element={<AnalyticsPages />} />
                </Route>

                <Route
                  path="/"
                  element={
                    isSpaceLocked ? <SpaceLockedPlaceholder /> : <ProjectList space={space} />
                  }
                />
              </Routes>
            </ContentContainer>
          </Content>
        </Main>
      </App>

      {insufficientStorageStore.state && (
        <InsufficientStorageDialog
          spaceId={space._id.toString()}
          onCancel={() => insufficientStorageStore.setState(false)}
        />
      )}

      {bulkUpdateStore.shouldOpenModal && (
        <ShotsBulkUpdateModal
          shotsCount={bulkUpdateStore.shotsCount}
          onUpdate={bulkUpdateStore.onUpdate}
          onCancel={bulkUpdateStore.onCancel}
        />
      )}

      {isPastDueModalOpen && (
        <PastDueWarningModal spaceId={spaceId} onCancel={() => setIsPastDueModalOpen(false)} />
      )}

      {!authStore.isAuthorized && <UnauthorizedDialog />}

      <DesktopWarning />
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  overflow-x: hidden;
  overflow-y: hidden;
`;

const App = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: black;
  min-width: 0;

  @media (min-width: ${breakPoint.medium}px) {
    max-width: calc(100% - var(--drawer-width-closed));
  }
`;

const Content = styled.div`
  flex: 1;
  max-height: calc(100% - 4rem);

  @media (min-width: ${breakPoint.medium}px) {
    padding: 0.4rem 0.4rem 0.4rem 0;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  background: var(--content-bg-color);

  @media (min-width: ${breakPoint.medium}px) {
    border: 1px var(--content-border-color) solid;
    border-radius: 4px;
  }
`;
