import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ShootingSchedule } from '../components/ShootingSchedule';
import { fetchStripBoard } from '../../strip-board/strip-board.slice';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { PageLoader } from '../../../components/page-loader/PageLoader';
import { breakPoint } from '../../../app/theme';
import { ScheduleHeader } from '../components/schedule-screen.header';
import { StepDrawer } from '../../projects/components/step.drawer';
import { Shot } from '../../../app/entities/shot';

import type { ScreenProps } from '../../projects/models/types';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import { useCurrentProject } from 'hooks/use-current-project';

export const ScheduleScreen: React.FC<ScreenProps> = observer(({ step }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingShot, setIsAddingShot] = useState(false);
  const [isShowingLimitsModal, setIsShowingLimitsModal] = useState(false);

  const project = useCurrentProject()!;
  const stripBoard = project.stripboard;

  useEffect(() => {
    fetchStripBoard(project._id).then(() => {
      setIsLoading(false);
    });
  }, [project]);

  const addShot = async () => {
    if (!project.canAddShot()) {
      setIsShowingLimitsModal(true);
      return;
    }

    if (isAddingShot) return;

    setIsAddingShot(true);

    try {
      await Shot.createShot({
        projectId: stripBoard.project._id,
        position: stripBoard.project.shots.length,
      });

      setIsAddingShot(false);
    } catch {
      setIsAddingShot(false);
    }
  };

  return (
    <React.Fragment>
      <ScheduleHeader step={step} project={project} />

      <StepDrawer step={step} />

      {isLoading ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : (
        <CustomScreenContainer>
          {stripBoard ? (
            <ShootingSchedule
              stripboard={stripBoard}
              strips={stripBoard.strips}
              spaceId={project.spaceId}
              step={step}
            />
          ) : null}

          <AddShotStrip onClick={addShot}>Add shot</AddShotStrip>
        </CustomScreenContainer>
      )}

      {isShowingLimitsModal && (
        <LimitReachedModal
          type="shots"
          eventName="Shots exceed 10"
          onCancel={() => setIsShowingLimitsModal(false)}
        />
      )}
    </React.Fragment>
  );
});

const AddShotStrip = styled.div`
  border-radius: 0.4rem;
  height: 4.8rem;
  color: #8a8f98;
  border: 2px dashed currentColor;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  margin-top: 0.8rem;
  margin-bottom: var(--spacing-bottom-screen-container);

  &:hover {
    cursor: pointer;
    color: var(--color-grayscale-ghost);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    height: 6.4rem;
    margin-top: 0;
  }
`;

const CustomScreenContainer = styled(ScreenContainer)`
  padding: 0 1.6rem;
`;
