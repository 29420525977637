import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { Asset } from '../../../app/entities/asset';
import { useTranslation } from 'react-i18next';
import { Widescreen } from '../../../components/icons/Widescreen';

interface AssetViewerImageProps {
  asset: Asset;
  src?: string;
}

export const AssetViewerImage: React.FC<AssetViewerImageProps> = ({ asset, src }) => {
  const { t } = useTranslation('moodboard');
  const image = useRef<HTMLImageElement>(null);
  const [imageNotFound, setImageNotFound] = useState(false);

  useEffect(() => {
    setImageNotFound(false);
  }, [src]);

  const handleFullScreen = async () => {
    if (!image.current) {
      return;
    }

    const requestFullscreen =
      image.current.requestFullscreen ||
      // @ts-ignore
      image.current.mozRequestFullScreen ||
      // @ts-ignore
      image.current.webkitEnterFullScreen ||
      // @ts-ignore
      image.current.msRequestFullscreen;

    requestFullscreen.call(image.current);
  };

  // TODO Handle a better loading in the parent component
  return (
    <Container>
      {src && (
        <MoodboardCardPicture>
          {!imageNotFound ? (
            <ImageLayout>
              <ImageContainer>
                <img
                  ref={image}
                  src={src}
                  alt={asset.name}
                  onError={() => setImageNotFound(true)}
                />
              </ImageContainer>
            </ImageLayout>
          ) : (
            <ReplacementImage>
              <p>{t('common:image_not_found')}</p>
            </ReplacementImage>
          )}
        </MoodboardCardPicture>
      )}

      <ImageController>
        <IconBox onClick={handleFullScreen}>
          <Widescreen />
        </IconBox>
      </ImageController>
    </Container>
  );
};

const Container = styled.div`
  background-color: #17171b;
  text-align: center;
  flex: 1;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 0.375rem;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
`;

const ImageLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const IconBox = styled.div`
  cursor: pointer;
`;

const ImageController = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  background-color: var(--bg-color-dark-black);
  align-items: center;
  padding: 0 1.6rem;
  border-radius: 0 0 0.4rem 0.4rem;
`;

const MoodboardCardPicture = styled.div`
  text-align: center;
  user-select: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 0;

  img {
    width: 100%;
    height: 100%;
    user-select: none;
    object-fit: contain;
  }
`;

const ReplacementImage = styled.div`
  height: 53rem;
  width: 53rem;
  font-family: Inter, sans-serif;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white-default);
  background-color: var(--color-grey-3);
  margin: auto;

  p {
    text-transform: uppercase;
    font-weight: 600;
  }
`;
