import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { CharacterDetailScreen } from '../../../features/character/screens/character-detail.screen';
import { CharacterListScreen } from '../../../features/character/screens/character-list.screen';
import { Project } from '../../../app/entities/project';

import type { Step } from '../../../app/entities/step';

export type CastSelectionPageProps = {
  project: Project;
  step: Step;
};

export const CastingPage: React.FC<CastSelectionPageProps> = ({ project, step }) => (
  <Routes>
    <Route
      path="/:characterId/*"
      element={
        <CharacterDetailScreen
          projectId={project._id}
          step={step}
          spaceId={project.spaceId}
          isLoading={false}
        />
      }
    />

    <Route path="/" element={<CharacterListScreen projectId={project._id} step={step} />} />
  </Routes>
);
