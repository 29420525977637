import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { CandidateCard } from './candidate-card';
import { sortingFunction } from '../../../assets/enums/assetType.enum';

import type { Candidate } from '../../../app/entities/candidate';

type CandidatesListProps = {
  candidates: Candidate[];
  castId?: string;
  sortBy: string;
};

export const CandidatesList: React.FC<CandidatesListProps> = observer(
  ({ candidates, castId, sortBy }) => {
    if (!candidates?.length) {
      return (
        <EmptyResultsContainer>
          <p>No results found</p>
        </EmptyResultsContainer>
      );
    }

    const sortedCandidates = candidates
      ?.slice()
      // @ts-ignore
      .sort(sortingFunction[sortBy])
      .sort((candidate) => (candidate._id === castId ? -1 : 1));

    return (
      <Container>
        {sortedCandidates?.map((candidate) => (
          <CandidateCard
            key={candidate._id}
            candidate={candidate}
            isSelectedCandidate={candidate._id === castId}
          />
        ))}
      </Container>
    );
  },
);
const EmptyResultsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  & > p {
    font-size: 1.6rem;
    line-height: 2rem;
    color: #f2f3f5;
  }
`;

const Container = styled.div`
  display: grid;
  position: relative;
  grid-gap: 1.6rem;
  font-size: 1.6rem;
  overflow: auto;
  flex: 1;
  grid-auto-rows: max-content;
  padding: 1.6rem;

  @media screen and (min-width: 420px) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: 750px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: 1124px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @media screen and (min-width: 1920px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;
