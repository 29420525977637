import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Link, useParams } from 'react-router-dom';

import { Header } from './project.page';
import { Character } from '../../app/entities/character';
import { CharacterInfoTab } from '../../features/character/components/character-info-tab';
import { uiStore } from '../../core/stores/ui-store';

export const ProjectCharacterRoute = () => {
  const { characterId } = useParams() as { characterId: string };

  const character = Character.getOne(characterId)!;

  useEffect(() => {
    uiStore.currentLocation = 'project.characters';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <>
      <Header>
        <Crumb>
          <Parent to="../">Characters</Parent>
          <ChevronRightIcon />
          {character.name}
        </Crumb>
      </Header>

      <Container>
        <CharacterInfoTab characterId={characterId} />
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const Crumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Parent = styled(Link)`
  color: #e3e4e7;

  &:hover {
    color: white;
  }
`;
