import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Header } from './project.page';
import { useCurrentProject } from '../../hooks/use-current-project';
import { CharacterCards } from '../../features/character/components/character-cards';
import { CreateCharacterDrawer } from '../../features/character/components/create-character.drawer';
import { ElevatedButton } from '../../components/buttons';
import { uiStore } from 'core/stores/ui-store';
import { CharacterListPlaceholder } from '../../features/character/components/character-list-placeholder';

export const ProjectCharactersRoute = observer(() => {
  const project = useCurrentProject()!;
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    uiStore.currentLocation = 'project.characters';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <>
      <Header>
        <div>Characters</div>
        <ElevatedButton text="Create character" onClick={() => setIsCreateModalOpen(true)} />
      </Header>

      <Content>
        <Container>
          {project.characters.length !== 0 ? (
            <CharacterCards characters={project.characters} />
          ) : (
            <CharacterListPlaceholder onAddCharacterClicked={() => setIsCreateModalOpen(true)} />
          )}
        </Container>

        {isCreateModalOpen && (
          <CreateCharacterDrawer
            projectId={project._id}
            onCancel={() => setIsCreateModalOpen(false)}
          />
        )}
      </Content>
    </>
  );
});

const Content = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  height: 100%;
`;
