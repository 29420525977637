import React, { ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ObjectShape } from 'yup/lib/object';
import * as yup from 'yup';

import { TextInput } from '../text-input/TextInput';
import { TextAreaInput } from '../textarea-input/TextAreaInput';
import { FieldContainer, FormTitle, HasServerResponse } from '../PanelContainer';
import { DialogFooter } from '../dialog';
import { TextButton, ElevatedButton } from '../buttons';
import { Tag } from '../tag/Tag';
import { ModalHeader } from './modal-header';
import { FormBuilderDefinition, type FormBuilderField } from './form-builder';
import { ProfileAvatar } from '../profile-avatar/ProfileAvatar';
import { breakPoint } from '../../app/theme';
import { useResponsive } from '../../hooks/useResponsive';

interface FormBuilderModalProps {
  model: 'location' | 'contact' | 'character';
  definition: FormBuilderDefinition;
  onSubmit: (values: any) => Promise<void>;
  onCancel: () => void;
  autoFocus?: boolean;
  initialValues?: any;
}

export const FormBuilderModal: React.FC<FormBuilderModalProps> = ({
  model,
  definition,
  onSubmit,
  onCancel,
  autoFocus = true,
  initialValues,
}) => {
  const t = useTranslation(model);
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState<any>();
  const { isDesktop } = useResponsive();

  const onHandleSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    try {
      await onSubmit(values);
      handleCancel();
    } catch (e) {
      setServerError(e);
    } finally {
      setIsLoading(false);
    }
  };

  const buildValidationSchema = (fields: FormBuilderField[]) => {
    const schema: ObjectShape = {};

    for (const field of fields) {
      if (field.validation) {
        schema[field.name!] = field.validation;
      } else if (field.fields?.length) {
        schema[field.name!] = buildValidationSchema(field.fields);
      }
    }

    return yup.object().shape(schema);
  };

  const schema = useMemo(() => buildValidationSchema(definition.fields), [t]);

  // TODO: Works just with two levels of hierarchy
  const getFieldName = (field: FormBuilderField, parentFieldName?: string) => {
    return parentFieldName ? `${parentFieldName}.${field.name}` : field.name;
  };

  const getFieldDefaultValue = (field: FormBuilderField) => {
    return (
      initialValues?.[field.name!] || (['text', 'textArea'].includes(field.type) ? '' : undefined)
    );
  };

  const buildDefaultValues = (fields: FormBuilderField[]) => {
    const defaultValues: Record<string, any> = {};

    for (const field of fields) {
      defaultValues[field.name!] = getFieldDefaultValue(field);
    }

    return defaultValues;
  };

  const renderForm = (fields: FormBuilderField[], parentFieldName?: string): ReactNode[] => {
    return fields.map((field) => {
      if (field.type === 'group') {
        return (
          <>
            <FormTitle>{field.sectionHeader}</FormTitle>
            {field.fields?.length && renderForm(field.fields!, field.name)}
          </>
        );
      }

      if (field.type === 'upload') {
        return (
          <ProfileAvatarWrapper key={field.name}>
            <ProfileAvatar
              placeholder="image"
              defaultUrl={initialValues?.avatar}
              onChange={field.handleSetImage}
            />
          </ProfileAvatarWrapper>
        );
      } else if (field.type === 'text') {
        return (
          <FieldContainer key={field.name}>
            <TextInput
              {...register(getFieldName(field, parentFieldName)!)}
              placeholder={field.placeholder}
              errorMessage={errors[field.name!]?.message as string}
              label={field.label}
              defaultValue={getFieldDefaultValue(field)}
              required={!!field.isRequired}
            />
          </FieldContainer>
        );
      } else if (field.type === 'textArea') {
        return (
          <FieldContainer key={field.name}>
            <TextAreaInput
              {...register(getFieldName(field, parentFieldName)!)}
              placeholder={field.placeholder}
              errorMessage={errors[field.name!]?.message}
              label={field.label}
              required={!!field.isRequired}
            />
          </FieldContainer>
        );
      } else if (field.type === 'controller') {
        return (
          <FieldContainer key={field.name}>
            <Label>{field.label}</Label>
            <Controller control={control} name={field.name!} render={field.render!} />
          </FieldContainer>
        );
      } else {
        return <></>;
      }
    });
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: buildDefaultValues(definition.fields),
  });

  const handleCancel = () => {
    reset();
    onCancel();
  };

  return (
    <Content className="DialogContent">
      <ModalHeader
        title={definition.header}
        onClose={handleCancel}
        action={initialValues ? 'Update' : 'Create'}
        onAction={handleSubmit(onHandleSubmit)}
      />

      <Container>
        {serverError && serverError.message && (
          <HasServerResponse>
            <Tag text={serverError.message} />
          </HasServerResponse>
        )}

        <Form>{renderForm(definition.fields)}</Form>
      </Container>

      {isDesktop && (
        <DialogFooter
          actions={[
            <TextButton key="cancel" text="Cancel" onClick={handleCancel} />,
            <ElevatedButton
              key="submit"
              id={`${model}-submit`}
              text={definition.submitText}
              isLoading={isLoading}
              onClick={handleSubmit(onHandleSubmit)}
            />,
          ]}
        />
      )}
    </Content>
  );
};

const Content = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: var(--layer-modal);
  width: 100%;
  max-width: 100%;
  background: var(--color-grayscale-eerie-black);
  box-shadow: hsl(206 22% 7% / 35%) 0 10px 38px -10px, hsl(206 22% 7% / 20%) 0 10px 20px -15px;
  animation: content-show 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes content-show {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @media (min-width: ${breakPoint.medium}px) {
    width: 37.6rem;
    border-left: 1px solid var(--content-border-color);

    @keyframes content-show {
      from {
        opacity: 0;
        transform: translateX(100%);
      }
      to {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  }
`;

const Container = styled.div`
  padding: 0 2.4rem 2.4rem;
  overflow-y: scroll;
  margin-bottom: 0.8rem;
  background: var(--color-grayscale-eerie-black);
  flex: 1;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 0.8rem;

  @media (min-width: ${breakPoint.small}px) {
    width: unset;
  }
`;

const Label = styled.label`
  display: block;
  font-weight: 600;
  color: white;
  letter-spacing: 0.7px;
  line-height: 1.1rem;
  margin-bottom: 0.8rem;
`;

const ProfileAvatarWrapper = styled.div`
  margin: 0 auto;
  position: relative;
`;
