import React, { useMemo } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

import { FileInfo } from '../../../routes/project/components/assets/FileInfo';
import { FilePreview } from '../../../components/icons/FilePreview';
import { FilePreviewAudio } from '../../../components/icons/FilePreviewAudio';
import { LinkPreview } from '../../../components/icons/LinkPreview';
import { DownloadOverlay } from './download-overlay';
import { FILES_TYPES } from '../../../assets/enums/assetType.enum';
import { Add } from '../../../components/icons';
import { getModifier } from '../../../core/services/event.service';
import { useSelection } from '../../../core/contexts/selection.context';
import { secondsToHms } from '../../../lib/utils/Convertor';

import { Asset } from '../../../app/entities/asset';
import { User } from '../../../app/entities/user';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';
import { catchError } from '../../../core/catch-error';
import { breakPoint } from '../../../app/theme';
import { AssetMenu } from './asset-menu';
import { useResponsive } from '../../../hooks/useResponsive';
import { useLongTouch } from '../../../app/hooks/use-long-touch';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

type FileItemProps = {
  asset: Asset;
  assetsRouteTo?: (asset: string, assetId: string) => void;
  isSelected?: boolean;
  currentUser?: User | null;
};

const RenderCover: React.FC<{ file: Asset }> = ({ file }) => {
  if (file.type === 'link') {
    const previewImage = file?.meta?.previewImage;
    const favIcon = file?.meta?.favIcon;

    return previewImage || favIcon ? (
      <FilesBodyItemImage data-hasLogo={!!favIcon && !previewImage}>
        {previewImage ? (
          <>
            <LinkIconWrapper data-onTopOfPreviewImage>
              <LinkPreview />
            </LinkIconWrapper>
            <ImageCover src={previewImage} alt={file.name} />
          </>
        ) : (
          <FilesImageMp4
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <ImageCover src={favIcon} alt={file.name} />
          </FilesImageMp4>
        )}
      </FilesBodyItemImage>
    ) : (
      <FilesImageMp4>
        <LinkIconWrapper>
          <LinkPreview />
        </LinkIconWrapper>
      </FilesImageMp4>
    );
  } else if (file.type === 'note') {
    return (
      <FilesImageMp4>
        <FilePreview text="note" />
      </FilesImageMp4>
    );
  } else if (file.fileType?.includes(FILES_TYPES.AUDIO)) {
    return (
      <FilesImageMp4>
        <FilePreviewAudio />
      </FilesImageMp4>
    );
  } else if (
    file.fileType?.includes(FILES_TYPES.IMAGE) ||
    file.fileType?.includes('png') ||
    file.fileType?.includes('jpeg')
  ) {
    return (
      <FilesBodyItemImage>
        {file.cover ? (
          <ImageCover src={file.cover.src} alt={file.name} />
        ) : (
          <FilePreview text={file.extension} />
        )}
      </FilesBodyItemImage>
    );
  } else if (file.fileType?.includes(FILES_TYPES.VIDEO)) {
    return (
      <FilesBodyItemImage>
        <ImageCover src={file.cover?.src} alt={file.name} />
        {
          <VideoDuration>
            {file.meta?.duration ? secondsToHms(file.meta.duration) : 'MOVIE'}
          </VideoDuration>
        }
      </FilesBodyItemImage>
    );
  }

  return (
    <FilesImageMp4>
      <FilePreview text={file.extension} />
    </FilesImageMp4>
  );
};

export const FileTile: React.FC<FileItemProps> = observer(
  ({ asset, assetsRouteTo, isSelected, currentUser }) => {
    const { select, toggleSelectMode, selectionModeOn } = useSelection();
    const { isMobile } = useResponsive();
    const { longTouchRef } = useLongTouch({
      onLongTouch: () => toggleSelectMode(asset._id),
    });

    const { setNodeRef, attributes, listeners, transform, isDragging } = useDraggable({
      id: asset._id,
      data: {
        type: 'file',
      },
    });

    const style = {
      transform: CSS.Transform.toString(transform),
    };

    const handleNewVersion = async (file: File) => {
      if (!asset.uploaded) {
        return;
      }

      try {
        await asset.addVersion(file);
      } catch (e) {
        catchError(e);
      }
    };

    const onDrop = (files: File[]) => {
      handleNewVersion(files[0]);
    };

    const accepts = useMemo(() => {
      switch (asset.fileType) {
        case 'image/*':
          return {
            'image/png': ['.png', '.jpg', '.jpeg'],
          };
        case 'audio/*':
          return {
            'audio/mpeg': ['.mp3'],
          };
        case 'video/*':
          return {
            'video/mp4': ['.mp4', '.mov', '.avi', '.mkv'],
          };
        case 'application/pdf':
          return {
            'application/pdf': ['.pdf'],
          };
        case 'application/zip':
          return {
            'application/zip': ['.zip'],
          };
      }
    }, [asset.fileType]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      noClick: true,
      noDragEventsBubbling: true,
      accept: accepts as Record<string, string[]> | undefined,
    });

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();

      if (!isMobile || selectionModeOn) {
        const modifier = getModifier(e.nativeEvent);
        select(asset._id, modifier);
        return;
      }

      handleOpenFile();
    };

    const handleOpenFile = () => {
      if (asset.type === 'link') {
        const hasValidSchema = new RegExp(/^https?:\/\//).test(asset.url!);
        const url = hasValidSchema ? asset.url : `https://${asset.url}`;

        yup
          .string()
          .url()
          .validate(url)
          .then(() => {
            const newTab = window.open(url, '_blank');
            newTab?.focus();
          })
          .catch(() => {
            toastPool.insert(new Toast('Invalid URL.', 'error'));
          });

        return;
      }

      if (asset.uploaded === false && currentUser?._id !== asset.uploaderId) {
        toastPool.insert(new Toast('File is not uploaded yet.', 'error'));
        return;
      }

      return assetsRouteTo && asset && assetsRouteTo('file', asset._id);
    };

    if (asset.type === 'folder') {
      return (
        <Container onDoubleClick={handleOpenFile} onClick={handleClick} data-selected={isSelected}>
          <FileHeader />

          <FileFooter>
            <FileName>{asset.name}</FileName>
          </FileFooter>

          <ModalContainer>
            <AssetMenu asset={asset} onOpen={handleOpenFile} />
          </ModalContainer>
        </Container>
      );
    }

    return (
      <>
        <Container
          key={asset?._id + (asset?.meta?.previewImage || '')} // To rerender the preview image immediately after updating the url
          onDoubleClick={handleOpenFile}
          {...getRootProps()}
          onClick={handleClick}
          data-selected={isSelected}
          ref={(e) => {
            setNodeRef(e);
            longTouchRef.current = e;
          }}
          style={style}
          data-dragging={isDragging}
          {...attributes}
          {...listeners}
        >
          <input {...getInputProps()} />
          {asset.currentVersionNumber && (
            <CurrentVersionDisplay>
              <span>v</span>
              {asset.currentVersionNumber}
            </CurrentVersionDisplay>
          )}

          <FileHeader>
            <FileCover>
              {isDragActive && asset.type !== 'link' && (
                <DragOverlay>
                  <DropIconWrapper>
                    <Add />
                  </DropIconWrapper>

                  <DragOverlayText>
                    Add Version
                    <br />
                    of same file type
                  </DragOverlayText>
                </DragOverlay>
              )}

              <RenderCover file={asset} />

              {asset.uploaded === false &&
                (asset.uploaderId && currentUser && asset.uploaderId === currentUser._id ? (
                  asset.upload ? (
                    <ProgressLoader>
                      <DownloadOverlay
                        progress={asset.upload.progress}
                        status={asset.upload.status}
                        onPause={() => {
                          asset.upload!.pause();
                        }}
                        onResume={() => {
                          asset.upload!.resume();
                        }}
                      />
                    </ProgressLoader>
                  ) : (
                    <BlockedUploadingOverlay>
                      <span>resuming files from different machines is not supported yet</span>
                    </BlockedUploadingOverlay>
                  )
                ) : (
                  <BlockedUploadingOverlay>
                    <span>This file is being uploaded by another user</span>
                  </BlockedUploadingOverlay>
                ))}
            </FileCover>

            <ModalContainer>
              {asset.uploaded === false && currentUser?._id !== asset.uploaderId ? null : (
                <AssetMenu asset={asset} onOpen={handleOpenFile} />
              )}
            </ModalContainer>
          </FileHeader>

          <FileFooter>
            <FileName>{asset.name}</FileName>
            <FileInfo file={asset} />
          </FileFooter>
        </Container>
      </>
    );
  },
);

const Container = styled.article`
  position: relative;
  width: 100%;
  border-radius: 0.8rem;
  padding: 0.4rem;
  min-width: 0;
  background: var(--color-grayscale-charleston);
  user-select: none;

  &[data-selected='true'] {
    box-shadow: inset 0 0 0 1.5px var(--color-secondary);
  }

  &:hover {
    cursor: pointer;
  }

  &[data-dragging='true'] {
    opacity: 0;
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    background: var(--color-grayscale-eerie-black);
  }
`;

const FileHeader = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9;
`;

const FileFooter = styled.footer`
  padding: 0.8rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 0.8rem 0.8rem 0 0.8rem;
  }
`;

const FileCover = styled.div`
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 0.6rem;
  background-color: #28292d;
  overflow: hidden;
`;

const FileName = styled.div`
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.9rem;
  color: #e3e4e7;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: block;
  white-space: nowrap;
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  cursor: pointer;
  z-index: 13;
`;

const FilesBodyItemImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  border-radius: 0.6rem;
  margin-bottom: 1.2rem;
  background-color: black;

  &[data-hasLogo='true'] {
    background-color: #28292d;
  }

  svg {
    position: absolute;
    height: 6.4rem;
    width: 6.4rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const FilesImageMp4 = styled.div`
  position: absolute;
  height: 6.4rem;
  width: 6.4rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 100%;
    height: 100%;
  }
`;

const ImageCover = styled.img`
  user-select: none;
  max-width: 100%;
  max-height: 100%;
`;

const ProgressLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 10rem;
  height: 100%;
  width: 100%;
  display: flex;
  color: white;
  font-size: 40px;
  align-items: center;
  flex: 1;
  justify-content: center;
  z-index: 11;
`;

const DragOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: inherit;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DragOverlayText = styled.div`
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #f7f8f8;
  margin-top: 1.6rem;
  text-align: center;
`;

const DropIconWrapper = styled.div`
  animation: bounce 1.5s ease-in-out infinite;

  @keyframes bounce {
    0% {
      transform: translateY(-0.2rem);
    }

    50% {
      transform: translateY(0.2rem);
    }

    100% {
      transform: translateY(-0.2rem);
    }
  }
`;

const CurrentVersionDisplay = styled.div`
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
  background-color: black;
  height: 2.8rem;
  width: 2.8rem;
  color: white;
  border-radius: 0.6rem;
  font-weight: 500;
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border: var(--color-grey-8) solid 1px;

  span {
    opacity: 0.7;
  }
`;

const BlockedUploadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 10;

  & span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 1.6rem;
    line-height: 1.9rem;
    font-weight: 700;
    z-index: 10;
    text-align: center;
    display: block;
  }
`;

const VideoDuration = styled.span`
  position: absolute;
  bottom: 0.8rem;
  right: 0.8rem;
  padding: 0.4rem;
  color: var(--color-grayscale-white);
  background: var(--color-grayscale-tuna);
  border-radius: 0.4rem;
`;

const LinkIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  color: var(--color-grayscale-white);
  padding: 1.6rem;
  background-color: #1d1f24;
  border-radius: 0.6rem;
  opacity: 75%;

  &[data-onTopOfPreviewImage='true'] {
    width: 6.4rem;
    height: 6.4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
`;
