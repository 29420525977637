import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';

import { Step, StepStatus } from '../../../app/entities/step';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import styled from 'styled-components';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import { useCurrentMember } from '../../../hooks/use-current-member';

interface StepStatusSelectorProps {
  stepId: string;
}

export const StepStatusSelector: React.FC<StepStatusSelectorProps> = observer(({ stepId }) => {
  const step = Step.getOne(stepId)!;
  const currentMember = useCurrentMember();

  const [isOpen, setIsOpen] = useState(false);

  const handleChangeStatus = async (newStatus: StepStatus) => {
    try {
      await step.update({ status: newStatus });
    } catch (e) {
      console.error(e);
    }
  };

  const onOpenChanged = (value: boolean) => {
    setIsOpen(value);
  };

  return currentMember?.role !== 'guest' ? (
    <DropdownMenu
      onOpenChange={onOpenChanged}
      items={Object.entries(Step.StatusMapper).map(([key, { title }]) => ({
        title,
        onSelect: () => handleChangeStatus(key as StepStatus),
        icon: <Dot data-status={key as StepStatus} />,
      }))}
      trigger={
        <SelectorTrigger data-status={step.status}>
          <Dot data-status={step.status} />
          <StatusTitle>{Step.StatusMapper[step.status].title}</StatusTitle>
          {isOpen ? <ChevronUpIcon color="#959BAB" /> : <ChevronDownIcon color="#959BAB" />}
        </SelectorTrigger>
      }
    />
  ) : (
    <SelectorTrigger data-status={step.status} disabled>
      <Dot data-status={step.status} />
      <StatusTitle>{Step.StatusMapper[step.status].title}</StatusTitle>
    </SelectorTrigger>
  );
});

export const Dot = styled.div`
  position: relative;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  border: 2px solid;
  border-color: var(--color-grayscale-white, #fafafa);

  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1rem;
    width: 1rem;
    transform: translate(-50%, -50%);
  }

  &[data-status='on-hold'] {
    border-color: var(--color-grayscale-light-slate, #7a8296);

    &:after {
      height: 0.4rem;
      width: 0.4rem;
      background: var(--color-grayscale-light-slate, #7a8296);
    }
  }

  &[data-status='in-progress'] {
    border-color: var(--color-primary-crayola);

    &:after {
      background: conic-gradient(var(--color-primary-crayola) 90deg, transparent 90deg);
    }
  }

  &[data-status='needs-review'] {
    border-color: var(--color-secondary-saffron, #f8a72d);

    &:after {
      background: conic-gradient(var(--color-secondary-saffron) 180deg, transparent 180deg);
    }
  }

  &[data-status='changes-requested'] {
    border-color: var(--color-secondary-roman, #db5c66);

    &:after {
      background: conic-gradient(var(--color-secondary-roman) 270deg, transparent 270deg);
    }
  }

  &[data-status='approved'] {
    border-color: var(--color-primary-puerto-rico);

    &:after {
      background: var(--color-primary-puerto-rico);
    }
  }
`;

const StatusTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  text-align: center;
  color: #fafafa;
  margin-right: 0.8rem;
  margin-left: 0.8rem;
`;

const SelectorTrigger = styled.button`
  position: relative;
  outline: none;
  display: flex;
  align-items: center;
  border-radius: 0.4rem;
  border-width: 1px;
  border-style: solid;
  padding: 0.8rem;
  height: 3.2rem;
  font-size: 1.4rem;

  &:not(:disabled) {
    cursor: pointer;
  }

  &[data-status='not-started'] {
    border-color: var(--color-grayscale-white, #fafafa);
    background-color: #fafafa33;
  }

  &[data-status='on-hold'] {
    border-color: var(--color-grayscale-light-slate, #7a8296);
    background-color: #7a829633;
  }

  &[data-status='in-progress'] {
    border-color: var(--color-primary-crayola, #367bff);
    background-color: #367bff33;
  }

  &[data-status='needs-review'] {
    border-color: var(--color-secondary-saffron, #f8a72d);
    background-color: #f8a72d33;
  }

  &[data-status='changes-requested'] {
    border-color: var(--color-secondary-roman, #db5c66);
    background-color: #db5c6633;
  }

  &[data-status='approved'] {
    border-color: var(--color-primary-puerto-rico, #38c2b8);
    background-color: #38c2b833;
  }
`;
