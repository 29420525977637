import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ShareLinkItem } from './share-link-item';
import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';
import { ListViewHeader } from '../../../components/list-view/list-view';
import { useCurrentProject } from 'hooks/use-current-project';

export const ShareLinkList = observer(() => {
  const project = useCurrentProject()!;

  if (project.shareLinks.length === 0) {
    return (
      <PagePlaceholder
        title="No files or links shared yet."
        description="You can share multiple items for viewing, commenting or downloading from inside the file's step."
      />
    );
  }

  return (
    <Table>
      <ListViewHeader>
        <HeaderCell>Name</HeaderCell>
        <HeaderCell>Creation date</HeaderCell>
        <HeaderCell>Number of items</HeaderCell>
        <HeaderCell>Creator</HeaderCell>
        <HeaderCell>Allow download</HeaderCell>
        <HeaderCell />
      </ListViewHeader>

      {project.shareLinks.length > 0 && (
        <TableBody>
          {project.shareLinks.map((link) => (
            <ShareLinkItem key={link._id} link={link} />
          ))}
        </TableBody>
      )}
    </Table>
  );
});

const Table = styled.table`
  width: 100%;
`;

const TableBody = styled.tbody``;

const HeaderCell = styled.th`
  color: var(--color-grayscale-shuttle);
  font-size: 1.4rem;
  font-weight: 400;
  text-align: left;
  width: 1%;
  white-space: nowrap;

  &:nth-child(4) {
    width: 100%;
  }

  &:not(:last-child) {
    padding-right: 9.6rem;
  }
`;
