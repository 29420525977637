import React from 'react';
import styled from 'styled-components';

import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';

interface Props {
  onAddCharacterClicked: () => void;
}

export const CharacterListPlaceholder: React.FC<Props> = ({ onAddCharacterClicked }) => (
  <Container>
    <PagePlaceholder
      backgroundImgUrl="/images/Process_Badges_Pre_Production.svg"
      description='Characters will be displayed here, you can add them by clicking on the "Create
              character" button'
      actionButton={{ text: 'Create character', onClick: onAddCharacterClicked }}
    />
  </Container>
);

const Container = styled.div`
  flex: 1;
  padding: 0 2.4rem 2.4rem;
  height: 100%;
`;
