import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChatBubbleIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { useLiveChat } from '../hooks/use-livechat';
import { Tooltip } from '../../components/tooltip/Tooltip';
import { ElevatedButton } from '../../components/buttons';
import { TabBar } from './tab-bar';
import { keycloak } from '../../keycloak';
import { breakPoint } from '../theme';
import { Space } from '../entities/space';
import { MobileHeader } from './mobile-header';
import { authStore } from '../../core/stores/auth-store';
import { NotificationCenter } from './notification-center';
import { DropdownMenu } from '../../components/modals/dropdown-menu/DropdownMenu';

type AppHeaderProps = {
  space?: Space;
  hasBanner?: boolean;
};

export const AppHeader: React.FC<AppHeaderProps> = observer(({ space, hasBanner = false }) => {
  const navigate = useNavigate();
  const currentUser = authStore.currentUser;

  /**
   * Load the live chat into the application
   */
  const { toggleChat, unreadMessages } = useLiveChat();

  const handleToSettingPage = () => {
    if (!space) return;
    navigate(`/space/${space._id}/account`, { state: { prevPath: location.pathname } });
  };

  const handleGoToPlansPage = () => {
    if (!space?.flags?.['space.settings']) {
      return;
    }

    navigate(`/space/${space._id}/settings/plans`);
  };

  const menuItems = [
    {
      title: 'My settings',
      onSelect: handleToSettingPage,
    },
    {
      title: 'Log out',
      type: 'danger' as const,
      onSelect: () => {
        const url = keycloak.createLogoutUrl({
          redirectUri: window.location.origin,
        });
        window.location.href = url;
      },
    },
  ];

  return (
    <Header>
      <Left>
        <TabBar />
      </Left>

      <MobileHeader hasSpace={!!space} />

      <Right>
        {space && !space.hasSubscription() && space.flags?.['space.settings'] && (
          <UpgradeButtonWrapper>
            <ElevatedButton text="Upgrade plan" onClick={handleGoToPlansPage} />
          </UpgradeButtonWrapper>
        )}

        <NotificationCenter />

        <Tooltip text={'Chat with us'} position={'bottom'}>
          <ChatToggle id="chat-toggle" onClick={toggleChat}>
            <ChatBubbleIcon />

            {unreadMessages > 0 && <span />}
          </ChatToggle>
        </Tooltip>

        <div style={{ position: 'relative' }}>
          <DropdownMenu
            items={menuItems}
            trigger={
              <div style={{ cursor: 'pointer' }}>
                <Avatar src={currentUser?.avatar} initials={currentUser?.initials} size="small" />
              </div>
            }
          />
        </div>
      </Right>
    </Header>
  );
});

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: var(--tab-bar-height);
  min-height: var(--tab-bar-height);
  width: calc(100%);
  background-color: var(--header-bg-color);
  z-index: 1001;
  border-bottom: 1px solid var(--color-grayscale-tuna);
  background: var(--color-grayscale-eerie-black);

  @media screen and (min-width: ${breakPoint.medium}px) {
    background: black;
    padding-inline-end: 1.6rem;
    border-bottom: none;
  }
`;

const Right = styled.div`
  display: none;
  align-items: center;
  gap: 1.6rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
  }
`;

const Left = styled.div`
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ChatToggle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: var(--border-radius-small);
  color: white;
  cursor: pointer;
  transition: background 0.2s ease;

  svg {
    color: #858699;
    height: 2rem;
    width: 2rem;
  }

  &:hover {
    svg {
      color: white;
    }
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    background: var(--color-primary);
    width: 1rem;
    height: 1rem;
    font-size: 0.6rem;
    border-radius: 50%;
  }
`;

const UpgradeButtonWrapper = styled.div`
  button {
    background: #e6057699 !important;
    box-shadow: inset 0 0 0 2px #e60576;
    transition: all 300ms ease;

    &:hover {
      background: #e60576 !important;
    }
  }
`;
