import React from 'react';
import { useResponsive } from 'hooks/useResponsive';
import styled from 'styled-components';

import { breakPoint } from '../../../app/theme';
import { TimeFrame } from './timeframe';

interface ScreenProps {
  title: string;
  dueDate?: string;
  startDate?: string;
  onUpdate: (values: { dueDate?: string; startDate?: string }) => void;
}

export const HeaderLeft: React.FC<ScreenProps> = ({ title, dueDate, startDate, onUpdate }) => {
  const { isDesktop } = useResponsive();

  return (
    <Left>
      <ScreenTitle>{title}</ScreenTitle>

      {isDesktop && (
        <StepDeadLine>
          <TimeFrame dueDate={dueDate} startDate={startDate} onUpdate={onUpdate} />
        </StepDeadLine>
      )}
    </Left>
  );
};

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ScreenTitle = styled.h1`
  color: var(--color-grayscale-white);
  font-family: Inter, sans-serif;
  text-transform: capitalize;
  font-size: 1.4rem;
  font-weight: 700;

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.4rem;
    font-weight: 500;
    color: #e3e4e7;
  }
`;

const StepDeadLine = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2.4rem;
`;
