import { Asset } from './asset';

export class Folder extends Asset {
  constructor(parentId: string) {
    super();

    this.type = 'folder';
    this.name = 'New folder';
    this.parentId = parentId;
  }
}
