import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { VideoToCompare } from './components/video-compare/video-to-compare';
import { CompareContext } from './components/video-compare/compare.context';
import { CompareProvider } from './components/video-compare/compare.provider';
import { ChooseVideoVersion } from './components/video-compare/choose-video-version';

import type { Asset } from '../../app/entities/asset';

type VideoAssetProps = {
  asset: Asset;
};

export const VideoCompareScreen: React.FC<VideoAssetProps> = observer(({ asset }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [v1Id, setV1Id] = useState<string>();
  const [v2Id, setV2Id] = useState<string>();

  useEffect(() => {
    if (!searchParams) {
      return;
    }

    const searchParamV1Id = searchParams.get('v1');
    const searchParamV2Id = searchParams.get('v2');

    if (searchParamV1Id) {
      setV1Id(searchParamV1Id);
    }

    if (searchParamV2Id) {
      setV2Id(searchParamV2Id);
    }
  }, [searchParams]);

  const handleSelectVersion = (id: 'v1' | 'v2', versionId: string) => {
    if (id === 'v1') {
      setV1Id(versionId);
    } else {
      setV2Id(versionId);
    }

    setSearchParams((searchParams) => {
      searchParams.set(id, versionId);
      return searchParams;
    });
  };

  return (
    <CompareProvider>
      {asset.children.length && (
        <CompareContext.Consumer>
          {({ player1Ref, player2Ref }) => (
            <Container>
              {v1Id ? (
                <VideoToCompare
                  key={`v1-${v1Id}`}
                  id="v1"
                  ref={player1Ref}
                  selectedAssetId={v1Id}
                  onSelectVersion={handleSelectVersion}
                  assetChildren={asset.children}
                />
              ) : (
                <ChooseVideoVersionContainer>
                  <ChooseVideoVersion
                    versions={asset.children.filter((child) => child._id !== v2Id)}
                    onSelect={(assetId: string) => handleSelectVersion('v1', assetId)}
                  />
                </ChooseVideoVersionContainer>
              )}

              {v2Id ? (
                <VideoToCompare
                  key={`v2-${v2Id}`}
                  id="v2"
                  ref={player2Ref}
                  selectedAssetId={v2Id}
                  onSelectVersion={handleSelectVersion}
                  assetChildren={asset.children}
                />
              ) : (
                <ChooseVideoVersionContainer>
                  <ChooseVideoVersion
                    versions={asset.children.filter((child) => child._id !== v1Id)}
                    onSelect={(assetId: string) => handleSelectVersion('v2', assetId)}
                  />
                </ChooseVideoVersionContainer>
              )}
            </Container>
          )}
        </CompareContext.Consumer>
      )}
    </CompareProvider>
  );
});

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  width: 100%;
  background: var(--color-grey-9);

  // Border between the two videos
  &:before {
    content: '';
    position: absolute;
    background-color: #7a829666;
    align-self: stretch;
    width: 1px;
    height: 100%;
    z-index: 1;
    left: 50%;
  }
`;

const ChooseVideoVersionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
