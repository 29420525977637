import { useEffect, useRef } from 'react';

export const useOnClickOutside = (handler: (e: Event) => void) => {
  const ref = useRef<any>();

  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mouseup', listener);
    document.addEventListener('touchend', listener);

    return () => {
      document.removeEventListener('mouseup', listener);
      document.removeEventListener('touchend', listener);
    };
  }, [ref, handler]);

  return {
    ref,
  };
};
