import React from 'react';
import { useParams } from 'react-router-dom';

import { VideoCompareScreen } from './video-compare.screen';
import { Asset } from '../../app/entities/asset';

export const VideoComparePage: React.FC = () => {
  const { assetId } = useParams();

  const asset = Asset.getOne(assetId!)!;

  return <>{asset && <VideoCompareScreen asset={asset!} />}</>;
};
