import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { ChevronRightIcon } from '@radix-ui/react-icons';

import { Header } from './project.page';
import { Location } from '../../app/entities/location';
import { FolderView } from 'features/assets/components/folder-view';
import { uiStore } from '../../core/stores/ui-store';

export const ProjectLocationRoute = () => {
  const { locationId } = useParams() as { locationId: string };

  const location = Location.getOne(locationId)!;

  useEffect(() => {
    uiStore.currentLocation = 'project.locations';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <>
      <Header>
        <Crumb>
          <Parent to="../">Locations</Parent>
          <ChevronRightIcon />
          {location.name}
        </Crumb>
      </Header>

      <FolderView folderId={location.assetsFolderId || ''} isRoot />
    </>
  );
};

const Crumb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.4rem;
`;

const Parent = styled(Link)`
  color: #e3e4e7;

  &:hover {
    color: white;
  }
`;
