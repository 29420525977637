import { v4 as uuid } from 'uuid';

import { api } from '../../../api';
import { Asset } from '../../../app/entities/asset';
import { uploadFile } from './uploads.slice';
import { loadPdfData } from '../../../lib/utils/load-pdf-data';
import { entityPool } from '../../../core/engine/engine';
import { Upload } from '../../../app/entities/upload';

/**
 * Create asset
 **/
type CreateAssetInputBase = {
  parentId: string;
  type: string;
  name?: string;
  fileType?: string;
  index?: number;
  key?: string;
  size?: number;
  file?: File;
  duration?: number;
  uploaded?: boolean;
  pages?: number;
  uploadId?: string;
  url?: string;
  content?: string;
};

export const createAsset = async ({
  file,
  key,
  ...input
}: CreateAssetInputBase): Promise<{ statusCode: number; asset: Asset | null }> => {
  try {
    /**
     * First create the asset in the API
     */
    let uploadId: string | undefined;

    if (file) {
      uploadId = uuid();
      input.uploadId = uploadId;
    }

    let { data } = await api.post('/assets', input);

    if (data.asset) {
      data = data.asset;
    }

    const asset: Asset = Object.assign(new Asset(), data);

    /**
     * Trigger a new upload if we have a file
     */
    if (file && data.links?.upload && uploadId) {
      const upload = new Upload(uploadId, data._id, data.links.upload);
      entityPool.insert(upload);

      uploadFile({
        upload,
        assetId: data._id,
        file: file,
      });
    }

    if (file && (file.type === 'video/mp4' || file.type === 'video/quicktime')) {
      new Promise<{ duration: number; width: number; height: number }>((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          const duration = video.duration;
          const width = video.videoWidth;
          const height = video.videoHeight;
          resolve({ duration, width, height });
        };

        video.onerror = function (err) {
          reject(err);
        };

        video.src = URL.createObjectURL(file);
      }).then(({ duration, height, width }) => {
        if (!asset.meta) asset.meta = {};
        asset.update({
          meta: {
            ...asset.meta,
            duration,
            width,
            height,
          },
        });
        asset.meta.duration = duration;
        asset.meta.width = width;
        asset.meta.height = height;
      });
    }

    if (file && file.type === 'application/pdf') {
      loadPdfData(file).then((pages) => {
        if (!pages) return;
        if (!asset.meta) asset.meta = {};
        asset.update({ meta: { ...asset.meta, pages } });
        asset.meta.pages = pages;
      });
    }

    /**
     * Return the asset before the upload is finished, so we can display it
     */
    entityPool.insert(asset);
    return { statusCode: 200, asset };
  } catch (error: any) {
    return { statusCode: error?.response?.status, asset: null };
  }
};
