import React from 'react';
import { useParams } from 'react-router-dom';

import { CastBreadcrumb } from '../components/cast-breadcrumb';
import { ScreenHeader } from '../../projects/components/screen-header';
import { CandidatesTab } from '../components/candidates-tab';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { PageLoader } from '../../../app/components/page-loader';

import type { ScreenProps } from '../../projects/models/types';

type CharacterDetailScreenProps = ScreenProps & {
  spaceId: string;
};

export const CharacterDetailScreen: React.FC<CharacterDetailScreenProps> = ({
  step,
  spaceId,
  isLoading,
}) => {
  const { characterId } = useParams() as { characterId: string };

  return (
    <React.Fragment>
      <ScreenHeader
        breadcrumb={<CastBreadcrumb characterId={characterId!} />}
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        canValidateStep={false}
      />

      {isLoading ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : (
        <CandidatesTab characterId={characterId} spaceId={spaceId} />
      )}
    </React.Fragment>
  );
};
