import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { useCurrentProject } from '../../hooks/use-current-project';
import { Header } from './project.page';
import { ListView, ListViewHeader, ListViewItem } from '../../components/list-view/list-view';
import { ArrowDownIcon, ArrowUpIcon } from '@radix-ui/react-icons';
import { HeaderCell } from 'features/projects/components/ProjectList';
import { sortBy } from '../../features/settings/helpers/sortBy';
import { uiStore } from '../../core/stores/ui-store';
import { ElevatedButton } from '../../components/buttons';
import { Storyboard } from 'app/entities/storyboard';
import { observer } from 'mobx-react-lite';

export const ProjectStoryboardsRoute = observer(() => {
  const project = useCurrentProject()!;
  const [orderBy, setOrderBy] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    uiStore.currentLocation = 'project.storyboards';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  const order = orderBy[0] === '-' ? 'asc' : 'desc';
  const storyboards = sortBy(
    project.storyboards,
    !orderBy ? 'createdAt' : orderBy.replace('-', ''),
    order,
  );

  const handleOrder = (value: string) => {
    const currentOrder = orderBy[0] === '-' ? 'desc' : 'asc';
    const current = orderBy.replace('-', '');

    /**
     * 1. If it's not the same as current ordering, set it to desc
     * 2. If it's the same and it's already desc, set it to asc
     * 3. If it's the same and it's already asc, set it to default
     */

    if (value !== current) {
      setOrderBy(`-${value}`);
    } else if (currentOrder === 'desc') {
      setOrderBy(value);
    } else {
      setOrderBy('');
    }
  };

  const handleOpen = (storyboardId: string) => {
    const href = `./${storyboardId}`;
    navigate(href);
  };

  const handleCreateStoryboard = () => {
    const storyboard = new Storyboard();
    storyboard.projectId = project._id;
    storyboard.save();
  };

  return (
    <>
      <Header>
        <div>Storyboards</div>

        <ElevatedButton text="Create storyboard" onClick={handleCreateStoryboard} />
      </Header>

      <Container>
        <ListView>
          <ListViewHeader>
            <HeaderCell onClick={() => handleOrder('name')}>
              Name
              {orderBy === '-name' && <ArrowDownIcon />}
              {orderBy === 'name' && <ArrowUpIcon />}
            </HeaderCell>
          </ListViewHeader>

          <tbody>
            {storyboards.map((storyboard, index) => (
              <ListViewItem onClick={() => handleOpen(storyboard._id)}>
                <td style={{ width: '100%' }}>{storyboard.name || `Storyboard ${index + 1}`}</td>
              </ListViewItem>
            ))}
          </tbody>
        </ListView>
      </Container>
    </>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;
