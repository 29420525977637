import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { LineHeightIcon, DownloadIcon, Pencil1Icon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { EyeClosedIcon, EyeOpenIcon } from '../../../components/icons';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { RenumberShotsDialog } from './renumber-shots.dialog';
import { ScreenHeader } from '../../projects/components/screen-header';
import { ElevatedButton } from '../../../components/buttons';
import { downloadShotList } from '../services/shot-list.service';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { useResponsive } from '../../../hooks/useResponsive';

import type { Step } from '../../../app/entities/step';
import type { Project } from '../../../app/entities/project';
import { LimitReachedModal } from '../../../components/dialogs/limit-reached.modal';
import { useCurrentMember } from '../../../hooks/use-current-member';

type ShotListHeaderProps = {
  step: Step;
  project: Project;
  shotCount: number;
};

export const ShotListHeader: React.FC<ShotListHeaderProps> = observer(
  ({ step, project, shotCount }) => {
    const { isDesktop } = useResponsive();

    const currentMember = useCurrentMember();
    const userStepPreferences = currentMember?.preferences.stepsUI[step._id];

    const [generatingPDF, setGeneratingPDF] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isRenumberModalOpen, setIsRenumberModalOpen] = useState(false);
    const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);

    const stripboard = project.stripboard;

    const handleDownload = async () => {
      if (!project?.space?.canExportPdf()) {
        return setShowLimitReachedModal(true);
      }

      if (generatingPDF || !shotCount) {
        return;
      }

      setGeneratingPDF(true);

      await downloadShotList(project._id, project.name);

      setGeneratingPDF(false);
    };

    const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
      {
        title:
          userStepPreferences?.showHiddenShots === false
            ? 'Show Hidden Shots'
            : 'Hide Hidden Shots',
        onSelect: () => currentMember?.toggleShowHiddenShots(step._id),
        icon: userStepPreferences?.showHiddenShots === false ? <EyeClosedIcon /> : <EyeOpenIcon />,
      },
      {
        title: 'Renumber Shots',
        onSelect: () => setIsRenumberModalOpen(true),
        icon: <LineHeightIcon />,
      },
      ...(!isDesktop
        ? [
            {
              title: 'Generate PDF',
              icon: <DownloadIcon />,
              onSelect: handleDownload,
              disabled: generatingPDF || !shotCount,
            },
          ]
        : []),
      {
        title: 'Rename step',
        icon: <Pencil1Icon />,
        onSelect: () => setIsRenameModalOpen(true),
      },
    ];

    return (
      <>
        <ScreenHeader
          stepId={step._id}
          title={step.name}
          dueDate={step.dueDate}
          startDate={step.startDate}
          handleUpdateTimeFrame={(values) => step.update(values)}
          menu={<DropdownMenu items={items} size={'large'} />}
          actions={
            isDesktop && stripboard
              ? [
                  <ActionsButtonWrapper key="generate-pdf">
                    <ElevatedButton
                      onClick={handleDownload}
                      text="Generate PDF"
                      isLoading={generatingPDF}
                      disabled={!shotCount}
                    />
                  </ActionsButtonWrapper>,
                ]
              : []
          }
        />

        {isRenumberModalOpen && (
          <RenumberShotsDialog
            stripboard={stripboard}
            onCancel={() => setIsRenumberModalOpen(false)}
          />
        )}

        {isRenameModalOpen && (
          <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
        )}

        {showLimitReachedModal && (
          <LimitReachedModal
            title="Upgrade your space to export your shot list as a PDF"
            eventName="Export shotlist attempt"
            onCancel={() => setShowLimitReachedModal(false)}
          />
        )}
      </>
    );
  },
);

const ActionsButtonWrapper = styled.div`
  min-width: 13.6rem;
`;
