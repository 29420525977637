import styled from 'styled-components';
import { breakPoint } from '../../app/theme';

export const AssetGrid = styled.div`
  display: grid;
  grid-gap: 0.8rem;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr;

  @media screen and (min-width: ${breakPoint.tiny}px) {
    grid-template-columns: var(--grid-columns-2);
  }

  @media screen and (min-width: ${breakPoint.small}px) {
    grid-template-columns: var(--grid-columns-3);
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    grid-template-columns: var(--grid-columns-3);
    grid-gap: 1.2rem;
  }

  @media screen and (min-width: ${breakPoint.large}px) {
    grid-template-columns: var(--grid-columns-4);
  }

  @media screen and (min-width: ${breakPoint.xlarge}px) {
    grid-template-columns: var(--grid-columns-5);
  }

  @media screen and (min-width: ${breakPoint.xxlarge}px) {
    grid-template-columns: var(--grid-columns-6);
  }

  @media screen and (min-width: ${breakPoint.xxxlarge}px) {
    grid-template-columns: var(--grid-columns-7);
  }
`;

export const CollaboratorsGrid = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  padding: 1.6rem;

  @media screen and (min-width: 420px) {
    grid-template-columns: var(--grid-columns-2);
  }

  @media screen and (min-width: 750px) {
    grid-template-columns: var(--grid-columns-3);
  }

  @media screen and (min-width: 1124px) {
    grid-template-columns: var(--grid-columns-4);
  }

  @media screen and (min-width: 1440px) {
    grid-template-columns: var(--grid-columns-5);
  }

  @media screen and (min-width: 1920px) {
    grid-template-columns: var(--grid-columns-6);
  }
`;
