import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormBuilderModal } from '../../../components/form-builder-modal/form-builder-modal';
import { LocationCreateDto } from '../../../dto/location/location-create.dto';
import { createProjectLocation } from '../location.slice';
import { useCurrentProject } from 'hooks/use-current-project';
import { buildLocationForm } from './location-form.definition';

type CreateLocationDrawerProps = {
  onCancel: () => void;
};

export const CreateLocationDrawer: React.FC<CreateLocationDrawerProps> = ({ onCancel }) => {
  const { t } = useTranslation('location');
  const project = useCurrentProject()!;

  const handleSubmitLocation = async (data: LocationCreateDto) => {
    createProjectLocation({
      projectId: project._id,
      ...data,
    });
  };

  const createLocationFormDefinition = buildLocationForm(true, t);

  return (
    <FormBuilderModal
      model="location"
      definition={createLocationFormDefinition}
      onSubmit={handleSubmitLocation}
      onCancel={onCancel}
    />
  );
};
