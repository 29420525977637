import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';

import { LoaderContainer } from '../project/components/step-container';
import { PageLoader } from '../../components/page-loader/PageLoader';
import { useNavigate, useParams } from 'react-router-dom';
import { Space } from '../../app/entities/space';
import { dateShort } from '../../assets/contants/dates-fns';
import { SettingsTable } from '../../components/settings-table/settings-table';
import { Cell, Row } from '../../components/settings-table/styled-table';
import { SettingScreenLayout } from '../../features/settings/components/setting-screen-layout';
import { api } from '../../api';
import { uiStore } from '../../core/stores/ui-store';

type BillingHistoryProps = {
  isLoading?: boolean;
};

const tableHeaders = ['Date', 'Description', 'Invoice total', 'Status'];

export const BillingHistoryRoute: React.FC<BillingHistoryProps> = observer(({ isLoading }) => {
  const navigate = useNavigate();
  const { spaceId } = useParams() as { spaceId: string };
  const space = Space.getOne(spaceId)!;

  useEffect(() => {
    uiStore.showBack = true;

    uiStore.currentLocation = 'space.settings';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  useEffect(() => {
    space?.getBillingHistory();
  }, [space]);

  const openInvoice = (transactionId: string) => {
    api.get(`/spaces/${space._id}/transactions/${transactionId}/invoice`).then((response) => {
      window.open(response.data.links.invoice, '_blank');
    });
  };

  return (
    <SettingScreenLayout title="Billing history" onBack={() => navigate(-1)}>
      {isLoading ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : (
        <SettingsTable headers={tableHeaders}>
          {space.transactions?.map((transaction) => (
            <Row key={transaction.transactionId}>
              <Cell>{format(new Date(transaction.date), dateShort)}</Cell>
              <Cell>{transaction.description} </Cell>
              <Cell>{transaction.amount}</Cell>
              <Cell>{transaction.status === 'completed' ? 'Completed' : 'Past due'}</Cell>
              {transaction.hasInvoice && (
                <ActionContainer>
                  <Action onClick={() => openInvoice(transaction.transactionId)}>
                    View invoice
                  </Action>
                </ActionContainer>
              )}
            </Row>
          ))}
        </SettingsTable>
      )}
    </SettingScreenLayout>
  );
});

const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
  margin: 1.6rem 0;
`;

const Action = styled.button`
  all: unset;

  color: var(--color-primary-crayola);
  font-size: 1.4rem;
  font-weight: 400;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;
