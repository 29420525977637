import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ProjectCollaboratorModal } from '../modals/project-collaborator.modal';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import {
  addProjectCollaborator,
  fetchProjectCollaborators,
} from '../../collaborators/slices/collaborator.slice';
import { Project } from '../../../app/entities/project';
import { breakPoint } from '../../../app/theme';
import { toastPool } from '../../toasts/models/toast-pool';
import { Toast } from '../../toasts/models/toast';
import { fetchInvitations } from '../../guest-invitation/store/guest-invitation.slice';

import type { RoleTypes } from './role-dropdown';
import { CollaboratorAvatar } from '../../../components/collaborators-list/CollaboratorAvatar';
import { IconButton } from '../../../components/buttons';
import { UserPlusIcon } from '../../../components/icons/user-plus.icon';
import { IconButtonWrapper } from '../../../components/buttons/icon-button';
import { useCurrentMember } from '../../../hooks/use-current-member';

interface ProjectCollaboratorsProps {
  project: Project;
  spaceId: string;
  collapsible?: boolean;
}

export const ProjectCollaborators: React.FC<ProjectCollaboratorsProps> = observer(
  ({ project, spaceId, collapsible }) => {
    const currentMember = useCurrentMember();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDropDownOpen, setIsModalDropDownOpen] = useState(false);
    const [serverError, setServerError] = useState<string>();

    useEffect(() => {
      /** TODO Should be done during bootstrapping **/
      fetchProjectCollaborators(project._id);
    }, [project._id]);

    useEffect(() => {
      if (['owner', 'admin'].includes(currentMember?.role || '')) {
        fetchInvitations(spaceId);
      }
    }, [currentMember?.role, spaceId]);

    const handleSubmit = async (email: string, role?: RoleTypes) => {
      try {
        await addProjectCollaborator({
          projectId: project._id,
          email,
          ...(role && role !== 'guest' && { role }),
          grantAccess: true,
        });
        setIsModalOpen(false);

        toastPool.insert(new Toast(`Successfully invited ${email} to the project`, 'success'));
        setServerError(undefined);
      } catch (error) {
        setServerError('Something went wrong. Please try again');
      }
    };

    const handleOpen = async (openDropDown: boolean) => {
      setIsModalOpen(true);
      setIsModalDropDownOpen(openDropDown);
    };

    return (
      <Collaborators>
        <CollaboratorsList>
          {project.collaborators.slice(0, 5).map((collaborator, key) => (
            <CollaboratorAvatar
              key={key}
              onClick={() => handleOpen(true)}
              collaborator={collaborator as any}
              tooltip={true}
              collapsible={collapsible}
            />
          ))}

          {project.collaborators.length - 5 > 0 && (
            <MoreCollaborator onClick={() => handleOpen(true)}>
              <More>{`+${project.collaborators.length - 5}`}</More>
            </MoreCollaborator>
          )}
        </CollaboratorsList>

        {['owner', 'admin', 'creator'].includes(currentMember?.role || '') && (
          <Tooltip text="Invite guests/creators to project" position="bottom">
            <CustomIconButtonWrapper>
              <IconButton
                id="add-collaborator"
                icon={<UserPlusIcon />}
                variant="primary"
                onClick={() => handleOpen(false)}
              />
            </CustomIconButtonWrapper>
          </Tooltip>
        )}

        {isModalOpen && (
          <ProjectCollaboratorModal
            serverError={serverError}
            setServerError={setServerError}
            onSubmit={handleSubmit}
            collaborators={project.collaborators}
            onCancel={() => setIsModalOpen(false)}
            isOpenProps={isModalDropDownOpen}
            spaceId={spaceId}
            currentMemberRole={currentMember?.role || ''}
          />
        )}
      </Collaborators>
    );
  },
);

const Collaborators = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const CollaboratorsList = styled.div`
  display: flex;
  position: relative;
  max-width: calc(100% - 7.2rem);
  margin-inline-end: 1.2rem;
  gap: 0.4rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    flex-direction: row;
    max-width: unset;
  }
`;

const MoreCollaborator = styled.div`
  height: 3.2rem;
  width: 3.2rem;
  border-radius: var(--border-radius-medium);
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: var(--color-grayscale-arsenic);
  z-index: 999;

  &:hover {
    cursor: pointer;
    z-index: 99999;
    transform: scale(1.05);
    transition: all 0.1s ease-in;
  }
`;

const More = styled.div`
  font-weight: 400;
  padding-top: 0.3rem;
  padding-bottom: 3px;
  font-size: 1.4rem;
  user-select: none;
  text-transform: uppercase;
`;

const CustomIconButtonWrapper = styled(IconButtonWrapper)`
  svg {
    height: 2.2rem;
    width: 2.2rem;
  }
`;
