import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CommentCheckbox } from './CommentCheckbox';
import { CommentsList } from './CommentsList';
import { AddComment } from './AddComment';
import { api } from '../../api';
import { BufferToBlob } from '../../lib/utils/BufferToBolb';
import { isVideo } from '../../core/services/file.service';
import { breakPoint } from '../../app/theme';
import { DropdownMenu } from '../modals/dropdown-menu/DropdownMenu';
import { useResponsive } from 'hooks/useResponsive';

import type { Comment } from '../../app/entities/comment';
import type { Asset } from '../../app/entities/asset';

interface CommentsProps {
  playerRef: React.RefObject<HTMLVideoElement | null>;
  timeTagRef: React.RefObject<HTMLSpanElement>;
  asset: Asset;
  showHeader?: boolean;
  pdfCurrentPage?: number;
  defaultShowCompleted?: boolean;
  onSelectComment?: (comment: Pick<Comment, 'timestamp' | 'page'>) => Promise<void>;
}

export const Comments: React.FC<CommentsProps> = observer(
  ({
    asset,
    playerRef,
    timeTagRef,
    pdfCurrentPage,
    showHeader = true,
    defaultShowCompleted,
    onSelectComment,
  }) => {
    const { t } = useTranslation('project');
    const { isDesktop } = useResponsive();

    const [showCompleted, setShowCompleted] = useState(!!defaultShowCompleted);

    const mimeType = asset.fileType ? asset.fileType.split('/')[0] : 'unknown';

    const handleShowCompleted = () => {
      setShowCompleted(!showCompleted);
    };

    const handleDownloadResolve = async () => {
      const { data } = await api.get(`/assets/${asset._id}/comments`, {
        headers: {
          Accept: 'text/edl',
        },
      });

      BufferToBlob(data, `${asset.name}.edl`);
    };

    const handleAddComment = async (
      comment: Pick<Comment, 'text' | 'timestamp' | 'page' | 'annotations'>,
    ): Promise<string> => {
      const commentId: string = await asset.addComment(comment);

      return commentId;
    };

    return (
      <Container>
        {showHeader && (
          <>
            <Header>
              <Title>{asset.commentCount} comments</Title>

              {isDesktop && !!asset.fileType && isVideo(asset.fileType) && (
                <DropdownMenu
                  key="download-file"
                  items={[
                    {
                      title: 'Download for Resolve',
                      onSelect: handleDownloadResolve,
                      icon: null,
                    },
                  ]}
                  size={'small'}
                />
              )}

              {!isDesktop && (
                <IsCompletedLabel>
                  {t('common:show_completed')}

                  <CommentCheckbox checked={showCompleted} onClick={handleShowCompleted} />
                </IsCompletedLabel>
              )}
            </Header>

            {isDesktop && (
              <Header>
                <div />
                <IsCompletedLabel>
                  {t('common:show_completed')}

                  <CommentCheckbox checked={showCompleted} onClick={handleShowCompleted} />
                </IsCompletedLabel>
              </Header>
            )}
          </>
        )}

        <CommentsList showCompleted={showCompleted} onSelectComment={onSelectComment} />

        <AddComment
          onAddComment={handleAddComment}
          mimeType={mimeType}
          playerRef={playerRef}
          pdfCurrentPage={pdfCurrentPage}
          timeTagRef={timeTagRef}
        />
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 0;
  height: 100%;
  min-width: 0;
  width: 100%;
  scroll-behavior: smooth;
  border-left: 1px var(--content-border-color) solid;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 1.2rem;

    &:first-child {
      border-bottom: 0.1rem solid var(--color-grayscale-trout);
    }
  }
`;

const Title = styled.h6`
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--color-grayscale-white);

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.4rem;
  }
`;

const IsCompletedLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: var(--color-grayscale-manatee);

  @media screen and (min-width: ${breakPoint.medium}px) {
    font-size: 1.4rem;
  }
`;
