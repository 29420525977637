import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { Header } from './project.page';
import { useCurrentProject } from '../../hooks/use-current-project';
import { uiStore } from '../../core/stores/ui-store';

import { FolderView } from '../../features/assets/components/folder-view';

export const ProjectDocumentsRoute = observer(() => {
  const project = useCurrentProject()!;

  useEffect(() => {
    uiStore.currentLocation = 'project.documents';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <>
      <Header>Documents</Header>

      <FolderView folderId={project.documentsFolderId} isRoot />
    </>
  );
});
