import React from 'react';
import styled from 'styled-components';

import { CharacterItem } from './character-item';
import { AssetGrid } from '../../../components/grid';

import type { Character } from '../../../app/entities/character';

type CharacterCardsProps = {
  characters: Character[];
};

export const CharacterCards: React.FC<CharacterCardsProps> = ({ characters }) => (
  <Container>
    {characters?.map((character) => (
      <CharacterItem key={character._id} character={character} />
    ))}
  </Container>
);

const Container = styled(AssetGrid)`
  padding: 1.6rem;
`;
