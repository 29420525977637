import React, { ReactNode } from 'react';
import styled from 'styled-components';
import * as RadixMenu from '@radix-ui/react-dropdown-menu';

import { MenuItem, MenuItemIcon } from '../modals/dropdown-menu/DropdownMenu';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { Tooltip } from '../tooltip/Tooltip';

type Item = {
  onSelect: (event: Event) => void;
  title: ReactNode;
  icon?: ReactNode;
  type?: 'danger' | 'warning';
  disabled?: boolean;
  tooltipText?: string;
};

type AddBlockButtonProps = {
  id?: string;
  text: string;
  items: Item[];
  onClick?: () => void;
  icon?: ReactNode;
};

export const SplitButton: React.FC<AddBlockButtonProps> = ({ id, text, icon, items }) => (
  <RadixMenu.Root>
    <RadixMenu.Trigger asChild>
      <ButtonContainer id={id}>
        <Button>
          {icon}
          {text}
        </Button>

        <OpenSelectButton>
          <ChevronDownIcon />
        </OpenSelectButton>
      </ButtonContainer>
    </RadixMenu.Trigger>

    <RadixMenu.Portal>
      <RadixMenu.Content asChild align={'end'} sideOffset={6}>
        <MenuModal>
          {items.map((item, key) => (
            <Tooltip
              key={key}
              disabled={!item.disabled || !item.tooltipText}
              text={item.tooltipText || 'Action not allowed'}
              position="left"
            >
              <MenuItem
                key={key}
                onSelect={(e: Event) => {
                  if (item.disabled) return;
                  item.onSelect(e);
                }}
                disabled={item.disabled}
                data-type={item.type}
              >
                <MenuItemIcon>{item.icon}</MenuItemIcon>

                <span>{item.title}</span>
              </MenuItem>
            </Tooltip>
          ))}
        </MenuModal>
      </RadixMenu.Content>
    </RadixMenu.Portal>
  </RadixMenu.Root>
);

const Button = styled.button`
  display: flex;
  gap: 0.8rem;
  min-height: 3.2rem;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-secondary);
  border-radius: 0.5rem;
  color: white;
  cursor: pointer;
  height: 100%;
  font-weight: 500;
  font-size: 1.4rem;
  background: none;
  line-height: 1.9rem;
  padding-inline-start: 1rem;
`;

type ButtonContainerProps = {
  justifyContent?: string;
};

const ButtonContainer = styled.div<ButtonContainerProps>`
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent};
  background: var(--color-secondary);
  border-radius: 0.5rem;
  height: 3.2rem;

  path {
    fill: white;
  }
`;

const OpenSelectButton = styled.button`
  padding: 0 0.8rem;
  height: 100%;
  background: unset;
  border: unset;
  cursor: pointer;
  border-left: 0.1rem solid #2053b6;

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;

const MenuModal = styled.div`
  opacity: 2;
  min-width: 18rem;
  border-radius: 0.6rem;
  padding: 0.4rem;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 24px;
  backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
  background-color: rgba(29, 30, 43, 0.498);
`;
