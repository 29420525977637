import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  CalendarIcon,
  DrawingPinIcon,
  FileIcon,
  GearIcon,
  GridIcon,
  Link2Icon,
} from '@radix-ui/react-icons';
import styled from 'styled-components';

import { uiStore } from '../../../core/stores/ui-store';
import { useCurrentProject } from '../../../hooks/use-current-project';
import { IconButton } from '../../../components/buttons/icon-button';
import { ClapperIcon } from '../../../components/icons/clapper';
import { TheatreMasksIcon } from '../../../components/icons';

type ProjectMenuProps = {
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  onSelect?: () => void;
};

export const ProjectMenu: React.FC<ProjectMenuProps> = observer(
  ({ isOpen, setIsOpen, onSelect }) => {
    const navigate = useNavigate();

    const project = useCurrentProject();

    if (!project) {
      return null;
    }

    return (
      <Container data-expand={isOpen}>
        {setIsOpen && <CloseButton onClick={() => setIsOpen(!isOpen)} />}

        <MenuContent>
          <MenuHeader
            onClick={() => {
              navigate(`/space/${project.spaceId}/projects/${project._id}/`);
              onSelect?.();
            }}
          >
            <IconButton icon={<ClapperIcon />} />
            Overview
          </MenuHeader>

          <Menu>
            <MenuItem
              onClick={onSelect}
              to={`/space/${project.spaceId}/projects/${project._id}/documents`}
              data-is-active={uiStore.currentLocation === 'project.documents'}
            >
              <FileIcon />
              Documents
            </MenuItem>

            {/*<MenuItemto={`/space/${project.spaceId}/projects/${project._id}/tasks`}
              onClick={onSelect} data-is-active={uiStore.currentLocation === 'project.tasks'}>
                <ClipboardIcon />
                Tasks
              </MenuItemto=>*/}

            <MenuItem
              onClick={onSelect}
              to={`/space/${project.spaceId}/projects/${project._id}/calendar`}
              data-is-active={uiStore.currentLocation === 'project.calendar'}
            >
              <CalendarIcon />
              Calendar
            </MenuItem>

            <MenuItem
              onClick={onSelect}
              to={`/space/${project.spaceId}/projects/${project._id}/share-links`}
              data-is-active={uiStore.currentLocation === 'project.share-links'}
            >
              <Link2Icon />
              Shared Links
            </MenuItem>

            <MenuItem
              onClick={onSelect}
              to={`/space/${project.spaceId}/projects/${project._id}/settings`}
              data-is-active={uiStore.currentLocation === 'project.settings'}
            >
              <GearIcon />
              Settings
            </MenuItem>

            <Subtitle>Creation</Subtitle>

            <MenuItem
              onClick={onSelect}
              to={`/space/${project.spaceId}/projects/${project._id}/storyboards`}
              data-is-active={uiStore.currentLocation === 'project.storyboards'}
            >
              <GridIcon />
              Storyboards
            </MenuItem>

            <Subtitle>Resources</Subtitle>

            <MenuItem
              onClick={onSelect}
              to={`/space/${project.spaceId}/projects/${project._id}/locations`}
              data-is-active={uiStore.currentLocation === 'project.locations'}
            >
              <DrawingPinIcon />
              Locations
            </MenuItem>

            <MenuItem
              onClick={onSelect}
              to={`/space/${project.spaceId}/projects/${project._id}/characters`}
              data-is-active={uiStore.currentLocation === 'project.characters'}
            >
              <TheatreMasksIcon />
              Characters
            </MenuItem>
          </Menu>
        </MenuContent>
      </Container>
    );
  },
);

const Container = styled.div`
  width: 0;
  position: relative;
  transition: all 0.2s ease-in;

  &[data-expand='true'] {
    width: 200px;
    min-width: 200px;
    border-right: 1px var(--content-border-color) solid;
  }
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

const Menu = styled.div`
  width: 100%;
  padding: 0.8rem;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
`;

export const Header = styled.div`
  height: 4rem;
  min-height: 4rem;
  border-bottom: 1px var(--content-border-color) solid;
  color: #e3e4e7;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 1.2rem;
  }
`;

const MenuHeader = styled.div`
  height: 4rem;
  border-bottom: 1px var(--content-border-color) solid;
  color: #e3e4e7;
  font-size: 1.4rem;
  padding: 0 0.8rem;
  max-width: 100%;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  position: relative;
  gap: 0.4rem;
  cursor: pointer;

  button {
    background-color: var(--color-primary);

    &:hover {
      background-color: var(--color-primary);
    }

    svg {
      stroke: var(--white-default);
      fill: var(--white-default);
      color: white;
    }
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(50%, -50%);
  border: 1px var(--content-border-color) solid;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  background: #101012;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

const MenuItem = styled(Link)`
  color: #e3e4e7;
  font-size: 1.4rem;
  height: 3.2rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0.4rem;
  gap: 0.8rem;

  &:hover {
    cursor: pointer;
    background: var(--bg-elevation-2);
  }

  &[data-is-active='true'] {
    background: var(--bg-elevation-2);
  }
`;

const Subtitle = styled.div`
  margin-top: 0.8rem;
  font-size: 1.3rem;
  height: 2.6rem;
  color: #7a8296;
  display: flex;
  align-items: center;
  padding: 0.4rem;
`;
