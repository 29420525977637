import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { breakPoint } from '../../theme';
import { SpaceMenu } from '../space-menu';
import { SpaceSelector } from '../space-selector';
import { ProducerLogo } from '../../../components/logo/producer-logo';
import { ProjectMenu } from '../../../routes/project/components/project-menu';
import { uiStore } from '../../../core/stores/ui-store';

import type { DrawerComponentProps } from './drawer';

export const ProjectDrawer: React.FC<DrawerComponentProps> = observer(({ isOpen, space }) => {
  return (
    <Container>
      <LeftContainer data-icon="true">
        <ProducerLogo />

        <SpaceSelector
          space={space}
          isOpen={isOpen}
          onClose={() => (uiStore.isDrawerOpen = false)}
        />

        <SpaceMenu space={space} onClose={() => (uiStore.isDrawerOpen = false)} />
      </LeftContainer>

      <ProjectMenu onSelect={() => (uiStore.isDrawerOpen = false)} isOpen />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  @media screen and (max-width: ${breakPoint.medium}px) {
    display: flex;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.6rem;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  width: 6.4rem;
  background-color: var(--color-surfaces-bg-recessed);
  & > svg {
    display: none;
    height: 2.4rem;
    fill: #ffffff;
  }

  &[data-icon='true'] {
    // This is the space selector
    & > div:not([data-radix-collection-item]) {
      & > div:last-child > svg {
        display: none;
      }

      & > div {
        padding: 0;
        flex-direction: column;
        gap: 0.4rem;

        & > p,
        & > div > span:last-child {
          display: none;
        }
      }
    }
  }
`;

const UserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 1.6rem 3.2rem 1.6rem;
  background: var(--color-grayscale-charleston, #25272d);
  order: 3;
  cursor: pointer;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;
