import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon } from '@radix-ui/react-icons';

import { IconButton } from '../../../components/buttons';
import { Plus } from '../../../components/icons';
import { LocationList } from '../components/location-list';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { PageLoader } from '../../../app/components/page-loader';
import { ScreenProps } from '../../projects/models/types';
import { ScreenHeader } from '../../projects/components/screen-header';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { Project } from '../../../app/entities/project';
import { StepDrawer } from '../../projects/components/step.drawer';
import { CreateLocationDrawer } from '../components/create-location.drawer';

export const LocationListScreen: React.FC<ScreenProps> = observer(
  ({ projectId, step, isLoading }) => {
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

    const project = Project.getOne(projectId)!;

    const handleOpenAddModal = () => {
      setIsAddModalOpen(true);
    };

    const items = [
      {
        title: 'Rename step',
        icon: <Pencil1Icon />,
        onSelect: () => setIsRenameModalOpen(true),
      },
    ];

    return (
      <React.Fragment>
        <ScreenHeader
          stepId={step._id}
          title={step.name}
          dueDate={step.dueDate}
          startDate={step.startDate}
          handleUpdateTimeFrame={(values) => step.update(values)}
          menu={<DropdownMenu items={items} size={'large'} />}
          actions={[
            <IconButton
              id="location-create"
              key="plus"
              onClick={handleOpenAddModal}
              icon={<Plus />}
            />,
          ]}
        />

        <StepDrawer step={step} />

        {isLoading ? (
          <LoaderContainer>
            <PageLoader />
          </LoaderContainer>
        ) : (
          <ScreenContainer>
            <LocationList locations={project.locations} onOpenModal={handleOpenAddModal} />
            {isAddModalOpen && <CreateLocationDrawer onCancel={() => setIsAddModalOpen(false)} />}
          </ScreenContainer>
        )}

        {isRenameModalOpen && (
          <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
        )}
      </React.Fragment>
    );
  },
);
