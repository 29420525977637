import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { breakPoint } from '../../theme';
import { SpaceMenu } from '../space-menu';
import { SpaceSelector } from '../space-selector';
import { ProducerLogo } from '../../../components/logo/producer-logo';
import { uiStore } from '../../../core/stores/ui-store';

import type { DrawerComponentProps } from './drawer';

export const MainDrawer: React.FC<DrawerComponentProps> = observer(
  ({ isOpen, currentUser, space }) => {
    const navigate = useNavigate();

    return (
      <Container>
        <TopSection>
          <ProducerLogo />

          <SpaceMenu space={space} onClose={() => (uiStore.isDrawerOpen = false)} />

          <SpaceSelector
            space={space}
            isOpen={isOpen}
            onClose={() => (uiStore.isDrawerOpen = false)}
          />
        </TopSection>

        <UserWrapper
          onClick={() => {
            navigate(`/space/${space._id}/account`, { state: { prevPath: location.pathname } });
          }}
        >
          <CurrentUserDiv>
            <Avatar
              size="medium"
              src={currentUser.avatar}
              initials={currentUser.initials}
              theme="dark"
            />
            <UserNameEmailWrapper>
              <span>{currentUser.fullName}</span>

              <span>{currentUser.email}</span>
            </UserNameEmailWrapper>
          </CurrentUserDiv>

          <IconWrapper>
            <ChevronRightIcon color="var(--color-grayscale-light-slate, #7A8296)" />
          </IconWrapper>
        </UserWrapper>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: hidden;
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-top: 1.6rem;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  max-width: 100%;
  order: 1;
  gap: 0.4rem;

  & > div {
    min-width: 100%;
    padding: 0 1.6rem;
  }

  & > svg {
    display: none;
  }

  @media screen and (min-width: ${breakPoint.medium - 1}px) {
    order: 2;
    gap: 1.6rem;

    // This is the space selector
    & > div {
      & > div {
        padding: 0;
        flex-direction: column;
        gap: 0.4rem;

        & > svg:last-child {
          display: none;
        }

        & > div {
          & > p,
          & > span:last-child {
            display: none;
          }
        }
      }
    }

    & > svg {
      display: block;
      height: 2.4rem;
      fill: #ffffff;
    }
  }
`;

const UserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 1.6rem 3.2rem 1.6rem;
  background: var(--color-grayscale-charleston, #25272d);
  order: 3;
  cursor: pointer;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;

const CurrentUserDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  min-width: 0;
`;

const UserNameEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 3.2rem;

  span:first-child {
    color: var(--color-grayscale-white, #fafafa);
    font-size: 1.4rem;
    font-weight: 500;
  }

  span:last-child {
    color: var(--color-grayscale-shuttle, #626878);
    font-size: 1.2rem;
    font-weight: 400;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconWrapper = styled.div`
  padding: 0.4rem;
`;
