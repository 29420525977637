import React from 'react';
import { observer } from 'mobx-react-lite';

import { Breadcrumb } from '../../../components/breadcrumb/breadcrumb';
import { Character } from '../../../app/entities/character';

type CastBreadcrumbType = {
  characterId: string;
};

export const CastBreadcrumb: React.FC<CastBreadcrumbType> = observer(({ characterId }) => {
  const character = Character.getOne(characterId!)!;

  const castBreadcrumb = [
    {
      label: 'Casting',
      href: `..`,
    },
    {
      label: `${character.name}`,
      href: `.`,
    },
  ];

  return <Breadcrumb breadcrumbs={castBreadcrumb} />;
});
