import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createCharacter } from '../character.slice';
import { formatPicture } from '../../../core/services/image.service';

import { catchError } from '../../../core/catch-error';
import { FormBuilderModal } from '../../../components/form-builder-modal/form-builder-modal';
import { buildCharacterForm } from '../character-form.definition';

import type { Frame } from '../../../lib/utils/transform-image';

type FormValues = {
  name: string;
  description: string;
  personality: string;
  characteristics: string;
  appearance: string;
  gender: string;
};

type CreateCharacterProps = {
  projectId: string;
  onCancel: () => void;
};

export const CreateCharacterDrawer: React.FC<CreateCharacterProps> = ({ projectId, onCancel }) => {
  const [file, setFile] = useState<Frame>();
  const navigate = useNavigate();

  const onHandleSubmit = async (values: FormValues) => {
    try {
      const character = await createCharacter({ characterInfo: values, projectId, file });
      const href = `./${character._id}`;
      navigate(href);
    } catch (e) {
      catchError(e);
    }
  };

  const handleSetImage = async (file?: File) => {
    if (!file) {
      return;
    }

    const options = { width: 640, height: 380 };
    const frame = await formatPicture(file, options);
    setFile(frame);
  };

  const createCharacterFormDefinition = buildCharacterForm(true, handleSetImage);

  return (
    <FormBuilderModal
      model="contact"
      definition={createCharacterFormDefinition}
      onSubmit={onHandleSubmit}
      onCancel={onCancel}
    />
  );
};
