import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Avatar } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';

import { ContactLocation } from '../../components/icons/ContactLocation';
import { ContactPhone } from '../../components/icons/ContactPhone';
import { ContactMail } from '../../components/icons/ContactMail';
import { ContactWebsite } from '../../components/icons/ContactWebsite';
import { PersonalFood } from '../../components/icons/PersonalFood';
import { PersonalDriverLicense } from '../../components/icons/PersonalDriverLicense';
import { List } from '../../features/contacts/components/list';
import { EditButton } from '../../components/icons/EditButton';
import { DeleteDialog } from '../../components/dialogs/DeleteDialog';
import { Contact } from '../../app/entities/contact';

import { DropdownMenu } from '../../components/modals/dropdown-menu/DropdownMenu';
import { uiStore } from '../../core/stores/ui-store';
import { breakPoint } from '../../app/theme';
import { FormBuilderModal } from '../../components/form-builder-modal/form-builder-modal';
import { buildContactForm } from './contact-form.definition';
import { DropdownTrigger } from '../../features/projects/components/styled-project-components';
import { useResponsive } from '../../hooks/useResponsive';
import { EditorContent } from '@tiptap/react';
import { EditorBubbleMenu } from '../../components/rich-editor/editor-bubble-menu';
import { useRichTextEditor } from '../../hooks/useRichTextEditor';

export const ContactDetailScreen: React.FC = observer(() => {
  const { t } = useTranslation('contact');
  const navigate = useNavigate();
  const [file, setFile] = useState<File>();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { isDesktop } = useResponsive();

  const { contactId } = useParams() as { contactId: string };
  const contact = Contact.getOne(contactId)!;

  const { editor, isTextSelected } = useRichTextEditor({
    key: contactId,
    value: contact.notes || '',
    onBlur: (text) => {
      contact.update({ notes: text });
    },
  });

  const [updateModalKey, setUpdateModalKey] = useState(contact?._id);

  useEffect(() => {
    if (contact?._id) {
      // To rerender the update form modal
      setUpdateModalKey(contact._id + Math.random());
    }
  }, [contact?._id]);

  useEffect(() => {
    uiStore.showBack = true;
  }, []);

  if (!contact) {
    return <div />;
  }

  const getContactLocation = () => {
    let location = '';
    if (contact.city) {
      location += contact.city;
    }
    if (contact.city && contact.country) {
      location += ', ';
    }
    if (contact.country) {
      location += contact.country;
    }
    return location;
  };

  const info = {
    contacts: [
      {
        img: <ContactLocation />,
        name: getContactLocation(),
      },
      { img: <ContactPhone />, name: contact.phone || '' },
      { img: <ContactMail />, name: contact.email || '' },
      { img: <ContactWebsite />, name: contact.website || '' },
    ],
    personal: [
      { img: <PersonalFood />, name: contact.foodPreferences || '' },
      { img: <PersonalDriverLicense />, name: contact.driverLicense || '' },
    ],
  };

  const handleContactDelete = async () => {
    await contact.delete();

    navigate('..');
  };

  const menuItems = [
    {
      onSelect: () => setIsEditModalOpen(true),
      title: 'Edit',
      icon: <EditButton />,
    },
    {
      onSelect: () => setDeleteModalIsOpen(true),
      title: 'Delete',
      icon: <TrashIcon />,
      type: 'danger' as 'danger',
    },
  ];

  const handleSubmitContact = async (data: any) => {
    await contact.update({ file, ...data });

    // To rerender the update form modal
    setUpdateModalKey(contact._id + Math.random());
  };

  const updateContactFormDefinition = buildContactForm(false, t, setFile);

  return (
    <React.Fragment>
      <InfoContainer>
        <UserAvatarContainer>
          <Avatar
            src={contact.avatar || ''}
            alt="photo"
            theme="dark"
            size="fill"
            initials={contact.initials}
          />
        </UserAvatarContainer>

        <ActionsContainer>
          <Name>{contact.fullName}</Name>

          <DropdownMenu
            items={menuItems}
            trigger={<DropdownTrigger data-dark="true"> ⋮</DropdownTrigger>}
          />
        </ActionsContainer>

        {info.contacts.some((item) => !!item.name) && (
          <List title="Contact information" list={info.contacts} />
        )}

        {info.personal.some((item) => !!item.name) && (
          <ListWrapper title="Personal information" list={info.personal} />
        )}

        {!isDesktop && (
          <NotesWrapper key={`${contactId}-editor`}>
            <NotesHeader>Notes</NotesHeader>
            <NotesInput editor={editor} placeholder="Write notes here" className="notes-textarea" />
          </NotesWrapper>
        )}
      </InfoContainer>

      {isDesktop && (
        <NotesWrapper>
          <NotesHeader>Notes</NotesHeader>
          {editor && isTextSelected && <EditorBubbleMenu editor={editor} />}
          <NotesInput
            key={`${contactId}-editor`}
            editor={editor}
            placeholder="Write notes here"
            className="notes-textarea"
          />
        </NotesWrapper>
      )}

      {isEditModalOpen && (
        <FormBuilderModal
          key={updateModalKey}
          model="contact"
          definition={updateContactFormDefinition}
          initialValues={contact}
          onSubmit={handleSubmitContact}
          onCancel={() => setIsEditModalOpen(false)}
        />
      )}

      {deleteModalIsOpen && (
        <DeleteDialog
          title={'Delete contact ?'}
          text={`Are you sure you want to delete ${contact.fullName} from your contacts? This action is irreversible!`}
          onCancel={() => setDeleteModalIsOpen(false)}
          onSubmit={handleContactDelete}
        />
      )}
    </React.Fragment>
  );
});

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  height: 100%;
  min-width: 0;

  @media screen and (min-width: ${breakPoint.small}px) {
    max-width: 32.8rem;
  }
`;

const UserAvatarContainer = styled.div`
  position: relative;
  min-height: 16rem;
  min-width: 16rem;
  margin: 0 auto;
  border-radius: 0.4rem;
  aspect-ratio: 1;

  @media screen and (min-width: ${breakPoint.small}px) {
    min-width: 32rem;
    min-height: 32rem;
    flex: 0 0 32rem;
    margin: unset;
  }

  span {
    &[data-size='fill'] {
      font-size: 8rem;
    }

    &[data-theme='dark'] {
      background-color: var(--color-grayscale-tuna);
    }
  }
`;

const ListWrapper = styled(List)`
  @media screen and (min-width: ${breakPoint.medium}px) {
    padding-bottom: 2.4rem;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & button {
    align-self: start;
    min-width: 2.8rem;
    min-height: 2.8rem;
  }
`;

const Name = styled.h2`
  font-size: 1.6rem;
  color: var(--color-grayscale-white);
  font-weight: 600;
`;

const NotesHeader = styled.h2`
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--color-grayscale-light-slate);
`;

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  flex: 1;

  & > div:last-child {
    margin-bottom: 2.6rem;

    @media screen and (min-width: ${breakPoint.medium}px) {
      height: 100%;
      margin-bottom: 0;
    }
  }
`;

const NotesInput = styled(EditorContent)`
  height: 30vh;
  border-radius: 0.4rem;
  border: 0.1rem solid transparent;
  padding: 0.8rem;
  line-height: 2rem;
  color: var(--color-grayscale-white);
  font-size: 1.4rem;
  font-weight: 400;
  position: relative;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  letter-spacing: 0.3px;
  resize: none;
  background-color: transparent;

  @media screen and (min-width: ${breakPoint.medium}px) {
    height: 100%;
  }

  &::placeholder {
    color: var(--color-grayscale-light-slate);
  }

  &:hover {
    background-color: transparent;
    border: 0.1rem solid var(--color-grayscale-trout);
    cursor: text;
  }

  &:focus {
    background-color: transparent !important;
    border-color: var(--color-secondary);
    border: 0.1rem solid var(--color-grayscale-trout);
  }

  &[data-error='true'] {
    &:focus {
      border-color: var(--color-error);
    }
  }
`;
