import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { StatusSelector } from '../../../features/process/components/status-selector';
import { AssigneeSelector } from '../../../components/assignee-selector/assignee-selector';
import { handleScrollToStrip } from '../../../components/gantt-chart/utils/utils';
import { Tooltip } from '../../../components/tooltip/Tooltip';

import type { Step, StepStatus } from '../../../app/entities/step';

type StepGanttHeaderProps = {
  step: Step;
  isViewOnly?: boolean;
};

export const StepGanttHeader: React.FC<StepGanttHeaderProps> = observer(({ step, isViewOnly }) => {
  const handleChangeStatus = async (newStatus: StepStatus) => {
    try {
      await step.update({ status: newStatus });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container
      id={`${step._id}-header`}
      onClick={() => handleScrollToStrip?.(step._id)}
      data-dim={step.done}
    >
      <LeftContainer>
        <StatusSelector
          onChange={handleChangeStatus}
          status={step.status}
          isViewOnly={isViewOnly}
        />

        <Tooltip text={step.displayName} position="top" delay={500}>
          <Title>{step.displayName}</Title>
        </Tooltip>
      </LeftContainer>

      <AssigneeSelector
        collaborators={step.project!.collaborators}
        assigneeId={step.assigneeId}
        onCheckedChange={(assigneeId) => {
          step.update({
            assigneeId,
          });
        }}
      />
    </Container>
  );
});

const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: var(--border-radius-small);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
  cursor: pointer;
  background-color: var(--color-surfaces-bg-elevation-3);
  gap: 0.8rem;

  &[data-dim='true'] {
    background-color: var(--color-surfaces-bg-elevation-2);

    & p {
      color: var(--color-texts-middle-contrast);
    }
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 0;
  align-items: center;
`;

const Title = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--color-texts-high-contrast);
`;
