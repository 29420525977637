import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { EnterIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { useTranslation } from 'react-i18next';

import { LocationPin } from '../../../components/icons/LocationPin';
import { CoverImage } from '../../../components/cover-image/cover-image';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { formatAddress } from '../../../core/services/address.service';
import { updateLocationCover } from '../location.slice';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { Location } from '../../../app/entities/location';
import { FormBuilderModal } from '../../../components/form-builder-modal/form-builder-modal';
import { buildLocationForm } from './location-form.definition';

import type { Frame } from '../../../lib/utils/transform-image';

interface LocationCardProps {
  location: Location;
}

export const LocationCard: React.FC<LocationCardProps> = observer(({ location }) => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [updateModalKey, setUpdateModalKey] = useState(location?._id);
  const { t } = useTranslation('location');

  useEffect(() => {
    if (location?._id) {
      // To rerender the update form modal
      setUpdateModalKey(location._id + Math.random());
    }
  }, [location?._id]);

  const handleDeleteLocation = async () => {
    await location.delete();
    setIsDeleteModalOpen(false);
  };

  const handleEditLocation = async (values: Partial<Location>) => {
    await location.update(values);

    // To rerender the update form modal
    setUpdateModalKey(location._id + Math.random());
  };

  const handleSubmitCover = async (frame: Frame) => {
    updateLocationCover({
      locationId: location._id,
      file: frame,
    });
  };

  const goToLocation = async () => {
    const href = `./${location._id}`;
    navigate(href);
  };

  const handleOpenEditModal = async () => {
    setIsEditModalOpen(true);
  };

  const menuItems = [
    {
      title: 'Open',
      icon: <EnterIcon />,
      onSelect: () => navigate(`./${location._id}`),
    },
    {
      title: 'Edit',
      icon: <Pencil1Icon />,
      onSelect: handleOpenEditModal,
    },
    {
      title: 'Delete',
      icon: <TrashIcon />,
      type: 'danger' as 'danger',
      onSelect: () => setIsDeleteModalOpen(true),
    },
  ];

  const updateLocationFormDefinition = buildLocationForm(false, t);

  return (
    <>
      <Card onClick={goToLocation}>
        <Header>
          <CoverImage
            src={location.cover?.src || ''}
            title={location.name}
            onEdit={handleSubmitCover}
            onDoubleClick={goToLocation}
          />

          <DropdownContainer>
            <DropdownMenu items={menuItems} />
          </DropdownContainer>
        </Header>

        <Content>
          <Name>{location.name}</Name>

          {location.address && (
            <Address>
              <LocationPin />
              <span>{formatAddress(location.address)}</span>
            </Address>
          )}
        </Content>
      </Card>

      {isDeleteModalOpen && (
        <DeleteDialog
          title={'Delete location ?'}
          text={`Are you sure you want to delete ${location.name}?`}
          onCancel={() => setIsDeleteModalOpen(false)}
          onSubmit={handleDeleteLocation}
        />
      )}

      {isEditModalOpen && (
        <FormBuilderModal
          key={updateModalKey}
          model="location"
          definition={updateLocationFormDefinition}
          initialValues={location}
          onCancel={() => setIsEditModalOpen(false)}
          onSubmit={handleEditLocation}
        />
      )}
    </>
  );
});

const Card = styled.div`
  position: relative;
  background: var(--card-bg-color);
  border-radius: var(--card-border-radius);
  overflow: hidden;
  border: 1px solid var(--card-bg-color);

  &[data-is-selected='true'] {
    border-color: var(--color-secondary);
  }
`;

const Header = styled.div`
  padding-top: 62.5%;
  position: relative;
  z-index: 1;

  &:hover + #action {
    display: initial;
  }

  &:hover > #action {
    display: initial;
  }

  &:hover #action {
    display: initial;
  }

  h1 {
    font-family: Inter, sans-serif;
    color: var(--step-title-color);
  }

  cursor: pointer;
`;

const Content = styled.div`
  padding: 0.8rem 1.6rem 1.6rem 1.6rem;
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #f7f8f8;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: block;
  white-space: nowrap;
`;

const Address = styled.label`
  display: flex;
  align-items: center;
  margin-top: 0.8rem;

  svg {
    fill: #959bab;
    height: 1.4rem;
    width: 1.4rem;
    margin-right: 0.8rem;
  }

  > span {
    display: inline-block;
    min-height: 100%;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.4rem;
    color: #959bab;
  }
`;

const DropdownContainer = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  cursor: pointer;
  z-index: 99;
`;
