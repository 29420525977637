import React, { useEffect } from 'react';
import styled from 'styled-components';

import { Header } from './project.page';
import { useCurrentProject } from '../../hooks/use-current-project';
import { uiStore } from '../../core/stores/ui-store';

export const ProjectTasksRoute = () => {
  const project = useCurrentProject()!;

  useEffect(() => {
    uiStore.currentLocation = 'project.tasks';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <>
      <Header>Tasks</Header>

      <Container></Container>
    </>
  );
};

const Container = styled.div`
  padding: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow-y: scroll;
`;
