import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { CheckIcon } from '@radix-ui/react-icons';

import { CoverImage } from '../../../components/cover-image/cover-image';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { IconButton } from '../../../components/buttons';
import { CircleCancel } from '../../../components/icons/circle-cancel';
import { CrownBadge } from '../../../components/icons/crown-badge';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';

import { Candidate } from '../../../app/entities/candidate';

type CandidateCardProps = {
  candidate: Candidate;
  isSelectedCandidate?: boolean;
};

export const CandidateCard: React.FC<CandidateCardProps> = observer(
  ({ candidate, isSelectedCandidate }) => {
    const [isRemoveCandidateOpen, setIsRemoveCandidateOpen] = useState(false);

    console.log(candidate);

    const handleApprove = async () => {
      if (isSelectedCandidate) {
        await candidate.character.update({ cast: null });
        return;
      }

      await candidate.character.assignCast(candidate._id);
    };

    const handleOnRemove = async () => {
      await candidate.character.removeCandidate(candidate._id);
    };

    return (
      <Card data-active={isSelectedCandidate}>
        <CandidateImageContainer>
          <CoverImage src={candidate.avatar || ''} title={candidate.fullName} />
        </CandidateImageContainer>

        <InfoContainer>
          <CandidateName>{candidate.fullName}</CandidateName>

          <CandidateTitle>Actor</CandidateTitle>
        </InfoContainer>

        <RightContainer>
          {isSelectedCandidate && <CrownBadge />}

          <ButtonsContainer>
            <SelectButtonContainer data-active={isSelectedCandidate}>
              <Tooltip text="Select Candidate" position="top" disabled={isSelectedCandidate}>
                <IconButton icon={<CheckIcon />} onClick={handleApprove} />
              </Tooltip>
            </SelectButtonContainer>

            <RemoveButtonContainer>
              <Tooltip text="Remove Candidate" position="top">
                <IconButton
                  icon={<CircleCancel />}
                  onClick={() => setIsRemoveCandidateOpen(true)}
                />
              </Tooltip>
            </RemoveButtonContainer>
          </ButtonsContainer>
        </RightContainer>

        {isRemoveCandidateOpen && (
          <DeleteDialog
            onSubmit={handleOnRemove}
            onCancel={() => setIsRemoveCandidateOpen(false)}
            title="Are you sure you want to remove candidate?"
            text="Candidate will be removed immediately. After removing you can add candidate from candidates list"
          />
        )}
      </Card>
    );
  },
);

const Card = styled.div`
  display: flex;
  position: relative;
  gap: 1.2rem;
  background: var(--color-grey-8);
  border-radius: 6px;
  padding: 1.6rem;
  min-height: 10rem;

  &[data-active='true'] {
    background: var(--color-grey-8-75);

    border: 0.1rem solid var(--color-secondary);
  }
`;

const CandidateImageContainer = styled.div`
  width: 6.4rem;
  height: 6.4rem;
  position: relative;
  cursor: pointer;

  & > div {
    padding: 0;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  flex: 1;
  cursor: pointer;
`;

const SelectButtonContainer = styled.div`
  & button {
    background-color: var(--color-grey-7);
    border-radius: 0.6rem;

    & > svg {
      fill: none;
      stroke: var(--color-grey-5);
    }

    &:hover,
    &:active {
      background-color: var(--color-secondary);

      & > svg {
        fill: none;
        stroke: var(--white-default);
      }
    }
  }

  &[data-active='true'] {
    & button {
      background-color: var(--color-secondary);

      & > svg {
        stroke: var(--white-default);
      }
    }
  }
`;

const RemoveButtonContainer = styled.div`
  & button {
    background-color: var(--color-grey-7);
    border-radius: 0.6rem;

    & svg {
      fill: var(--color-grey-5);
      stroke: unset;
    }

    &:hover {
      background-color: #db5c66;

      & svg {
        stroke: unset;
        fill: var(--white-default);
      }
    }
  }
`;

const RightContainer = styled.div`
  height: calc(100% - 3.2rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 1.6rem;
  right: 1.6rem;

  & > svg {
    top: 0;
    right: 0;
  }

  display: flex;
  gap: 1.6rem;
  align-items: end;

  & button {
    min-width: 3.2rem;
    width: 3.2rem;
    min-height: 3.2rem;
    height: 3.2rem;
  }
`;

const CandidateName = styled.h6`
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--white-default);
  line-height: 1.9rem;
`;

const CandidateTitle = styled.p`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: var(--step-title-color);
  opacity: 0.6;
`;
