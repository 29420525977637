import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { useCurrentProject } from '../../hooks/use-current-project';
import { Header } from './project.page';
import { LocationList } from '../../features/locations/components/location-list';
import { ElevatedButton } from '../../components/buttons';
import { CreateLocationDrawer } from 'features/locations/components/create-location.drawer';
import { uiStore } from '../../core/stores/ui-store';

export const ProjectLocationsRoute = observer(() => {
  const project = useCurrentProject()!;
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  useEffect(() => {
    uiStore.currentLocation = 'project.locations';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <>
      <Header>
        <div>Locations</div>

        <ElevatedButton text="Create location" onClick={() => setIsCreateModalOpen(true)} />
      </Header>

      <Content>
        <Container>
          <LocationList
            locations={project.locations}
            onOpenModal={() => setIsCreateModalOpen(true)}
          />
        </Container>

        {isCreateModalOpen && <CreateLocationDrawer onCancel={() => setIsCreateModalOpen(false)} />}
      </Content>
    </>
  );
});

const Content = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  height: 100%;
`;
