import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link1Icon, TrashIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { Switch } from '../../../components/toggle/Switch';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { formatDate } from '../../../lib/utils/DateHelper';
import { dateShort } from '../../../assets/contants/dates-fns';
import { Asset } from '../../../app/entities/asset';

import type { ShareLink } from '../../../app/entities/share-link';

type ShareLinkItemProps = {
  link: ShareLink;
};

export const ShareLinkItem: React.FC<ShareLinkItemProps> = observer(({ link }) => {
  const menuItems = [
    {
      onSelect: () => navigator.clipboard.writeText(link.url),
      title: 'Copy link',
      icon: <Link1Icon />,
    },
    {
      onSelect: () => link.delete(),
      title: 'Delete',
      icon: <TrashIcon />,
      type: 'danger' as const,
    },
  ];

  const hasDownloadableItems = link.assetIds
    .map((id) => Asset.getOne(id) || { type: 'file' })
    .some((asset) => asset!.type !== 'link');

  return (
    <Row>
      <Cell>{link.name}</Cell>
      <Cell>{formatDate(link.createdAt.toString(), dateShort)}</Cell>
      <Cell>{link.assetIds?.length || 0}</Cell>
      <Cell>{link.creatorName}</Cell>
      <Cell style={{ textAlign: 'center' }}>
        {hasDownloadableItems && (
          <Switch
            onToggle={(canDownload) => link.update({ canDownload })}
            isToggled={!!link.settings.canDownload}
            size="medium"
          />
        )}
      </Cell>

      <Cell>
        <DropdownMenu items={menuItems} />
      </Cell>
    </Row>
  );
});

const Row = styled.tr`
  height: 4.8rem;
`;

const Cell = styled.td`
  color: var(--white-default);
  font-size: 1.4rem;
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid var(--color-grayscale-tuna);

  &:not(:last-child) {
    padding-right: 9.6rem;
  }

  &:first-child {
    padding-left: 1.6rem;
  }

  &:last-child {
    padding-right: 1.6rem;
  }
`;
