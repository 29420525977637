import { api } from '../../api';

import { entityPool } from '../../core/engine/engine';
import { ShareLink } from '../../app/entities/share-link';

type FetchShareLinkInput = {
  spaceId: string;
  projectId: string;
  assetIds: string[];
};

export const createShareLink = async ({
  projectId,
  spaceId,
  assetIds,
}: FetchShareLinkInput): Promise<ShareLink> => {
  const { data } = await api.post('/share-links', { projectId, spaceId, assetIds });
  const shareLink = Object.assign(new ShareLink(), data);
  entityPool.insert(shareLink);
  return data;
};
