import styled from 'styled-components';

type CircularProgressProps = {
  progress: number;
};

export const CircularProgress = styled.div<CircularProgressProps>`
  position: relative;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  background: conic-gradient(
    #367bff ${({ progress }) => progress * 3.6}deg,
    var(--color-grayscale-arsenic) 000000 ${({ progress }) => progress * 3.6}deg
  );
  transition: all 0.5s;

  &:before {
    content: '';
    height: 70%;
    width: 70%;
    position: absolute;
    background-color: #1f2023;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
